.landing_slider {
    position: relative;
    width: 100%;
    padding: 0;
    max-width: 1170px;
    margin: 0 auto;
    height: initial;
}

.landing_slider.ls_header {
    padding: 0;
    margin: 0;
    max-width: initial
}

.landing_slider.ls_header .slick-next,
.landing_slider.ls_header .slick-prev {
    display: none
}

.landing_slider .slick-prev {
    top: -51px;
    left: initial;
    right: 45px;
    width: 25px;
    height: 25px !important;
    background-color: #586890;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    transition: background-color .3s ease-in-out
}
@media (hover: hover) {
    .landing_slider .slick-prev:hover {
        background-color: #5d5e6e
    }
}

.landing_slider .slick-prev:before {
    opacity: 1;
    top: 50%;
    right: 50%;
    width: 5px;
    height: 10px;
    transform: translate(-30%, -50%)
}

.landing_slider .btn_slick_disable {
    background-color: #686a7b!important;
    pointer-events: none
}

.landing_slider .slick-next {
    top: -51px;
    width: 25px;
    height: 25px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    transition: background-color .3s ease-in-out
}

.landing_slider .slick-next-loading {
    top: -127px;
    right: -12px
}

.landing_slider .slick-next:before {
    opacity: 1;
    top: 50%;
    left: 48%;
    width: 5px;
    height: 10px;
    transform: translate(-20%, -50%)
}

.landing_slider .view_more {
    right: 20px;
    bottom: -10px
}

.landing_slider .view_more a {
    font-size: 14px;
    font-weight: 700;
    position: static;
    color: #fff!important;
    display: inline-block
}

.landing_slider .slick-next {
    right: 5px
}