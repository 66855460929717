.game-accounts-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);

    &.hide {
        display: none !important;
    }

    &.show {
        display: block !important;
    }

    .multiselect__content-wrapper, .game_account_scrollbar {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--header-modal-game-account-bg);
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: var(--content-modal-game-account-bg);
      }
    }

    .game-accounts-modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 30;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #000;
    }

    .game-accounts-modal-container {
        display: flex;
        flex-direction: column;
        width: 850px;
        max-height: 90vh;
        height: fit-content;
        z-index: 40;

        .game-accounts-modal-header {
            width: 100%;
            height: 80px;
            padding: 20px 25px;
            background: var(--header-modal-game-account-bg);
            display: flex;
            align-items: center;
            justify-content: space-between;
            clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%, 0 0);

            .game-accounts-modal-title {
                font-weight: bold;
                font-size: 26px;
                line-height: 35px;
                color: var(--text-color);
            }

            .game-accounts-modal-esc {
                cursor: pointer;
            }
        }

        .game-accounts-modal-content {
            background: var(--content-modal-game-account-bg);
            padding: 25px 85px;
            width: 100%;
            overflow-y: auto;

            .game-accounts-modal-content-section {
                margin-bottom: 35px;
                width: 100%;
            }

            .game-accounts-modal-content-title {
                font-weight: bold;
                font-size: 24px;
                line-height: 33px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: var(--text-color);
            }

            .game-accounts-modal-content-description {
                width: 67%;
                font-size: 15px;
                line-height: 20px;
                text-transform: capitalize;
                color: var(--color-description-modal-game-account);
                margin-bottom: 25px;
            }

            .game-accounts-modal-content-form {
                background: var(--header-modal-game-account-bg);
                border: 1px solid #EE733A;
                border-radius: 6px;
                padding: 10px;

                .game-accounts-modal-content-form-line {
                    display: flex;
                    align-items: center;
                }

                .game-accounts-modal-content-form-group {
                    padding: 10px;

                    & > input {
                        background: var(--dropdown-modal-game-account-bg);
                        width: 100%;
                        height: 40px;
                        padding: 10px 15px;
                        color: var(--text-color);
                    }

                    .multiselect__tags {
                        background: var(--dropdown-modal-game-account-bg);
                        border-radius: 0;
                        border: none;
                    }

                    .multiselect__single {
                        background: var(--dropdown-modal-game-account-bg);
                        color: var(--text-color);
                    }
                    .multiselect__content-wrapper {
                        background: var(--dropdown-modal-game-account-bg);
                        color: var(--text-color);
                        border: none;
                        border-radius: 0;
                    }
                    .multiselect__option {
                        background: var(--dropdown-modal-game-account-bg);
                        color: var(--text-color);

                        &--selected {
                            background: var(--color-dark-alt);
                        }

                        &--highlight {
                            background-color: var(--color-border);
                        }
                    }
                }
                .game-accounts-modal-content-form-title {
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    color: var(--text-color);
                    margin-bottom: 10px;
                }
                .game-accounts-modal-content-form-actions {
                    display: flex;
                    align-items: center;

                    .game-accounts-modal-content-form-save {
                        background: #EE733A;
                        width: 106px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFFFFF;
                        cursor: pointer;
                        padding: 10px;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 14px;
                        margin-right: 30px;
                    }

                    .game-accounts-modal-content-form-remove {
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 14px;
                        text-align: center;
                        text-decoration-line: underline;
                        color: #E64747;
                        cursor: pointer;
                    }
                }
            }

            .game-accounts-modal-content-boxes {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                max-height: 300px;
                overflow-y: auto;

                .game-accounts-modal-content-box {
                    padding: 8px 8px 0 0;
                    width: calc(100% / 3);

                    .game-accounts-modal-content-box-content {
                        background: var(--header-modal-game-account-bg);
                        border-radius: 1px;
                        padding: 3px 0;
                        height: 35px;
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .game-accounts-modal-content-box-left {
                            padding: 7px 10px;
                            border-right: 0.5px solid #898B9C;
                            width: 35px;
                            height: 100%;

                            & > img {
                                height: auto;
                                max-height: 100%;
                                width: 16px;
                            }
                        }
                        .game-accounts-modal-content-box-right {
                            width: calc(100% - 35px);
                            display: flex;
                            align-items: center;

                            .game_content_data {
                                width: calc(100% - 35px);
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                padding: 0 10px;

                                .game_type_title {
                                    font-size: 11px;
                                    line-height: 14px;
                                    display: flex;
                                    align-items: center;
                                    color: var(--text-color);
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .game_name_title {
                                    font-size: 10px;
                                    line-height: 14px;
                                    display: flex;
                                    align-items: center;
                                    color: var(--text-color);
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .game_content_action {
                                width: fit-content;
                                max-width: 15px;
                                margin: 0 auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                & > svg {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .game-accounts-modal {
        zoom: 0.8;

        .game-accounts-modal-container {
            .game-accounts-modal-content {
                padding: 20px;

                .game-accounts-modal-content-description {
                    width: 100%;
                }

                .game-accounts-modal-content-boxes {
                    .game-accounts-modal-content-box {
                        width: calc(100% / 2);
                    }
                }
            }
        }
    }
}
