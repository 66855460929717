.statistics_game_stats {
  $game-stats-small-col-width: 345px;

  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr $game-stats-small-col-width;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 40px;

  // NOTE Here we define custom layouts for games
  // abusing grid-template-areas amazing power and css grid
  .overview_statistics {
    grid-area: overview;
  }
  .core_statistics {
    grid-area: core;
  }
  .graph_statistics {
    grid-area: graph;
  }
  .queue_statistics {
    grid-area: queue;
  }
  .monthly_statistics {
    grid-area: monthly;
  }

  &[game='lol'] {
    grid-template-areas: "queue overview"
                         "queue core"
                         "queue graph"
                         "queue monthly"
                         "queue blank";
  }

  &[game='fortnite'] {
    grid-template-columns: 1fr 1fr $game-stats-small-col-width;
    grid-template-areas: "overview core  graph"
                         "queue    queue monthly"
                         "queue    queue blank";
  }

  &[game='rocket-league'] {
    grid-template-areas: "core  overview"
                         "queue graph"
                         "queue monthly"
                         "queue blank";
  }

}

.empty_game_stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px dashed var(--color-border);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    border: 2px solid var(--color-primary);
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      opacity: .5;
    }

    img {
      width: 40px;
      height: 40px;
      z-index: 1;
    }
  }

  span {
    font-size: 20px;
    line-height: 28px;
    margin-top: 30px;
  }

  .button-simple {
    flex: 0;
    width: 165px;
    margin-top: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .empty_game_stats {
    width: 100%;
    padding: 20px;
  }
  .statistics_game_stats {
    display: block;

    & > div:not(:first-child) {
      margin-top: 20px;
    }
  }
}