.dropdown-simple {
  --dropdown-height: 45px;
  $dropdown-border-size: 2px;
  margin-left: 10px;
  background-color: var(--color-dropdown);
  color: var(--color-text-light);
  min-width: 100px;
  height: var(--dropdown-height);
  white-space: nowrap;
  cursor: pointer;

  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: -webkit-filter var(--global-animation-short);
  transition: filter var(--global-animation-short);
  transition: filter var(--global-animation-short), -webkit-filter var(--global-animation-short);

  .dropdown-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-radius: $dropdown-border-size;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    height: 100%;
    border: $dropdown-border-size solid var(--color-border);
    &:hover {
      -webkit-filter: brightness(115%);
              filter: brightness(115%);
    }

    .selected-item {
      flex: 1;
    }

    .dropdown-arrow {
      position: relative;
      top: 2px;
      width: 8px;
      height: auto;
      margin-left: 10px;
    }
  }

  .dropdown-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: var(--dropdown-height);
    left: 0;
    margin-top: -$dropdown-border-size;
    width: 100%;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    transform-origin: top center;
    transform: scaleY(0);
    transition: transform var(--global-animation-short);
    z-index: var(--global-dropdown-z);

    &.collapsed {
      transform: scaleY(1);
    }

    .item {
      display: flex;
      align-items: center;
      height: var(--dropdown-height);
      background-color: var(--color-dark-alt);
      color: var(--color-text-light-alt);
      padding: 0 20px;
      width: 100%;
      font-size: 13px;
      font-weight: 500;
      &:hover {
        -webkit-filter: brightness(115%);
                filter: brightness(115%);
      }

      &.active {
        background-color: var(--color-border);
        font-weight: 600;
      }
    }
  }
}