@import url("../../../../public/css/pubg_tournament/factionwars.css");
.hs_header {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 60px;
    width: 100%;
    padding: 128px 20px 180px;
    min-height: 591px;
    background: url(../../assets/v4/highschool_support/background_highschool_support_page.png) no-repeat #1d202d;
    background-size: cover;
    background-position: 50% 50%;
}
.hs_header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 720px;
    background-image: linear-gradient(270deg, rgba(29, 32, 45, 0) 0%, rgba(29, 32, 45, 0.55) 100%);
}
.hs_container {
    margin: 80px auto 110px auto;
}
.fw_info_card_num{
    color: #ea8230;
}
.avgl .fw_info_card_num{
    color: #d93932 !important;
}

.hs_about {
    width: 100%;
    margin: 0 10px;
    max-width: 485px;
    text-align: left;
}

.hs_about_title{
    color: #ffffff;
    font-size: 36px;
    font-weight: 400;
    margin: 0 0 -10px;
}

.hs_about_small_title{
    font-weight: bold;
    font-size: 16px;
    color: #D34445;
}

.hs_about_content{
    color: #ffffff;
    margin: 20px 0;
    font-size: 18px;
}

.hs_about_content_title{
    font-weight: bold;
}

.hs_about_content_list{
    list-style: disc;
    margin-left: 20px;
}

.intel_inspire_link{
    color: #329DFF;
    text-decoration: underline;
    cursor: pointer;
}

.fw_about_upcoming{
    max-width: 250px;
}
.fw_action_content{
    color: #ffffff;
    text-align: center;
}
.action_item_title{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}
.action_item_inclined_title{
    font-size: 12px;
    font-style: italic;
    line-height: 22px;
    margin: 12px -10px;
}

.hs_fun_fact{
    position: relative;
    margin-left: 15px;
}
.hs_fun_fact::before{
    content: '-';
    position: absolute;
    left: -10px;
}

.action_item_title_medium{
    font-size: 14px;
    font-weight: bold;
}

.hs_btn_confirm_qualify{
    margin: 20px 0 18px;
}

.hs_subtitle{
    margin-bottom: 10px;
}

.hs_important_info_fact{
    font-weight: bold;
    color: var(--sub-tourneys-info-content-color);
}

.fw_more img, .fw_more p{
    cursor: pointer;
}

.fw_action_content{
    display: block;
}
.hs_about_upcoming_btn {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 10px;
    margin: 0 -2px -2px;
    border-radius: 3px;
    background-color: #e5eaff;
    color: #1d222f;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}
.hs_about_upcoming_btn span {
    position: relative;
}
.hs_about_upcoming_btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: rgba(0,0,0,0);
}
.fw_btn.disabled{
    cursor: progress;
}
