.aboutp_team_user_icon:before {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(233, 123, 38, .5) 100%)
}

.aboutp_team_user_topright {
    background-color: #ee733a
}

.aboutp_team_user_topright:before {
    background-color: #ee733a
}

.aboutp_team_user_botleft {
    background-color: #ee733a
}

.aboutp_team_user_botleft:before {
    background-color: #ee733a
}

.aboutp_trusted:before {
    background-color: #ee733a
}

.aboutp_testimonial_usericon {
    background-color: rgba(58, 61, 78, .4)
}

.btn_update.orange {
    background-color: #e97b26
}

.nav_host span {
    color: #ee733a
}

.admin_login_titles:after {
    background-color: #e97b26
}

.admin_step {
    color: #ee733a;
    background-color: rgba(29, 32, 45, 0)
}

.admin_event_about {
    color: #ee733a
}

.admin_step.done:first-child .astep_num {
    border-color: rgba(233, 123, 38, .5)
}

.admin_step.done:first-child .astep_desc {
    color: rgba(233, 123, 38, .5)
}

.admin_step.on .astep_num {
    border-color: #ee733a;
    color: #ee733a
}

.admin_step.on .astep_desc {
    color: #ee733a
}

.abtn_main {
    background-color: #ee733a
}

.admin_events_item.on .admin_events_item_image,
.admin_events_item:hover .admin_events_item_image {
    border-color: #ee733a
}

.view_team_results .back_to_scoreboard {
    color: #ee733a
}

.view_team_results .admin_table_btn {
    background-color: #ee733a
}

.view_team_results .admin_table_btn:hover {
    background-color: #e15f1e
}

.tournament_url_holder_tweet .orange {
    background-color: #ee733a!important
}

.tweet_section a {
    color: #ee733a
}

.tournament_url_input a {
    color: #ee733a
}

.admin_item_btn {
    background-color: #ee733a
}

.admin_item_btn.orange {
    background-color: #ee733a
}

.admin_btn {
    background-color: #ee733a
}

.admin_btn:hover {
    background-color: #b9621f
}

.or {
    color: #ee733a
}

a.or:hover {
    color: rgba(233, 123, 38, .7)
}

.admin_check_label input:checked~.admin_checkbox {
    border-color: #ee733a;
    background-color: rgba(233, 123, 38, .7)
}

.admin_clickable {
    color: #ee733a
}

.advanced_tracking_premium {
    background-color: #ee733a
}

.admin_matchup .admin_matchup_section .abtn_main.update {
    background-color: #ee733a
}

.admin_matchup.done .admin_matchup_section .abtn_main.update,
.admin_matchup.finished .admin_matchup_section .abtn_main.update {
    background-color: #ee733a
}

.admin_matchups_username:hover {
    color: #ee733a
}

.body_boom_modal_content ul.list-proceed-event li a {
    color: #ee733a
}

#modal_provide_game_name_admin_app .landing_btn {
    background-color: #e97b26
}

#add_more_mods,
#add_more_supporters {
    background-color: #ee733a
}

.match_info .match_btn {
    background-color: #e97b26
}

.admin_event_image {
    border: 2px solid #ee733a
}

.game_type_value {
    color: rgba(233, 123, 38, .75)
}

.play_circle {
    background-color: #ef7125
}

.load_more {
    background-color: var(--bg-color-primary);
    border: 2px solid #1e232f;
    color: #ee733a
}

.about_link {
    color: rgba(238, 115, 58, .75)
}

.about_link:before {
    background-color: #ee733a
}

.about_link:hover {
    color: #ee733a
}

.header_btn_box {
    background-color: #ee733a
}

.headline_button {
    color: rgba(238, 115, 58, .8)
}

.headline_button:after {
    background: #ee733a
}

.subscribe_button_shadow {
    background-color: #ee733a
}

.subscribe_link {
    background-color: #ff6c24!important
}

.unsubscribe_link {
    background-color: #ff6c24!important
}

.contact_button {
    background-color: #ee733a
}

.contact_card_link {
    color: rgba(238, 111, 40, .9)
}

.contact_card_link:after {
    background: #ee733a
}

.contact_card_link:hover {
    color: #ee733a
}

.tips_card_info {
    color: #ee733a
}

.tips_card_link {
    color: #ee733a
}

.tips_card_link:after {
    background-color: rgba(238, 111, 40, .8)
}

.tips_card_link:hover {
    color: rgba(238, 111, 40, .8)
}

.related_link {
    color: #ee733a
}

.related_link:after {
    background-color: rgba(238, 111, 40, .8)
}

.related_link:hover {
    color: rgba(238, 111, 40, .8)
}

.features_title_link {
    color: #ee733a
}

.features_title_link:after {
    background-color: rgba(255, 107, 34, .8)
}

.features_title_link:hover {
    color: rgba(255, 107, 34, .8)
}

.careers_tile_link {
    color: #ee733a
}

.careers_tile_link:after {
    background: rgba(255, 107, 34, .8)
}

.careers_tile_link:hover {
    color: rgba(255, 107, 34, .8)
}

.help_tile_text {
    color: #ee733a
}

.help_tile_text:after {
    background-color: rgba(255, 108, 36, .8)
}

.help_tile_text:hover {
    color: rgba(255, 108, 36, .8)
}

.recording_tiles_answer:hover {
    color: #ee733a
}

.map_spot.selected {
    background-color: #ee733a
}

.map_spot.selected:before {
    border-color: #ee733a
}

.map_name {
    color: #ee733a
}

.livestream_share {
    background-color: #ee733a
}

.modal_two_button.secondary {
    background-color: #ee733a
}

.mini_games_stats_timer_innerline {
    background-color: #ee733a
}

.card_likes_new .video_liked {
    border: 2px solid #e97b2675!important
}

@keyframes timer_start1 {
    0% {
        width: 100%
    }
    99% {
        width: 4%;
        background-color: #ee733a
    }
    100% {
        background-color: rgba(217, 57, 50, 0)
    }
}

.main_btn {
    background-color: #ee733a
}

.mg_main_card_buy_price {
    background-color: #ee733a
}

.welcome_context_coin {
    background-color: #ee733a
}

.store_coins {
    background-color: #ee733a
}

.modal_login_general .abtn_main {
    background-color: #e97b26
}

.live_btn.play {
    background-color: #ee733a
}

.bom_btn {
    background-color: #ee733a
}

.bom_btn.secondary {
    background-color: #586890
}

.bom_round_of {
    background-color: #ee733a;
    color: var(--bg-color-primary)
}

.bom_bracket_round_best {
    background-color: #ee733a;
    color: var(--bg-color-primary)
}

.bom_team_scores span {
    color: #ee733a
}

.bom_team_pts {
    color: #ee733a
}

.bom_bracket_team_pts {
    color: #ee733a
}

.community_resources {
    color: #ee733a
}

.community_resources:hover {
    color: #d16e22
}

.community_action {
    background-color: #ee733a
}

.community_action:hover {
    background-color: #d16e22
}

.community_featured_all {
    color: #ee733a
}

.community_featured_all:hover {
    color: #d16e22
}

.community_event_title {
    color: #ee733a
}

.community_event_link {
    color: #ee733a
}

.community_event_link:hover {
    color: #d16e22
}

.announcement_text a {
    color: #ee733a
}

.announcement_text a:hover {
    color: #d16e22
}

.community_announcement_tag {
    background-color: #ee733a
}

.community_announcement_pin.on {
    background-color: #ee733a
}

.community_leaderboard_link {
    color: #ee733a
}

.community_leaderboard_link:hover {
    color: #bf5b30
}

.community_like:hover {
    background-color: rgba(233, 123, 38, .5)
}

.community_like.on {
    border-color: #bf5b30;
    background-color: #ee733a
}

.community_member_link {
    color: #ee733a
}

.community_member_link:hover {
    color: #d16e22
}

.community_member_stats:hover {
    background-color: rgba(233, 123, 38, .5)
}

.community_member.show_management .community_member_stats, .community_member_team.show_management .community_member_stats  {
    background-color: rgba(233, 123, 38, .5);
}

.hub_tabs:before {
    background-color: #ee733a
}

.community_modal a.body_third {
    color: #ee733a
}

.community_modal a.body_third:hover {
    color: #d16e22
}

.community_modal a.small_third {
    color: #ee733a
}

.community_modal a.small_third:hover {
    color: #d16e22
}

.community_email_icon {
    border: 2px solid #ee733a;
    background-color: rgba(238, 115, 58, .6)
}

.community_request_accept {
    color: #ee733a
}

.community_request_accept:hover {
    color: rgba(233, 123, 38, .7)
}

.community_request_dot {
    background-color: #ee733a
}

.community_request_dots:hover .community_request_dot {
    background-color: rgba(233, 123, 38, .7)
}

.com_hamburger_line {
    background-color: #ee733a
}

.com_hamburger {
    background-color: #ee733a
}

.com_dot {
    background-color: #ee733a
}

.faction_title b {
    color: #ee733a
}

.faction_advert_link {
    color: #ee733a
}

.faction_advert_link:hover {
    color: rgba(255, 107, 34, .8)
}

.faction_userbtn:hover {
    border-color: rgba(255, 107, 34, 1);
    background-color: rgba(255, 107, 34, 1)
}

.faction_userbtn.select {
    border-color: rgba(255, 107, 34, 1);
    background-color: rgba(255, 107, 34, 1)
}

.factions.fac-selected .landing_btn.joining {
    background-color: #ee733a
}

.nav_host span {
    color: #f48c18
}

.fw_about_present {
    color: #ea8230
}

.fw_action_fill {
    background-color: #ea8230
}

.fw_btn {
    background-color: #ea8230
}

.fw_info_card_num {
    border: 2px solid #ea8230
}

.bc_four {
    color: #ee733a
}

.landing_header_group {
    color: #ee733a
}

.landing_header .orange {
    color: #ee733a
}

.landing_btn.thirdly {
    background-color: #586890
}

.landing_event_btn {
    background-color: #ee733a
}

.community_modal .hub_btn {
    background-color: #ee733a
}

.event-card .event-name a {
    color: #ee733a
}

.hub-card .hub-name a {
    color: #ee733a
}

.signup_btn {
    color: #ee733a
}

.hub-card .checkout_btn {
    color: #ee733a
}

.event_page_wrap .view_all_event {
    color: #ee733a
}

.landing_slider .view_more a {
    color: #ee733a
}

.landing_slider_title {
    color: #ee733a
}

.cr_btn.orange {
    background-color: #ee733a
}

.qualifier_side_container .show_default_stream {
    background-color: #ee733a
}

.btn-coin {
    background-color: #ee733a
}

.landing_slider_header .slick-prev {
    background-color: #ee733a
}

.landing_slider_header .slick-prev:hover {
    background-color: #e55f1f
}

.landing_slider_header .slick-next {
    background-color: #ee733a
}

.landing_slider_header .slick-next:hover {
    background-color: #e55f1f
}

.landing_slider .slick-prev {
    background-color: #ee733a
}

.landing_slider .slick-prev:hover {
    background-color: #e55f1f
}

.landing_slider .slick-next {
    background-color: #ee733a
}

.landing_slider .slick-next:hover {
    background-color: #e55f1f
}

.game_name {
    background-color: #ee733a
}

.nav_more {
    background-color: #ee733a
}

.section_create_event {
    background-color: #ee733a
}

.filter_tag {
    color: #ee733a
}

.clickable_text {
    color: #ee733a
}

.ml_current_view {
    color: #ee733a
}

.ml_current_view:hover {
    color: rgba(238, 115, 58, .7)
}

.ml_btn {
    background-color: #ee733a
}

.ml_btn:hover {
    background-color: #bf5b30
}

.ml_matches_item_id {
    color: #ee733a
}

.ml_matches_item_more {
    color: #ee733a
}

.ml_matches_item_more:hover {
    color: rgba(233, 123, 38, .7)
}

.ml_link {
    color: #ee733a
}

.ml_killlist_team_value {
    color: #ee733a
}

.ml_killlist_proof_link {
    color: #ee733a
}

.ml_bracket_info_teams_vs {
    color: #ee733a
}

.profile_history_item .profile_history_two {
    color: #ee733a
}

.qualifier_state_info b {
    color: #ee733a
}

.qualifier_section .cr_participate {
    color: #ee733a
}

.cr_event_type {
    border: 2px solid #ee733a;
    color: #ee733a
}

.qualifier_game_icon {
    border: 2px solid #ee733a
}

.qualifier_game_icon.bo {
    border-color: #ee733a
}

.qualifier_game_host a {
    color: #ee733a
}

.qual_player.me .qual_player_icon {
    border-color: #ee733a
}

.rank_info_points {
    color: #ee733a
}

/*.rank_group_points {*/
/*    color: #ee733a*/
/*}*/

.team_box_stats_points {
    color: #ee733a
}

.team_box_dropdown_item:last-child {
    color: #ee733a
}

.rank-fort .cr_side_menu_btn {
    color: #ee733a
}

.standings_close {
    color: #ee733a
}

.standing_holder .rank_round .rank_round_text {
    color: #ee733a
}

.cr_matchup.danger .cr_matchup_userkills {
    color: rgba(195, 37, 52, .5)!important
}

.rank_round_status {
    background-color: #252735;
    color: rgba(241, 141, 47, .8)
}

.btn_revisit_instructions,
.btn_self_report,
.btn_view_tracking_console {
    background-color: #ee733a
}

.q_registered_bottom a {
    color: #ee733a
}

.cr_matchup_points {
    color: #f18d2f
}

.store_card_btn {
    background-color: #ff6b22
}

.modal_store_btn {
    background-color: #ff6b22
}

.shipment_input.wrong input {
    color: #eb4a39
}

.spin_point {
    background-color: #ff6922
}

.spin_point:before {
    background-color: #ff6922
}

.spin_point:after {
    background-color: #ff6922
}

.modal_spin_btn {
    background-color: #ff6b22
}

.header_third {
    color: #ee733a
}

.body_third {
    color: #ff6b22
}

.small_third {
    color: #ff6b22
}

.streamer_btn {
    background-color: #ff6b22
}

.stream_section_content .header_main span {
    color: #ff6b22
}

.stream_section_content .body_main span {
    color: #ff6b22
}

.stream_featured_content .small_third:hover {
    color: rgba(255, 107, 34, .7)
}

.tp_host {
    color: #ee733a
}

.round_next {
    background-color: #ee733a
}

.prediction_selector.on {
    background-color: #ee733a
}

.tp_agree_fill {
    background-color: #ee733a
}

.tp_raffle_sidetitle .title_main b {
    color: #ee733a
}

.tp_raffle_downarrow {
    background-color: #ee733a
}

.tp_score_categories {
    background-color: rgba(255, 107, 34, .75)
}

.avgl.tournament_page .tp_rules ul li a {
    color: #ee733a
}

.raffle_link {
    color: #ee733a
}

.team_box_kill_of_team {
    color: #ee733a
}

.q_friend_btn .orange {
    color: #ff6b22
}

.modal_big .q_btns .radio_btn {
    background-color: #ee733a
}

.q_registered_content .q_member_action a {
    color: #ee733a
}

.dispute_bracket,
.dispute_killrace {
    color: #e97b26
}

.report_issue_text {
    color: #5f7194
}

.match_item_statvalue.faction {
    color: #ee733a
}

.faction_boss:after {
    border: 2px solid #ee733a
}

.faction_boss_zone.target {
    border-color: #ee733a
}

.event-card .card_properties .event-name,
.hub-card .card_properties .hub-name {
    color: #ee733a
}

#event-popup .visit_event_page {
    color: #ee733a
}

#event-popup .event_type {
    border: 2px solid #ee8421;
    color: #ee8421
}

/* drops */
.drops_header_circle_line_inner:before {
    border: 8px solid #ee8421
}

.drops_header_circle_reward {
    color: #ee8421
}

.drops_works_desc a {
    color: #ee8421;
}

.drops_works_card_fill {
    background-color: #ee8421;
}

.drops_works_crown_title span {
    color: #ee8421;
}

.drops_ranks_header_link {
    color: #ee8421;
}

.drops_ranks {
    background: var(--drops-rank-bg);
}

.btn_go_to_drop {
    background-color: #FF3932;
}
/* .landing_socials_container {
    width: calc(100% - 101px);
    transform: translate(101px,0);
}
.landing_socials {
    margin: 0 auto -58px;
}

.landing_socials {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 560px;
    margin: 0 auto -58px;
    z-index: 1
}

.landing_social {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 78px;
    margin: 0 10px;
    border-radius: 2px;
    background-color: #1e232f
}

.landing_social:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color .3s ease-in-out
}

.landing_social:hover:before {
    background-color: rgba(255, 255, 255, .1)
}

.landing_social img {
    position: relative;
    width: initial;
    height: 18px
}
.landing_social img {
    width: initial;
    height: 18px;
}
.landing_social i {
    width: 18px;
    height: 18px;
}

.landing_social .sp-ic-sm-live-3 {
    width: 21px;
}

.landing_social .sp-ic-sm-live-4 {
    width: 16px;
}

@media (max-width:768px) {
    .landing_socials_container {
        width: 100%;
        transform: none
    }
} */
