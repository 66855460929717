.avgl body.aboutp {
    background-color: #111521!important
}

.avgl .aboutp nav {
    border-bottom: 2px solid #1e232f
}

.avgl .aboutp_works {
    background-color: #171c29
}

.avgl .aboutp_team_user_icon {
    background-color: #1e232f
}

.avgl .aboutp_team_user_icon:before {
    background-image: linear-gradient(180deg, rgba(217, 57, 50, 0) 0, rgba(217, 57, 50, .5) 100%)
}

.avgl .aboutp_team_user_topleft {
    background-color: #1e232f
}

.avgl .aboutp_team_user_topleft:before {
    background-color: #1e232f
}

.avgl .aboutp_team_user_topright {
    background-color: #d93932
}

.avgl .aboutp_team_user_topright:before {
    background-color: #d93932
}

.avgl .aboutp_team_user_botleft {
    background-color: #d93932
}

.avgl .aboutp_team_user_botleft:before {
    background-color: #d93932
}

.avgl .aboutp_team_user_botright {
    background-color: #1e232f
}

.avgl .aboutp_team_user_botright:before {
    background-color: #1e232f
}

.avgl .aboutp_trusted:before {
    background-color: #d93932
}

.avgl .aboutp_testimonials {
    background-color: #111521;
    background: url(../../assets/about-testimonial.png) no-repeat #111521
}

.avgl .aboutp_testimonial_usericon {
    background-color: rgba(217, 57, 50, .5)
}

@keyframes animationBackground1 {
    0% {
        background-color: #1e232f
    }
    100% {
        background-color: var(--bg-color)
    }
}

.avgl .admin_matchup.animate {
    animation-name: animationBackground1;
    animation-duration: 2s
}

.avgl .admin_page {
    background-color: var(--bg-color)
}

.avgl .admin_page nav {
    border-bottom: 2px solid #1e232f
}

.btn_update.orange {
    background-color: #d93932
}

.avgl .nav_host span {
    color: #d93932
}

.avgl .admin_login_titles {
    border-color: #1e232f
}

.avgl .admin_login_titles:after {
    background-color: #d93932
}

.avgl .admin_side {
    background-color: #1e232f
}

.avgl .admin_dashboard {
    background-color: #1e232f
}

.avgl .admin_steps_item {
    border-color: var(--bg-color-primary)
}

.avgl .admin_step {
    color: #d93932;
    background-color: rgba(23, 28, 41, 0)
}

.avgl .admin_step.on,
.avgl .admin_step:hover {
    background-color: var(--bg-color-primary)
}

.avgl .admin_event {
    background-color: var(--bg-color-primary)
}

.avgl .admin_event_about {
    color: #d93932
}

.avgl .admin_step.done:first-child .astep_num {
    border-color: rgba(217, 57, 50, .5)
}

.avgl .admin_step.done:first-child .astep_desc {
    color: rgba(217, 57, 50, .5)
}

.avgl .admin_step.on .astep_num {
    border-color: #d93932;
    color: #d93932
}

.avgl .admin_step.on .astep_desc {
    color: #d93932
}

.avgl .abtn_main {
    background-color: #d93932
}

.avgl .abtn_secondary {
    background-color: #5f7194
}

.avgl .admin_events {
    background-color: #1e232f
}

.avgl .admin_events_item {
    border-top: 2px solid var(--bg-color-primary);
    background-color: rgba(23, 28, 41, 0)
}

.avgl .admin_events_item.on,
.avgl .admin_events_item:hover {
    background-color: var(--bg-color-primary)
}

.avgl .admin_events_item_image {
    border-color: var(--bg-color-primary)
}

.avgl .admin_events_item.on .admin_events_item_image,
.avgl .admin_events_item:hover .admin_events_item_image {
    border-color: #d93932
}

.avgl .view_team_results .back_to_scoreboard {
    color: #d93932
}

.avgl .view_team_results .admin_table {
    border-color: #1e232f
}

.avgl .view_team_results .admin_table_title {
    border-color: #1e232f
}

.avgl .view_team_results .admin_table_btn {
    background-color: #d93932
}

.avgl .view_team_results .admin_table_btn:hover {
    background-color: #ae2d28
}

.avgl .view_team_results .admin_table input {
    border-color: #1e232f
}

.avgl .tournament_url_holder_tweet .orange {
    background-color: #d93932!important
}

.avgl .tweet_section a {
    color: #d93932
}

.avgl .tournament_url_input a {
    color: #d93932
}

.avgl .admin_item_btn {
    background-color: #d93932
}

.avgl .admin_item_btn.orange {
    background-color: #d93932
}

.avgl .update_game_name_btn.disabled {
    background: #1e232f
}

.avgl .admin_btn {
    background-color: #d93932
}

.avgl .admin_btn:hover {
    background-color: #ae2d28
}

.avgl .admin_matchup.collapse .admin_matchups {
    background-color: #1e232f
}

.avgl .mini_tab_buttons .abtn_secondary:hover:before {
    background-color: #1e232f
}

.avgl .or {
    color: #d93932
}

.avgl a.or:hover {
    color: #ae2d28
}

.avgl .admin_check_label input:checked~.admin_checkbox {
    border-color: #d93932;
    background-color: rgba(217, 57, 50, .7)
}

.avgl .admin_checkbox {
    background-color: rgba(217, 57, 50, 0);
    border-color: #1e232f
}

.avgl .admin_line {
    border-color: #1e232f
}

.avgl .admin_input {
    border-color: #1e232f
}

.avgl .admin_clickable {
    color: #d93932
}

.avgl .admin_textarea {
    border-color: #1e232f
}

.avgl .admin_dropdown_items {
    background-color: #1e232f
}

.avgl .admin_dropdown_item:hover {
    background-color: #313545
}

.avgl .admin_calendar {
    border-color: #1e232f
}

.avgl .admin_calendar_icon:before {
    background-color: #1e232f
}

.avgl .admin_advanced {
    border-color: #1e232f;
    background-color: #1e232f
}

.avgl .admin_advanced.show .admin_advanced_btn {
    border-color: #1e232f
}

.avgl .advanced_tracking_premium {
    background-color: #d93932
}

.avgl .admin_item {
    border-color: #1e232f;
    background-color: rgba(30, 35, 47, 0)
}

.avgl .admin_item:hover {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .admin_item_btn.secondary {
    background-color: #d93932
}

.avgl .admin_items_expand {
    background-color: var(--bg-color-primary)
}

.avgl .admin_matchup .admin_matchup_section .abtn_main.update {
    background-color: #d93932
}

.avgl .admin_matchup.done .admin_matchup_section .abtn_main.update,
.avgl .admin_matchup.finished .admin_matchup_section .abtn_main.update {
    background-color: #d93932
}

.avgl .admin_matchup_container {
    background-color: #1e232f
}

.avgl .admin_matchups_username:hover {
    color: #d93932
}

.avgl .admin_matchups_userwin {
    background-color: #1e232f
}

.avgl .admin_matchup.finished .admin_matchup_items.advance .admin_matchup_team_container {
    background-color: #1e232f
}

.avgl .admin_team {
    border-color: rgba(30, 35, 47, .5);
    background-color: rgba(30, 35, 47, .5)
}

.avgl .matchup_col {
    border-color: #1e232f
}

.avgl .matchup_col .match_bracket_item table {
    background-color: #1e232f!important
}

.avgl .matchup_col .match_bracket_item table:before {
    background-color: var(--bg-color)
}

.avgl .admin_matchup_detail_holder {
    background-color: var(--bg-color)
}

.avgl .matchup_col .title_bracket_item {
    background-color: #1e232f
}

.avgl .match_popup_holder {
    background-color: #1e232f
}

.avgl .match_popup_children {
    background-color: #1e232f
}

.avgl .body_boom_modal_content ul.list-proceed-event li a {
    color: #d93932
}

.avgl .admin_team_item_users_team {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .match_btn {
    background-color: #d93932
}

.avgl .admin_team_item_user_list {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .admin_team_item.collapse {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .admin_team_item.collapse .admin_team_item_user {
    background-color: rgba(30, 35, 47, 0)
}

.avgl .admin_switch {
    border-color: #1e232f;
    background-color: rgba(30, 35, 47, .5)
}

.avgl .admin_switch_box_circle {
    background-color: #1e232f
}

.avgl .admin_bracket_category {
    background-color: #1e232f
}

.avgl .admin_bracket_teams:before {
    border-color: rgba(217, 57, 50, 0)
}

.avgl .admin_bracket_teams:hover:before {
    border-color: #d93932
}

.avgl .admin_bracket_team {
    background-color: #1e232f
}

.avgl .admin_bracket_team_num {
    background-color: #1e232f
}

.avgl .admin_bracket_team_pts {
    color: #d93932;
    border-color: #1e232f
}

.avgl .admin_bracket_inspect {
    background-color: #1e232f
}

.avgl .admin_bracket_schedule {
    background-color: var(--bg-color-primary)
}

.avgl .admin_login_input {
    border-color: #1e232f
}

.avgl .admin_event_image {
    border-color: #d93932
}

.avgl .admin_input_tag {
    border-color: #1e232f
}

.avgl .admin_dropdown_main {
    border-color: #1e232f
}

.avgl .advanced_tracking_options {
    border-color: #1e232f
}

.avgl .admin_cats {
    border-color: #1e232f
}

.avgl .admin_matchup_value {
    border-color: #353748
}

.avgl table.table-bordered>tbody>tr>td {
    border-color: #1e232f
}

.avgl table.table-bordered>thead>tr>th {
    border-color: #1e232f
}

.avgl table.table-bordered {
    border-color: #1e232f
}

.avgl .game_type_value {
    color: rgba(217, 57, 50, .75)
}

.avgl #modal_provide_game_name_admin_app .landing_btn {
    background-color: #d93932
}

.avgl #add_more_mods,
.avgl #add_more_supporters {
    background-color: #d93932
}

.avgl nav {
    background-color: #1e232f
}

.avgl .gradient {
    background: linear-gradient(0deg, var(--bg-color-primary), rgba(30, 35, 47, 0)), linear-gradient(90deg, #362929, #1e232f)
}

.avgl .background_big {
    background: url(../../assets/background.png) #1e232f
}

.avgl .gradient_big {
    background: linear-gradient(0deg, #1e232f, rgba(30, 35, 47, 0))
}

.avgl .nav_dropdown {
    border: 2px solid #26272e
}

.avgl .dropdown_link {
    background-color: rgba(30, 35, 47, .95);
    border-bottom: 2px solid #26272e
}

.avgl .play_circle {
    background-color: #d93932
}

.avgl .card_properties {
    background-color: #1e232f
}

.avgl .card_likes img {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .card_stats {
    background-color: #1e232f
}

.avgl .stats_options_download,
.avgl .stats_options_share {
    background-color: #1e232f
}

.avgl .stats_options_download:before {
    border-bottom: 5px solid #1e232f
}

.avgl .stats_options_download:hover,
.avgl .stats_options_share:hover {
    background-color: #1e232f
}

.avgl .load_more {
    background-color: var(--bg-color-primary);
    border: 2px solid #1e232f;
    color: #d93932
}

.avgl footer {
    background-color: var(--bg-color-primary)
}

.avgl .about_link {
    color: rgba(217, 57, 50, .75)
}

.avgl .about_link:before {
    background-color: #d93932
}

.avgl .about_link:hover {
    color: #d93932
}

.avgl .header_btn_box {
    background-color: #d93932
}

.avgl .headlines {
    background-color: rgba(20, 21, 27, .8)
}

.avgl .headline_button {
    color: rgba(217, 57, 50, .8)
}

.avgl .headline_button:after {
    background-color: #d93932
}

.avgl main.landing {
    background-color: #1e232f
}

.avgl .landing .features_card {
    background-color: var(--bg-color-primary)
}

.avgl .opinions {
    background-color: var(--bg-color-primary)
}

.avgl .videos {
    background-color: var(--bg-color-primary)
}

.avgl .tile {
    background-color: var(--bg-color-primary)
}

.avgl .team {
    background-color: var(--bg-color-primary)
}

.avgl .investors {
    background-color: var(--bg-color-primary)
}

.avgl .subscribe_button_shadow {
    background-color: #d93932
}

.avgl .subscribe_link {
    background-color: #d93932!important
}

.avgl .unsubscribe_link {
    background-color: #d93932!important
}

.avgl .vr_ready_about {
    background-color: var(--bg-color-primary)
}

.avgl .subscribe_box {
    background-color: var(--bg-color-primary)
}

.avgl .contact_button {
    background-color: #d93932
}

.avgl .contact_card {
    background-color: var(--bg-color-primary)
}

.avgl .contact_card_link {
    color: rgba(217, 57, 50, .9)
}

.avgl .contact_card_link:after {
    background-color: #d93932
}

.avgl .contact_card_link:hover {
    color: #d93932
}

.avgl .quick_tips_card {
    background-color: var(--bg-color-primary)
}

.avgl main.landing.three {
    background-color: var(--bg-color-primary)
}

.avgl .landing.three .gradient_big {
    background: linear-gradient(0deg, var(--bg-color-primary), rgba(23, 28, 41, 0))
}

.avgl .tips_card_info {
    color: #d93932
}

.avgl .tips_card_link {
    color: #d93932
}

.avgl .tips_card_link:after {
    background-color: rgba(217, 57, 50, .8)
}

.avgl .tips_card_link:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .landing .headlines {
    background-color: rgba(22, 23, 33, .8)
}

.avgl .related_link {
    color: #d93932
}

.avgl .related_link:after {
    background-color: rgba(217, 57, 50, .8)
}

.avgl .related_link:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .features.three {
    background-color: var(--bg-color-primary);
    border-bottom: 2px solid #1e232f
}

.avgl .features_title_link {
    color: #d93932
}

.avgl .features_title_link:after {
    background-color: rgba(217, 57, 50, .8)
}

.avgl .features_title_link:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl #landing footer {
    background-color: var(--bg-color-primary)
}

.avgl .careers_tiles {
    background-color: var(--bg-color-primary)
}

.avgl .help_tile {
    background-color: var(--bg-color-primary)
}

.avgl .careers_tile_link {
    color: #d93932
}

.avgl .careers_tile_link:after {
    background-color: rgba(217, 57, 50, .8)
}

.avgl .careers_tile_link:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .help_tile_text {
    color: #d93932
}

.avgl .help_tile_text:after {
    background-color: rgba(217, 57, 50, .8)
}

.avgl .help_tile_text:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .recording_tiles {
    background-color: var(--bg-color-primary)
}

.avgl .recording_tiles_answer:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .profile_card {
    background-color: #1e232f
}

.avgl .user_context_edit {
    border: 2px solid #26272e
}

.avgl .profile_user_stats_box {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .profile_user_stat.views {
    border-right: 2px solid #26272e
}

.avgl .game_accounts_link {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .modal_container {
    background-color: #1e232f
}

.avgl .modal_buttons {
    background-color: #1e232f
}

.avgl .events_title {
    background-color: var(--bg-color-primary)
}

.avgl .videos_switch {
    background-color: #1e232f
}

.avgl .videos_switch_box {
    background-color: var(--bg-color-primary)
}

.avgl .map_spot.selected {
    background-color: #d93932
}

.avgl .map_spot.selected:before {
    border-color: #d93932
}

.avgl .map_info {
    background-color: #1e232f
}

.avgl .map_name {
    color: #d93932
}

.avgl .vr_faq {
    background-color: var(--bg-color-primary)
}

.avgl .vr_more {
    background-color: var(--bg-color-primary)
}

.avgl nav.events {
    background-color: rgba(23, 28, 41, .7)
}

.avgl .livestream_share {
    background-color: #d93932
}

.avgl .livestream_share_option {
    background-color: var(--bg-color-primary)
}

.avgl .boom_meter_modal_video:before {
    background: -webkit-linear-gradient(90deg, rgba(30, 35, 47, .8), rgba(30, 35, 47, 0));
    background: linear-gradient(90deg, rgba(30, 35, 47, .8), rgba(30, 35, 47, 0))
}

.avgl .boom_meter_preview {
    background-color: var(--bg-color-primary)
}

.avgl .boom_meter_about {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .boom_meter_modal_container {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .boom_meter_modal {
    background-color: #1e232f
}

.avgl .boom_meter_modal_btn_holder {
    background-color: #1e232f
}

.avgl .recommended_content {
    background-color: var(--bg-color-primary)
}

.avgl .recommended_card_top {
    background-color: #1e232f
}

.avgl .recommended_card_bottom {
    background-color: var(--bg-color-primary)
}

.avgl .recommended_show_btn {
    background-color: #1e232f
}

.avgl .modal_two_button.secondary {
    background-color: #d93932
}

.avgl .modal_two.image .modal_function {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .team_pane {
    background-color: #1e232f
}

.avgl .team_info {
    background-color: var(--bg-color-primary)
}

.avgl .team_member:hover {
    background-color: var(--bg-color-primary)
}

.avgl .mini_games_game {
    background-color: var(--bg-color-primary)
}

.avgl .mini_games_stats_timer_line {
    background-color: var(--bg-color-primary)
}

.avgl .mini_games_stats_timer_innerline {
    background-color: #d93932
}

.avgl .card_likes_new .video_liked {
    border: 2px solid #d93932!important
}

.avgl .modal_login_general .abtn_main {
    background-color: #d93932
}

@keyframes timer_start1 {
    0% {
        width: 100%
    }
    99% {
        width: 4%;
        background-color: #d93932
    }
    100% {
        background-color: rgba(217, 57, 50, 0)
    }
}

.avgl .mini_games_stats_timer_message.start~.mini_games_stats_timer_line .mini_games_stats_timer_innerline {
    animation: timer_start1 105s linear
}

.avgl .mini_games_stats_timer_message.ready~.mini_games_stats_timer_line .mini_games_stats_timer_innerline {
    background-color: rgba(217, 57, 50, 0)
}

.avgl .modal_big {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .modal_big_container {
    background-color: var(--avgl-bg-secondary)
}

.avgl .main_btn {
    background-color: #d93932
}

.avgl .tokens_balance {
    background-color: #1e232f
}

.avgl .main_btn.ended {
    background-color: #1e232f
}

.avgl .modal_footer {
    background-color: #1e232f
}

.avgl .mg_main_account_user {
    background-color: #1e232f
}

.avgl .mg_main_account_stats {
    background-color: #1e232f
}

.avgl .mg_main_stats_coins {
    background-color: #1e232f
}

.avgl .mg_main_card {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .mg_main_card_buy_btn {
    background-color: #1e232f
}

.avgl .mg_main_card_buy_price {
    background-color: #ff3932
}

.avgl .mg_main_card_buy_unavailable {
    background-color: var(--bg-color-primary)
}

.avgl .mg_main_card.event {
    background-color: #1e232f
}

.avgl .mg_main_card_announcment {
    background-color: #1e232f
}

.avgl .mg_main_loading_lineholder {
    background-color: #1e232f
}

.avgl .mg_main_loading_line {
    background-color: #d93932
}

.avgl .mg_leaderboard {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .mg_leaderboard_sort {
    background-color: #1e232f
}

.avgl .mg_leaderboard_ranking_item {
    background-color: #1e232f
}

.avgl .welcome_context_coin {
    background-color: #d93932
}

.avgl .notifications_line {
    background-color: #1e232f
}

.avgl .notifications_line .main_btn.on {
    background-color: #1e232f
}

.avgl .store_coins {
    background-color: #d93932
}

.avgl .vr_available {
    border-right: 2px solid var(--bg-color-primary)
}

.avgl .mini_games_background:after {
    background: linear-gradient(0deg, var(--bg-color-primary), rgba(23, 28, 41, 0))
}

.avgl .mg_leaderboard_ranking_list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 50px var(--bg-color-primary);
    border-radius: 3px
}

@media all and (max-width:696px) {
    .avgl .headline_button:after {
        background-color: rgba(217, 57, 50, 0)
    }
}

@media all and (max-width:919px) {
    .avgl .profile_user_stat:last-child {
        border-top: 2px solid #26272e
    }
}

@media all and (max-width:769px) {
    .avgl .profile_user_stat:nth-child(3) {
        border-top: 2px solid #26272e;
        border-right: 2px solid #26272e
    }
    .avgl .profile_user_stat:last-child {
        border-top: 2px solid #26272e
    }
}

@media all and (max-width:533px) {
    .avgl .profile_user_stat,
    .avgl .profile_user_stat.views,
    .avgl .profile_user_stat:last-child,
    .avgl .profile_user_stat:nth-child(3) {
        border-bottom: 2px solid #26272e
    }
}

.avgl .betting_page nav {
    background-color: #1e232f
}

.avgl .betting_page footer {
    background-color: #1e232f
}

.avgl .betting_user {
    background-color: rgba(30, 35, 47, .1)
}

.avgl .betting_option {
    border: 2px solid rgba(30, 35, 47, .55);
    background-color: #1e232f
}

.avgl .betting_option_dot {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .betting_value {
    background-color: #1e232f;
    border: 2px solid #26272e
}

.avgl .betting_down {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .betting_up {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .betting_bar {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .betting_btn {
    background-color: #1e232f
}

.avgl .betting_ammount {
    border: 2px solid #26272e;
    background-color: #1e232f
}

.avgl .betting_pool.selected .betting_value {
    background-color: #1e232f
}

.avgl .loot_win {
    background-color: rgba(23, 28, 41, .95)
}

.avgl .loot_lose {
    background-color: rgba(23, 28, 41, .95)
}

.avgl .modal_bet_container {
    background-color: #1e232f
}

.avgl .live_page {
    background-color: var(--bg-color)
}

.avgl .live_page footer {
    background-color: #1e232f
}

.avgl .live_btn.play {
    background-color: #d93932
}

.avgl .live_social {
    background-color: #212124
}

.avgl .text_bet_info {
    color: #d93932
}

.avgl .match_info {
    background-color: rgba(23, 28, 41, .9)
}

.avgl .match_info_container {
    border: 1px solid #1e232f;
    background-color: var(--bg-color-primary)
}

.avgl .match_info_mod {
    background-color: #1e232f
}

.avgl .match_info_team_menu {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .match_info_team_score {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .match_info_team_score_more_icon {
    background-color: #1e232f
}

.avgl .match_info_team_players {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .match_info_team_results {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .match_info_team_users {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .bom_side:before {
    background-color: rgba(23, 28, 41, .9)
}

.avgl .bom_btn {
    background-color: #d93932
}

.avgl .bom_btn.secondary {
    background-color: #586890
}

.avgl .bom_round_of {
    background-color: #d93932;
    color: var(--bg-color-primary)
}

.avgl .bom_team_icon {
    background-color: var(--bg-color-primary)
}

.avgl .bom_team_icon:before {
    background-color: var(--bg-color-primary)
}

.avgl .bom_item_action.collapse {
    color: var(--bg-color-primary)
}

.avgl .bom_bracket {
    background-color: rgba(23, 28, 41, .9)
}

.avgl .bom_bracket_container {
    border: 1px solid #1e232f;
    background-color: var(--bg-color-primary)
}

.avgl .bom_bracket_round_about {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .bom_bracket_round_best {
    background-color: #d93932;
    color: var(--bg-color-primary)
}

.avgl .bom_bracket_team {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .bom_team_scores span {
    color: #d93932
}

.avgl .bom_team_pts {
    color: #d93932
}

.avgl .bom_bracket_team_pts {
    color: #d93932
}

.avgl .ml_bracket_menu_switch {
    border-top: 1px solid #1e232f
}

.avgl .ml_bracket_actions {
    border: 2px solid #1e232f
}

.avgl .cr_chat_menu {
    border-bottom: 1px solid #1e232f
}

.avgl .cr_chat_more {
    background-color: #1e232f
}

.avgl .cr_chat_more:hover {
    background-color: var(--cr-chat-more-hover-avgl-bg-color)
}

.avgl .cr_chat_menu .dropdown .nav_link {
    background-color: var(--cr-chat-more-bg-color)
}

.avgl .cr_chat_menu .dropdown .nav_link:hover {
    background-color: var(--cr-chat-more-hover-avgl-bg-color)
}

.avgl .cr_chat_menu .dropdown .dropdown_item {
    background-color: #1e232f
}

.avgl .cr_chat_menu .dropdown .dropdown_item:hover {
    background-color: #26272e
}

.avgl .cr_mobile_display {
    border: 2px solid #1e232f
}

.avgl .community_resources {
    color: #d93932
}

.avgl .community_resources:hover {
    color: #ae2d28
}

.avgl .community_action {
    background-color: #d93932
}

.avgl .community_action:hover {
    background-color: #ae2d28
}

.avgl .community_content {
    background-color: var(--bg-color-primary)
}

.avgl .community_menu {
    background-color: #1e232f
}

.avgl .community_featured_all {
    color: #d93932
}

.avgl .community_featured_all:hover {
    color: #ae2d28
}

.avgl .community_event_info {
    background-color: var(--community-event-info-bg);
}

.avgl .light_theme .community_event_info {
    background: linear-gradient(-45deg, transparent 10px, #fff 10px)
}

.avgl .community_event_title {
    color: #d93932
}

.avgl .community_event_link {
    color: #d93932
}

.avgl .community_event.big .community_event_info .community_event_title {
    color: #ffffff !important;
}

.avgl .community_event_link:hover {
    color: #ae2d28
}

.avgl .community_announcement {
    background-color: #1e232f
}

.avgl .community_announcement_event:after {
    background-image: linear-gradient(270deg, var(--avgl-bg-secondary) 0, rgba(30, 35, 47, .5) 100%)
}

.avgl .announcement_text a {
    color: #d93932
}

.avgl .announcement_text a:hover {
    color: #ae2d28
}

.avgl .community_announcement_tag {
    background-color: #d93932
}

.avgl .community_announcement_pin.on {
    background-color: #d93932
}

.avgl .community_leaderboard {
    background-color: #1e232f
}

.avgl .community_leaderboard_link {
    color: #d93932
}

.avgl .community_leaderboard_link:hover {
    color: #ae2d28
}

.avgl .community_dropdown_main {
    background-color: var(--community-dropdown-main-bg-color);
    border: var(--community-dropdown-main-border)
}

.avgl .community_dropdown_main:hover {
    background-color: var(--community-dropdown-main-hover)
}

.avgl .community_dropdown_items {
    background-color: var(--community-dropdown-main-bg-color);
    border: var(--community-dropdown-main-bg-color)
}

.avgl .community_dropdown_item {
    background-color: var(--community-dropdown-main-bg-color)
}

.avgl .community_dropdown_item:hover {
    background-color: var(--community-dropdown-main-hover)
}

.avgl .community_leaderboard_users::-webkit-scrollbar-track {
    background-color: #1e232f
}

.avgl .community_leaderboard_user {
    background-color: #1e232f
}

.avgl .community_play {
    background-color: #1e232f
}

.avgl .community_like:hover {
    background-color: rgba(217, 57, 50, .5)
}

.avgl .community_like.on {
    border-color: #ae2d28;
    background-color: #d93932
}

.avgl .community_member_link {
    color: #d93932
}

.avgl .community_member_link:hover {
    color: #ae2d28
}

.avgl .community_member {
    background-color: #1e232f
}

.avgl .community_member_stats:hover {
    background-color: rgba(217, 57, 50, .5)
}

.avgl .community_member.show_management .community_member_stats, .community_member_team.show_management .community_member_stats {
    background-color: rgba(156, 12, 4, 0.5);
}

.avgl .community_modal_container {
    background-color: var(--avgl-bg-secondary)
}

.avgl .community_modal_boarding .community_modal_container {
    background-color: #1e232f;
}

.avgl .community_modal_boarding .admin_dropdown_items {
    background-color: #1e232f;
}

.avgl .community_modal_boarding .com_input {
    color: #FFFFFF;
}

.avgl .com_input {
    border: 2px solid #343744
}

.avgl textarea.com_input::-webkit-scrollbar-track {
    background-color: #1e232f
}

.avgl .community_modal_top {
    background-color: #1e232f
}

.avgl .hub_tabs:before {
    background-color: #d93932
}

.avgl .community_modal_bot {
    background-color: #1e232f
}

.avgl .community_leadersline {
    background-color: #1e232f
}

.avgl .community_modal_stat_item {
    background-color: #1e232f
}

.avgl .community_hub_iconholder {
    border: 2px solid #1e232f
}

.avgl .community_hub_bannerholder {
    border: 2px solid #1e232f
}

.avgl .community_modal a.body_third {
    color: #d93932
}

.avgl .community_modal a.body_third:hover {
    color: #ae2d28
}

.avgl .community_modal a.small_third {
    color: #d93932
}

.avgl .community_modal a.small_third:hover {
    color: #ae2d28
}

.avgl .community_email_icon {
    border: 2px solid #d93932;
    background-color: rgba(217, 57, 50, .6)
}

.avgl .community_email_icon_email {
    background-color: var(--bg-color-primary);
    border: 1px solid #1e232f
}

.avgl .community_game_main {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .community_game_list {
    border: 1px solid #1e232f
}

.avgl .community_request {
    background-color: #1e232f
}

.avgl .community_request_accept {
    color: #d93932
}

.avgl .community_request_accept:hover {
    color: rgba(217, 57, 50, .7)
}

.avgl .community_right.spec {
    background-color: var(--community-avgl-right-spec-bg-color)
}

.avgl .community_request_dot {
    background-color: #d93932
}

.avgl .community_request_dots:hover .community_request_dot {
    background-color: rgba(217, 57, 50, .7)
}

.avgl .pending_event_info {
    background-color: #1e232f
}

.avgl .com_hamburger_line {
    background-color: #d93932
}

.avgl .com_hamburger {
    background-color: #d93932
}

.avgl .com_dot {
    background-color: #d93932
}

.avgl .community_discord_placeholder {
    color: #d93932
}

.avgl .factions_container {
    background-color: var(--bg-color-primary)
}

.avgl .faction_header {
    background-color: #1e232f
}

.avgl .faction_title b {
    color: #d93932
}

.avgl .faction_footer {
    border-top: 4px solid #1e232f
}

.avgl .faction_advert_link {
    color: #d93932
}

.avgl .faction_advert_link:hover {
    color: rgba(217, 57, 50, .8)
}

.avgl .faction_side {
    background-color: rgba(23, 28, 41, .7)
}

.avgl .faction_user {
    border-top: 1px solid rgba(30, 35, 47, .75)
}

.avgl .faction_userbtn:hover {
    border-color: rgba(217, 57, 50, 1);
    background-color: rgba(217, 57, 50, 1)
}

.avgl .faction_userbtn.select {
    border-color: rgba(217, 57, 50, 1);
    background-color: rgba(217, 57, 50, 1)
}

.avgl .factions.fac-selected .landing_btn.joining {
    background-color: #d93932
}

.avgl .faction_war_page nav {
    border-bottom: 2px solid #1e232f
}

.avgl .nav_host span {
    color: #d93932
}

.avgl .fw_about_present {
    color: #d93932
}

.avgl .fw_about_upcoming_btn {
    color: var(--bg-color-primary)
}

.avgl .fw_action_fill {
    background-color: #d93932
}

.avgl .fw_btn {
    background-color: #d93932
}

.avgl .fw_action_url_copy {
    background-color: #1e232f
}

.avgl .fw_info_cards {
    border: 2px solid #1e232f
}

.avgl .fw_info_card_num {
    border: 2px solid #d93932
}

.avgl .fw_action {
    background-color: rgba(23, 28, 41, .9)
}

.avgl .faction_dropdown_item {
    background-color: rgba(23, 28, 41, .9)
}

.avgl .faction_dropdown_item:hover {
    background-color: rgba(23, 28, 41, .8)
}

.avgl .howto-bg {
    background-color: var(--bg-color-primary)
}

.avgl .howto-gradient {
    background-image: linear-gradient(to top, var(--bg-color-primary) 0, rgba(23, 28, 41, 0) 50%, rgba(23, 28, 41, 0) 100%), linear-gradient(140deg, #1a1d21, rgba(26, 29, 33, 0), #1a1d21);
    background-color: rgba(23, 28, 41, .4)
}

.avgl .ht_faq_main {
    background-color: #1e232f
}

.avgl .hypernet footer,
.avgl .hypernet nav {
    background-color: var(--bg-color-primary)
}

.avgl .option_about {
    color: var(--bg-color-primary)
}

.avgl .daily_item.locked .daily_action {
    background-color: #1e232f
}

.avgl .bc_line {
    background-color: #1e232f
}

.avgl .bc_four {
    color: #d93932
}

.avgl .bc_add {
    background-color: #1e232f
}

.avgl .bc_offer input:checked~.bc_line .bc_valute {
    color: #1e232f
}

.avgl .bc_offer input:checked~.bc_line .bc_discount {
    color: rgba(30, 35, 47, .5)
}

.avgl .bc_offer input:checked~.bc_line .bc_bonus {
    color: #1e232f
}

.avgl .landing_page nav {
    background-color: #1e232f
}

.avgl .landing_side {
    box-shadow: 1px 0 9px rgba(23, 28, 41, .1);
    background-color: var(--landing-side-avgl-bg-color)
}

.avgl .landing_header_overlay:before {
    background-color: rgba(30, 35, 47, .7)
}

.avgl .landing_header_overlay:after {
    background-image: linear-gradient(180deg, #1e232f 0, rgba(30, 35, 47, 0) 100%)
}

.avgl .landing_header_group {
    color: #d93932
}

.avgl .landing_header .orange {
    color: #d93932
}

.avgl .landing_btn.thirdly {
    background-color: #5f7194
}

.avgl .landing_btn:not(.gray) {
    background-color: #d93932
}

.avgl .landing_event_btn {
    background-color: #d93932
}

.avgl .community_modal .hub_btn {
    background-color: #d93932
}

.avgl .event-card .event-name a {
    color: #d93932
}

.avgl .hub-card .hub-name a {
    color: #d93932
}

.avgl .event-card .signup_btn {
    color: #d93932
}

.avgl .hub-card .checkout_btn {
    color: #d93932
}

.avgl .event_page_wrap .view_all_event {
    color: #d93932
}

.avgl .landing_slider .view_more a {
    color: #d93932
}

.avgl .landing_slider_title {
    color: #d93932
}

.avgl .cr_btn.orange {
    color: #fff;
    background-color: #d93932
}

.avgl .qualifier_side_container .show_default_stream {
    background-color: #d93932
}

.avgl .btn-coin {
    background-color: #d93932
}

.avgl .landing_slider_header .slick-prev {
    background-color: #d93932
}

.avgl .landing_slider_header .slick-prev:hover {
    background-color: #ae2d28
}

.avgl .landing_slider_header .slick-next {
    background-color: #d93932
}

.avgl .landing_slider_header .slick-next:hover {
    background-color: #ae2d28
}

.avgl .landing_slider .slick-prev {
    background-color: #d93932
}

.avgl .landing_slider .slick-prev:hover {
    background-color: #ae2d28
}

.avgl .landing_slider .slick-next {
    background-color: #d93932
}

.avgl .landing_slider .slick-next:hover {
    background-color: #ae2d28
}

.avgl .game_soon {
    text-shadow: 0 0 3px rgba(30, 35, 47, .85)
}

.avgl .game_name {
    background-color: #d93932
}

.avgl .landing_social {
    background-color: var(--bg-color-secondary)
}

.avgl .dropdown_items {
    border: 2px solid #1e232f;
    box-shadow: 0 5px 42px 1px rgba(23, 28, 41, .22)
}

.avgl .dropdown_item {
    background-color: rgba(30, 35, 47, .95);
    border-bottom: 2px solid #1e232f
}

.avgl .nav_more {
    background-color: #d93932
}

.avgl .login_tooltip {
    background-color: #1e232f
}

.avgl .login_tooltip:before {
    border-bottom: 14px solid #1e232f
}

.avgl .section_create_event {
    background-color: #d93932
}

.avgl .filter_tag {
    color: #d93932
}

.avgl .clickable_text {
    color: #d93932
}

.avgl .match_list_more {
    border: 1px solid var(--bg-color-primary)
}

.avgl .match_list_more_bar {
    background-color: #1e232f
}

.avgl .ml_current {
    border-top: 2px solid #1e232f
}

.avgl .ml_current_view {
    color: #d93932
}

.avgl .ml_current_view:hover {
    color: rgba(217, 57, 50, .7)
}

.avgl .match_list_right {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .ml_btn {
    background-color: #d93932
}

.avgl .ml_btn:hover {
    background-color: #ae2d28
}

.avgl .ml_btn.secondary {
    background-color: #5f7194
}

.avgl .ml_btn.secondary:hover {
    background-color: #4c5a77
}

.avgl .ml_matches_item {
    border-bottom: 1px solid #1e232f
}

.avgl .ml_matches_item_id {
    color: #d93932
}

.avgl .ml_matches_item_more {
    color: #d93932
}

.avgl .ml_matches_item_more:hover {
    color: rgba(217, 57, 50, .7)
}

.avgl .ml_link {
    color: #d93932
}

.avgl .ml_killlist_team_value {
    color: #d93932
}

.avgl .ml_killlist_proof_link {
    color: #d93932
}

.avgl .ml_bracket_info_teams_vs {
    color: #d93932
}

.avgl .match_dispute_container {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .match_dispute_textarea {
    border: 2px solid #1e232f
}

.avgl .ml_matches_categories {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_killlist_team {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_killlist_teamname {
    border-bottom: 1px solid rgba(30, 35, 47, .5)
}

.avgl .ml_killlist_team_stat {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_killlist_proof {
    border-top: 1px solid rgba(30, 35, 47, .5)
}

.avgl .ml_bracket_info {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_bracket_info_about_stats_score {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_bracket_info_details {
    border-top: 1px solid rgba(30, 35, 47, .5)
}

.avgl .ml_bracket_game {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .ml_bracket_game_team {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .match_dispute {
    background-color: rgba(23, 28, 41, .5)
}

.avgl .profile_history_categories {
    background-color: #1e232f
}

.avgl .profile_history_item {
    background-color: var(--bg-color-primary)
}

.avgl .profile_history_item .profile_history_two {
    color: #d93932
}

.avgl .qualifier_header {
    background-color: var(--bg-color-primary)
}

.avgl .qualifier_profile:after {
    background-image: linear-gradient(270deg, var(--bg-color-primary) 0, rgba(23, 28, 41, .37) 100%)
}

.avgl .qualifier_state_info b {
    color: #d93932
}

.avgl .qualifier_progress:before {
    background-color: var(--bg-color-primary)
}

.avgl .cr_event_group {
    background-color: var(--cr-event-group-avgl-bg-color)
}

.avgl .cr_event:before {
    background-color: var(--cr-event-group-avgl-bg-color)
}

.avgl .qualifier_section .cr_participate {
    color: #d93932
}

.avgl .cr_event_type {
    border: 2px solid #d93932;
    color: #d93932
}

.avgl .cr_event_type.fortnite {
    color: #9e2ce0;
    border-color: #9e2ce0
}

.avgl .cr_event_type.eft {
    border: 2px solid #ddd;
    color: #ddd
}

.avgl .cr_event_type.rl {
    color: #3d97cd;
    border-color: #3d97cd
}

.avgl .qualifier_side:before {
    background-color: var(--qualifier-side-before-bg-color)
}

.avgl .qualifier_game_icon {
    border: 2px solid #d93932
}

.avgl .qualifier_game_icon.bo {
    border-color: #d93932
}

.avgl .qualifier_game_host a {
    color: #d93932
}

.avgl .qualifier_round {
    background-color: var(--qualifier-round-avgl-bg-color)
}

.avgl .qualifier_ranks {
    background-color: var(--qualifier-round-avgl-bg-color)
}

.avgl .qualifier_tabs {
    background-color: var(--qualifier-round-avgl-bg-color)
}

.avgl .qualifier_winners {
    background-image: linear-gradient(180deg, var(--bg-color-primary) 0, var(--bg-color-primary) 100%), linear-gradient(180deg, var(--bg-color-primary) 0, rgba(23, 28, 41, 0) 100%)
}

.avgl .qual_group {
    border-bottom: 1px solid #1e232f
}

.avgl .cr_event_winner_rank {
    color: #1e232f
}

.avgl .qual_player_icon {
    border: 2px solid #1e232f;
    background-color: var(--bg-color-primary)
}

.avgl .qual_player.me .qual_player_icon {
    border-color: #d93932
}

.avgl .rank_info_points {
    color: #d93932
}

/*.avgl .rank_group_points {*/
/*    color: #d93932*/
/*}*/

.avgl .team_box {
    border: 1px solid #343744;
    background-color: #1e232f
}

.avgl .team_box_bar {
    background-color: #343744
}

.avgl .team_box_stats_points {
    color: #d93932
}

.avgl .team_box_dropdown {
    background-color: #1e232f
}

.avgl .team_box_dropdown:before {
    background-color: #1e232f
}

.avgl .team_box_dropdown_item:last-child {
    color: #d93932
}

.avgl .team_box_dropdown_line:nth-child(2n+3) {
    background-color: #1e232f
}

.avgl .qualifier_status_bar:before {
    background-color: var(--bg-color-primary)
}

.avgl .rank-fort .cr_side_menu_btn {
    color: #d93932
}

.avgl .rank_round {
    background-color: #1e232f
}

.avgl .rank_round_status {
    background-color: #1e232f;
    color: rgba(241, 141, 47, .8)
}

.avgl .standings {
    background-color: var(--bg-color-primary)
}

.avgl .standings_close {
    color: #d93932
}

.avgl .standing_holder .rank_round .rank_round_text {
    color: #d93932
}

.avgl .standings_section.three {
    border-right: 1px solid #1e232f
}

.avgl .standings_item {
    border: 1px solid #26272e;
    background-color: #1e232f
}

.avgl .cr_matchup.danger .cr_matchup_userkills {
    color: rgba(217, 57, 50, .5)!important
}

.avgl .qualifier_side .team-duo .bracket_line:nth-child(3) .rank_match:before {
    background-image: linear-gradient(to right, rgba(30, 35, 47, 0) 0, #1e232f 100%)
}

.avgl .qualifier_side .team-trio .bracket_line:nth-child(3) .rank_match:before {
    background-image: linear-gradient(to right, rgba(30, 35, 47, 0) 0, #1e232f 100%)
}

.avgl .qualifier_side .team-quad .bracket_line:nth-child(3) .rank_match:before {
    background-image: linear-gradient(to right, rgba(30, 35, 47, 0) 0, #1e232f 100%)
}

.avgl .standings_category .standings_section.three {
    border-right-color: rgba(30, 35, 47, 0)
}

.avgl .rank_round_status {
    background-color: #1e232f;
    color: rgba(217, 57, 50, .8)
}

.avgl .btn_revisit_instructions,
.avgl .btn_self_report,
.avgl .btn_view_tracking_console {
    background-color: #d93932
}

.avgl .q_registered_bottom a {
    color: #d93932
}

.avgl .cr_matchup_points {
    color: #d93932
}

@media all and (max-width:1310px) {
    .avgl .qualifier_side {
        background-color: rgba(30, 35, 47, .8)
    }
    .avgl .qualifier_side:before {
        background-color: rgba(23, 28, 41, 0)
    }
    .avgl .qualifier_side_container {
        background-color: var(--bg-color-primary)
    }
    .avgl .qualifier_side .bracket {
        background-color: var(--bg-color-primary)
    }
}

.avgl .store_card_stats {
    background-color: #1e232f
}

.avgl .store_card_btn {
    background-color: #d93932
}

.avgl .modal_store_container {
    background-color: var(--bg-color-primary);
    border: 1px solid #1e232f
}

.avgl .modal_store_btn {
    background-color: #d93932
}

.avgl .shipment_input.wrong input {
    color: #d93932
}

.avgl .featured_slide {
    background-color: #1e232f
}

.avgl .featured_btns .store_card_btn.secondary {
    background-color: #1e232f
}

.avgl .modal_spin_container {
    background-color: var(--bg-color-primary);
    border: 1px solid #1e232f
}

.avgl .spin_point {
    background-color: #d93932
}

.avgl .spin_point:before {
    background-color: #d93932
}

.avgl .spin_point:after {
    background-color: #d93932
}

.avgl .modal_spin_btn {
    background-color: #d93932
}

.avgl .modal_store {
    background-color: rgba(23, 28, 41, .75)
}

.avgl .header_third {
    color: #d93932
}

.avgl .body_third {
    color: #d93932
}

.avgl .small_third {
    color: #d93932
}

.avgl .streamer_page footer {
    background-color: rgba(23, 28, 41, .3)
}

.avgl .streamer_header_bg {
    background-color: var(--bg-color-primary)
}

.avgl .streamer_header.embeded {
    background-color: var(--bg-color-primary)
}

.avgl .live_streamers {
    background-color: #1e232f
}

.avgl .streamer_btn {
    background-color: #d93932
}

.avgl .streamer_btn.secondary {
    background-color: #5f7194
}

.avgl .top_sponsor {
    background-color: #1e232f
}

.avgl .top_sponsor_bucs {
    border-right: 1px solid #5f7194
}

.avgl .sponsor_btn {
    background-color: #5f7194
}

.avgl .sponsor_input {
    color: #1e232f
}

.avgl .leaderboard_filter_main {
    background-color: #1e232f;
    color: #5f7194
}

.avgl .leaderboard_filter_item {
    color: #5f7194
}

.avgl .leaderboard_list::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 100px #1e232f;
    box-shadow: inset 0 0 100px #1e232f
}

.avgl .leaderboard_item {
    border-bottom: 1px solid #1e232f
}

.avgl .leaderboard_item.selected {
    background-color: #5f7194
}

.avgl .wager_header {
    background-color: var(--bg-color-primary)
}

.avgl .wager_event {
    background-color: #1e232f
}

.avgl .streamer3_page {
    background-color: var(--bg-color-primary)
}

.avgl .streamer3_page nav {
    background-color: #1e232f!important
}

.avgl .streamer3_page footer {
    background-color: #1e232f
}

.avgl .stream_section_header {
    background-color: #1e232f
}

.avgl .stream_section_content .header_main span {
    color: #d93932
}

.avgl .stream_section_content .body_main span {
    color: #d93932
}

.avgl .streamer_user {
    border-bottom: 1px solid #1e232f
}

.avgl .stream_featured_content .small_third:hover {
    color: rgba(217, 57, 50, .7)
}

.avgl.tournament_page nav {
    background-color: #1e232f
}

.avgl .tp_header_bg:after {
    background: linear-gradient(0deg, var(--bg-color-primary), rgba(23, 28, 41, 0))
}

.avgl .tp_host {
    color: #d93932
}

.avgl .round_next {
    background-color: #d93932
}

.avgl .tp_score_items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 100px #1e232f
}

.avgl .prediction_selector.on {
    background-color: #d93932
}

.avgl .tp_agree_fill {
    background-color: #d93932
}

.avgl .tp_raffle_sidebox {
    border-bottom: 1px solid var(--bg-color-primary)
}

.avgl .tp_raffle_sidetitle .title_main b {
    color: #d93932
}

.avgl .tp_tab {
    background-color: rgba(23, 28, 41, .75)
}

.avgl .tp_tickets {
    background-color: rgba(23, 28, 41, .75)
}

.avgl .tp_leader {
    background-color: rgba(23, 28, 41, .2)
}

.avgl .tp_tip {
    background-color: rgba(23, 28, 41, .2)
}

.avgl .tp_score_categories {
    background-color: rgba(217, 57, 50, .75)
}

.avgl.tournament_page .tp_rules ul li a {
    color: #d93932
}

.avgl .raffle_link {
    color: #d93932
}

.avgl .team_box_kill_of_team {
    color: #d93932
}

.avgl .q_friend_btn .orange {
    color: #d93932
}

.avgl .modal_big .q_btns .radio_btn {
    background-color: #d93932
}

.avgl .q_registered_content .q_member_action a {
    color: #d93932
}

.avgl .dispute_bracket,
.avgl .dispute_killrace {
    color: #d93932
}

.avgl .report_issue_text {
    color: #d93932
}

.avgl .match_item_statvalue.faction {
    color: #d93932
}

.avgl .faction_war_container {
    background-color: #1e232f
}

.avgl .faction_tabmenu {
    background-color: var(--bg-color-primary)
}

.avgl .faction_war_feed_header {
    background-color: var(--bg-color-primary)
}

.avgl .faction_war_feed_users {
    background-color: var(--bg-color-primary)
}

.avgl .faction_war_feed_refer {
    background-color: var(--bg-color-primary)
}

.avgl .faction_war_feed_referlink {
    background-color: #1e232f
}

.avgl .faction_round_boss {
    background-color: var(--bg-color-primary)
}

.avgl .faction_round_winning {
    background-color: var(--bg-color-primary)
}

.avgl .faction_round_winner {
    background-color: var(--bg-color-primary)
}

.avgl .faction_round_stats {
    background-color: var(--bg-color-primary)
}

.avgl .faction_round_stat_value {
    background-color: #1e232f
}

.avgl .faction_start {
    background-color: var(--bg-color-primary)
}

.avgl .faction_start_input {
    border: 2px solid #1e232f
}

.avgl .faction_loading {
    background-color: #171c29
}

.avgl .faction_error {
    background-color: #171c29
}

.avgl .faction_gathered {
    background-color: #171c29
}

.avgl .faction_gathered_item_value {
    background-color: #1e232f
}

.avgl .faction_winners_cats {
    background-color: #171c29
}

.avgl .faction_winners_list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 100px #171c29
}

.avgl .faction_winners_list::-webkit-scrollbar-thumb {
    background-color: #1e232f
}

.avgl .faction_winner {
    background-color: #171c29
}

.avgl .faction_boss_zone {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .faction_tabs {
    background-color: rgba(30, 35, 47, .75)
}

.avgl .faction_round_boss_bar {
    background-color: rgba(30, 35, 47, .65)
}

.avgl .faction_modal {
    background-color: rgba(30, 35, 47, .5)
}

.avgl .faction_boss:after {
    border: 2px solid #d93932
}

.avgl .faction_boss_zone.target {
    border-color: #d93932
}

.avgl .event-card .card_properties .event-name,
.hub-card .card_properties .hub-name {
    color: #d93932
}

.avgl #event-popup .visit_event_page {
    color: #d93932
}

.avgl #event-popup .event_type {
    border: 2px solid #d93932;
    color: #d93932
}

.avgl #event-popup .event_type.fortnite {
    color: #9e2ce0;
    border-color: #9e2ce0
}

/* drops */
.avgl .drops_header_circle_line_inner:before {
    border: 8px solid #FF3932
}

.avgl .drops_header_circle_reward {
    color: #FF3932
}

.avgl .drops_works_desc a {
    color: #FF3932;
}

.avgl .drops_works_card_fill {
    background-color: #FF3932;
}

.avgl .drops_works_crown_title span {
    color: #FF3932;
}

.avgl .drops_ranks_header_link {
    color: #D93932;
}

.avgl .drops_header_box_smash_cake {
    background-color: #FF3932;
}

.avgl .drops_ranks {
    background: var(--drops-rank-avgl-bg)
}

.avgl .btn_go_to_drop {
    background-color: #D93932;
}
