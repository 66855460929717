// NOTE Difference between $var *scss variables and --var *css3 variables:
// css3 variables can be modified realtime and be accessible in all the app without imports (and accessible in javascript)
// scss variables cant be modified in realtime (they compile to normal css values) and need to be imported to be used (not accesible in js)

:root {
  --color-primary: #D93932;
  --color-primary-linear: #D93932;
  // NOTE This is the primary color in rgb, because we use it to have opacity in somme backgrounds
  // Example `background-color: rgba(var(--color-primary-rgb), .5);`
  --color-primary-rgb: 217, 57, 50, .24;

  --color-secondary: #6A7CB0;

  --color-dark: #111521;
  --color-dark-light: #171C29;
  --color-dark-alt: #1E232F;
  --color-dark-queue-icon: #1E232F;

  --color-border: #343744;

  --color-text-light: white;
  --color-text-light-alt: #A4A5A9;
  --color-text-item-stat: #A4A5A9;

  --color-text-dark-alt: #898B9C;

  --color-positive: #5CA868;
  --color-negative: #D34445;
  --color-circle-stroke: #2C303C;
  --margin-right-player_featured_statistics: 0;
  --color-dropdown: unset;
  --color-dropdown-hover: #343744;
  --header-modal-game-account-bg: #2E303C;
  --content-modal-game-account-bg: #14151F;
  --color-description-modal-game-account: #B8B8B8;
  --dropdown-modal-game-account-bg: #222431;
}

:root {
  --global-animation-long: 600ms ease-in-out;
  --global-animation-middle: 370ms ease-in-out;
  --global-animation-short: 200ms ease-in-out;
  --global-scrollbar-size: 3px;

  --global-tooltip-z: 50;
  --global-dropdown-z: 100;
}

[data-theme="light"] {
  --color-primary: #EE733A;
  --color-primary-linear: #FF7A00;
//   // NOTE This is the primary color in rgb, because we use it to have opacity in somme backgrounds
//   // Example `background-color: rgba(var(--color-primary-rgb), .5);`
  --color-primary-rgb: 250, 148, 51;

  --color-secondary: #A0AAC7;

  --color-dark: #FFFFFF;
  --color-dark-light: #E6E9F2;
  --color-dark-alt: #FFFFFF;
  --color-dark-queue-icon: #201E39;

  --color-border: #A0AAC7;

  --color-text-light: #201E39;
  --color-text-light-alt: #34324C;
  --color-text-item-stat: #A4A5A9;
//   --color-text-dark-alt: #898B9C;

//   --color-positive: #5CA868;
//   --color-negative: #D34445;
  --color-circle-stroke: #E3E6EE;
  --margin-right-player_featured_statistics: 0 25px;
  --color-dropdown: #ffffff;
  --color-dropdown-hover: #EDF0F6;
  --header-modal-game-account-bg: #E6E9F2;
  --content-modal-game-account-bg: #FFFFFF;
  --color-description-modal-game-account: #34324C;
  --dropdown-modal-game-account-bg: #F4F7FF;
}