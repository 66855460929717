.admin_creator_page {
  padding: 60px 0 25px;

  .admin_creator {
    display: flex;
  }
}


.admin_creator_mobile_nav {
  background: var(--bg-color-primary);

  .admin_creator_nav_hamburger {
    display: block;
    margin: 0 20px;
    padding: 10px 0;
    cursor: pointer;

    .admin_creator_menu_bars {
      width: 25px;
      height: 25px;

      .admin_creator_menu_bar {
        width: 25px;
        height: 4px;
        background-color: #EE733A;
        border-radius: 2px;
        margin: 4px 0;
      }
    }
  }
}

body {
  background-color: var(--bg-color)!important
}

.mg_top_10 {
  margin-top: 10px!important
}

.abtn_main.small {
  min-width: 130px;
  height: 30px;
  font-size: 12px;
  line-height: 16px;
}

.abtn_main.mid {
  min-width: 150px
}

.abtn_main.disabled {
  pointer-events: none;
  background-color: var(--admin-disabled-bg-color) !important;
}

.abtn_main {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 45px;
  padding: 0 20px;
  border-radius: 2px;
  background-color: #ee733a;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border: none!important;
  transition: background-color .3s ease-in-out, color .3s ease-in-out
}

.abtn_main.decline {
  position: fixed;
  top: 180px;
  right: 40px;
  z-index: 1;
  background-color: #286090 !important;
}

.abtn_main.danger {
  background-color: #d93932
}

.abtn_main:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease-in-out
}

.abtn_main:hover:before {
  background-color: rgba(0, 0, 0, .2)
}

.abtn_main img {
  position: relative;
  width: initial;
  margin-right: 8px
}

.abtn_main span {
  position: relative
}

.abtn_secondary.small {
  min-width: 130px;
  height: 30px;
  font-size: 12px;
  line-height: 16px;
}

.abtn_secondary.mid {
  min-width: 150px
}

.abtn_secondary {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 45px;
  padding: 0 20px;
  border-radius: 2px;
  background-color: #586890;
  color: #fff;
  font-size: 14px;
  font-weight: 700
}

.abtn_secondary:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease-in-out
}

.abtn_secondary:hover:before {
  background-color: rgba(0, 0, 0, .2)
}

.abtn_secondary img {
  position: relative;
  width: initial;
  margin-right: 8px
}

.abtn_secondary span {
  position: relative
}

.show-aapp .admin_main {
  opacity: 1;
  pointer-events: all
}

.admin_main {
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 77px;
  left: 220px;
  width: calc(100vw - 240px);
  height: calc(100vh - 77px);
  padding: 30px 50px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
  overflow-y: scroll
}

.admin_main::-webkit-scrollbar {
  display: none
}

.tournament_url_holder {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  width: -moz-fit-content;
}

.tournament_url_holder_tweet {
  justify-content: center
}

.tournament_url_holder_tweet img {
  width: 30px;
  margin-right: 25px
}

.tweet_section {
  font-size: 13px
}

#modal_boom_msg .admin_normal_text {
  margin-bottom: 15px;
  margin-top: 5px
}

.body_boom_modal_content .tournament_url_holder {
  align-items: center;
  margin: 0 auto
}

.body_boom_modal_content .admin_normal_text {
  margin-top: 20px
}

.body_boom_modal_content ul {
  text-align: left;
  list-style-type: disc;
  margin-left: 40px
}

.tournament_url_input {
  width: fit-content;
  width: -moz-fit-content
}

.tournament_url_input input {
  width: 100%
}

.tournament_url_input a {
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap
}

.tournament_url_btn {
  width: 100px
}

.tournament_url_btn .admin_item_btn {
  margin: 0;
  height: 27px
}

.admin_item_btn.yellow {
  background-color: #d4a72b
}

.admin_item_btn.orange.update_game_name_btn {
  margin-top: 10px;
  width: 80px
}

.admin_item_btn.orange.go_to_save_roster_btn {
  margin-top: 10px;
  width: 100px
}

.admin_item_btn.make_bulletin_announcement {
  width: 200px;
  max-width: 200px; /* to override max-width from .admin_item_btn*/
}

.update_game_name_btn.disabled {
  background: #1e232f;
  color: #fff
}

.admin_table .admin_table_col.width_10 {
  width: 10%
}

.admin_table .admin_table_col.width_15 {
  width: 15%
}

.admin_table .admin_table_col.width_20 {
  width: 20%
}

.admin_table .admin_table_col.width_30 {
  width: 30%
}

.admin_table .admin_table_col.width_35 {
  width: 35%
}

.admin_table .admin_table_col.width_40 {
  width: 40%
}

.admin_table .admin_table_col.width_50 {
  width: 50%
}

.admin_table .admin_table_col.width_60 {
  width: 60%
}

.admin_table .admin_table_col.width_70 {
  width: 70%
}

.admin_table .admin_table_col.width_80 {
  width: 80%
}

.admin_table .admin_table_col.width_90 {
  width: 80%
}

.admin_matchup_user .admin_mod_value {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(155, 159, 206, .1);
  padding: 8px 15px;
  margin-left: 0;
  cursor: pointer
}

.admin_matchup_user .admin_mod_value .admin_item_username {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden
}

.admin_matchup_user .admin_mod_value .admin_arrow {
  width: 8px;
  margin-top: 3px;
  margin-left: 8px;
  display: flex;
  align-items: center
}

.admin_matchup_user .admin_mod_value .admin_arrow.rotate {
  transition: transform .3s;
  transform: rotate(180deg)
}

.admin_matchup_user .admin_mod_value .admin_arrow img {
  width: 8px
}

.admin_matchup_user .admin_drop_content {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translate(0, 100%);
  background: var(--admin-event-bg-color);
  z-index: 3
}

.admin_matchup_user .admin_drop_content.show {
  display: block
}

.admin_matchup_user .admin_drop_item {
  padding: 8px 15px;
  width: 100%;
  display: flex;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
}

.admin_matchup_user .admin_drop_item:hover {
  background-color: var(--admin-dropdown-item-hover)
}

.admin_btn {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  transition: background-color .3s ease-in-out, color .3s ease-in-out
}

.admin_btn.disabled {
  pointer-events: none;
  background-color: grey
}

.save_mod {
  color: #ff6b22;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer
}

.save_mod.disabled {
  pointer-events: none;
  color: #898b9c
}

.admin_matchups .admin_subtitle {
  display: none;
  line-height: 75px;
  margin: 0 40px 0 20px
}

.mini_tab_buttons {
  display: none
}

.admin_matchup.animate {
  animation-name: animationBackground;
  animation-duration: 2s
}

.admin_matchup.collapse .admin_label,
.admin_matchup.collapse .admin_matchup_line,
.admin_matchup.collapse .admin_matchup_title,
.admin_matchup.collapse .admin_matchup_user,
.admin_matchup.collapse .admin_matchup_vs,
.admin_matchup.collapse .btn_save_mod {
  display: none
}

.admin_matchup.collapse .admin_matchups {
  background-color: #1e232f;
  position: relative
}

.admin_matchup.collapse .admin_matchup_item {
  width: 18%;
  min-width: 200px;
  max-width: 300px
}

.admin_matchup.collapse .admin_matchups .admin_subtitle {
  display: block
}

.admin_matchup.collapse .mini_tab_buttons {
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 0
}

.admin_matchup.collapse .disqualify_bracket {
  display: none
}

.admin_matchup.collapse .admin_restart.disqualify_bracket,
.admin_matchup.collapse .replace_player {
  display: none
}

.mini_tab_buttons .abtn_secondary:hover:before {
  background-color: #1e232f
}

.abtn_secondary.collapse_match {
  display: none
}

.admin_main_container {
  display: none;
  width: 100%
}

.admin_title {
  line-height: 34px;
  color: var(--text-color);
  font-size: 24px;
  font-weight: 500;
  margin: 0
}

.admin_subtitle {
  line-height: 25px;
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
  margin: 0
}

.admin_body {
  color: var(--text-color);
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  margin: 0
}

.admin_small {
  line-height: 18px;
  color: var(--text-color);
  font-size: 12px;
  margin: 0
}

.admin_label {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0
}

.admin_small.or {
  font-weight: 700
}

.op_50 {
  opacity: .5
}

.op_75 {
  opacity: .75
}

.ita {
  font-style: italic
}

a.or {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  transition: color .3s ease-in-out
}

.admin_input {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  height: 45px;
  border-radius: 2px;
  border: 2px solid #353748;
  padding: 0 18px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  transition: border-color .3s ease-in-out
}

.admin_input.small {
  height: 36px
}

.admin_input.mgy {
  margin: 3px 0
}

.admin_input::placeholder {
  color: var(--admin-placeholer);
  transition: color .3s ease-in-out
}

.admin_input:hover::placeholder {
  color: var(--admin-input-hover-color)
}

.admin_input:hover {
  border-color: var(--admin-input-hover-color)
}

.admin_input:focus::placeholder {
  color: var(--admin-input-hover-color)
}

.admin_input:focus {
  border-color: var(--admin-input-hover-color)
}

.admin_mod .admin_input~.abtn_main {
  width: calc(10% - 10px);
  min-width: 60px;
  transition: opacity .3s ease-in-out
}

.admin_mod {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-bottom: 15px
}

.admin_mod .admin_dropdown {
  margin-right: 10px;
  width: calc(20% - 10px);
  min-width: 200px;
}

.admin_mod .admin_input {
  width: calc(70% - 10px);
  max-width: unset;
  margin-right: 10px
}

.admin_input.center {
  margin: auto
}

.admin_input.no_border {
  border: none!important;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden
}

.admin_mod>.admin_uicon {
  margin-right: 12px
}

.admin_mod>.admin_body {
  margin-right: 20px
}

.admin_mod>.admin_small {
  margin-right: 12px
}

.admin_mod>.admin_check_label {
  margin-left: 8px;
  align-items: center
}

.admin_mod>.admin_check_label .admin_body {
  margin-bottom: 0
}

.admin_dropdown {
  position: relative;
  width: 100%
}

.admin_dropdown_main {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-radius: 2px;
  padding: 0 18px;
  border: 2px solid #353748;
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500
}

.admin_dropdown_main span {
  pointer-events: none;
  position: relative
}

.admin_dropdown_main input {
  width: 100%;
  padding: 0;
  border: none;
  outline: 0;
  color: rgba(255, 255, 255, .75);
  font-size: 13px;
  font-weight: 500
}

.admin_dropdown_main input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main input:-ms-input-placeholder {
  color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main input::placeholder {
  color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main img {
  pointer-events: none;
  position: relative;
  width: initial;
  margin-left: 10px;
  transform: rotate(0) translate(0, 1px);
  transition: transform .3s ease-in-out
}

.admin_dropdown_main img:first-child {
  pointer-events: none;
  position: relative;
  width: initial;
  margin-right: 4px
}

.admin_dropdown_main img:last-child {
  pointer-events: none;
  position: relative;
  width: initial;
  margin-left: 10px;
  transform: rotate(0) translate(0, 1px);
  transition: transform .3s ease-in-out
}

.admin_dropdown.show .admin_dropdown_main img:last-child {
  transform: rotate(180deg) translate(0, 1px)
}

.admin_dropdown.show .admin_dropdown_items {
  transform: rotateX(0)
}

.admin_dropdown_items {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  box-shadow: 0 0 40px rgba(20, 22, 31, .55);
  border-radius: 2px;
  background-color: var(--admin-dropdown-items-bg-color);
  overflow: auto;
  transform-origin: top;
  transform: rotateX(90deg);
  transition: transform .3s ease-in-out;
  z-index: 2
}

.admin_dropdown_main.game_type.changed~.admin_dropdown_items .game_type_add {
  display: block
}

.admin_dropdown_item {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  background-color: var(--admin-dropdown-item-bg-color);
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500;
  transition: background-color .3s ease-in-out
}

.admin_dropdown_item.taller {
  height: unset;
  padding: 5px 20px;
  color: rgba(255, 255, 255, .85)
}

.admin_dropdown_item .subtext {
  font-size: 12px;
  font-style: italic;
  color: #999
}

.admin_dropdown_item.disabled .admin_dropdown_item .subtext {
  color: rgba(255, 255, 255, .2)
}

.admin_dropdown_item.new {
  justify-content: space-between
}

.admin_dropdown_item.new .admin_dropdown_item_delete {
  display: flex
}

.admin_dropdown_item_delete {
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  margin-left: 5px;
  margin-right: -5px;
  padding: 5px
}

.admin_dropdown_main .subtext {
  display: none
}

.admin_dropdown_item.disabled {
  color: rgba(255, 255, 255, .3);
  pointer-events: none
}

.admin_dropdown_item:hover {
  background-color: var(--admin-dropdown-item-hover)
}

.admin_dropdown_item span {
  position: relative
}

.admin_dropdown_item img {
  width: initial
}

.admin_dropdown_item img:first-child {
  margin-right: 4px
}


.admin_search {
  position: relative;
  max-width: 300px
}

.admin_search .admin_input {
  padding-right: 44px;
  min-width: 200px
}

.admin_search_icon {
  position: absolute;
  top: 22px;
  right: 15px;
  width: 16px;
  display: flex;
  align-items: center;
  transform: translate(0, -50%);
  pointer-events: none
}

.admin_main_container.s3>.admin_subtitle {
  margin: 35px 0 20px
}

.admin_cats {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 18px;
  border-radius: 2px;
  border: 2px solid #1e232f
}

.admin_cat {
  color: #4c4e5a;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase
}

.admin_cat.one {
  width: 7%
}

.admin_cat.two {
  width: 25%
}

.admin_cat.two.big {
  width: 64%
}

.admin_cat.three {
  width: 10%
}

.admin_cat.four {
  width: 10%
}

.admin_cat.five {
  width: 20%
}

.admin_cat.six {
  width: 28%
}

.admin_item {
  display: flex;
  align-items: stretch;
  width: 100%;
  padding: 12px 20px;
  border-bottom: 2px solid #1e232f;
  background-color: rgba(41, 43, 58, 0);
  transition: background-color .3s ease-in-out
}

.admin_item:hover {
  background-color: rgba(41, 43, 58, .5)
}

.admin_item:last-child {
  border-bottom: none
}

.admin_item_sec {
  display: flex;
  align-items: center
}

.admin_item_sec.one {
  width: 7%;
  align-items: center
}

.admin_item_sec.two {
  width: 25%
}

.admin_item_sec.two.big {
  width: 64%
}

.admin_item_sec.three {
  width: 10%
}

.admin_item_sec.four {
  width: 10%
}

.admin_item_sec.five {
  width: 20%;
  color: #fff;
  font-size: 12px;
  flex-wrap: wrap
}

.admin_item_sec.six {
  width: 28%
}

.admin_item_sec .admin_small {
  font-weight: 600
}

.admin_item_sec .admin_item_user {
  margin-bottom: 6px
}

.admin_item_sec .admin_item_user:last-child {
  margin-bottom: 0
}

.admin_item_user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.admin_item_user .break {
  flex-basis: 100%;
  width: 0;
}

.admin_item_usericon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-right: 11px;
  overflow: hidden
}

.admin_item_username, .admin_item_email {
  position: relative;
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500;
  transition: color .3s ease-in-out
}

.admin_item_content {
  width: 100%
}

.admin_item_content .admin_small {
  margin-bottom: 10px
}

.admin_item_content .admin_small:last-child {
  margin-bottom: 0
}

.admin_item_btns {
  margin: 0 -5px;
  width: calc(100% + 10px);
  display: flex;
  align-items: center
}

.admin_item_btn.disabled {
  pointer-events: none;
  color: rgba(255, 255, 255, .25);
  background-color: grey!important
}

.admin_matchup_container.disqualified .admin_item_btn {
  pointer-events: none;
  color: rgba(255, 255, 255, .25)
}

.admin_matchup_container.disqualified .kill_member_input {
  pointer-events: none
}

.admin_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  width: 100%;
  max-width: 190px;
  height: 24px;
  padding: 0 10px;
  margin: 0 5px;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color .3s ease-in-out;
  white-space: nowrap
}

.admin_item_btn span {
  position: relative
}

.admin_item_btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .3s ease-in-out
}

.admin_item_btn:hover:before {
  background-color: rgba(0, 0, 0, .2)
}

.admin_item_btn.secondary {
  background-color: #ee733a
}

.admin_item_sec.six .admin_item_btn {
  max-width: 130px;
  margin: 5px
}

.admin_item_sec.six .admin_item_btns {
  flex-wrap: wrap
}

.admin_items_expand {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  width: 100%;
  height: 35px;
  border-radius: 2px;
  background-color: var(--bg-color-primary);
  color: var(--text-color);
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase
}

.admin_items_expand:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color .3s ease-in-out
}

.admin_items_expand:hover:before {
  background-color: rgba(255, 255, 255, .02)
}

.admin_items_expand span {
  position: relative
}

.admin_items_expand img {
  width: 8px;
  margin-left: 6px
}

.admin_userinput {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px
}

.admin_userinput:last-child {
  margin-bottom: 0
}

.admin_userinput .admin_input {
  max-width: 280px;
  margin-right: 5px
}

.admin_section {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.admin_section .admin_section_right {
  display: flex;
  justify-content: flex-end
}

.admin_section .admin_section_right .refresh_element {
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.admin_section .admin_section_right .refresh_element .refresh {
  margin-left: 5px
}

.admin_main_container.s4>.admin_section {
  margin: 30px 0 55px
}

.admin_btns {
  display: flex;
  align-items: center;
  margin-right: -6px
}

.admin_btns .abtn_secondary {
  margin: 0 6px
}

.admin_matchup {
  width: 100%;
  margin-bottom: 30px
}

.admin_matchup:last-child {
  margin-bottom: 0
}

.admin_matchup_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 45px;
  margin-bottom: 15px
}

.admin_matchup_section {
  display: flex;
  align-items: center
}

.admin_matchup_section .admin_subtitle {
  margin-right: 20px
}

.admin_matchup_section .admin_matchup_user {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative
}

.admin_mod_tag {
  margin: 0;
  margin-left: 5px;
  color: #db3345;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 2px
}

.admin_restart {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  flex-shrink: 0;
  margin-right: 6px;
  display: flex;
  align-items: center;
  color: #b72f41;
  font-size: 13px;
  font-weight: 600;
  transition: color .3s ease-in-out
}

.admin_restart:hover {
  color: rgba(183, 47, 65, .7)
}

.admin_restart img {
  width: 13px;
  margin-right: 7px
}

.admin_restart.disqualify_bracket {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%)
}

.admin_matchup_section .abtn_main {
  flex-shrink: 0;
  margin: 0 6px
}

.admin_matchup_section .abtn_secondary {
  flex-shrink: 0;
  margin: 0 6px
}

.admin_matchup_section:last-child {
  margin-right: -6px
}

.admin_matchup .admin_matchup_section .abtn_main.update {
  background-color: #1e232f;
  pointer-events: unset
}

.admin_matchup.done .admin_matchup_section .abtn_main.done {
  display: inline-flex
}

.admin_matchup.done .admin_matchup_section .abtn_main.update,
.admin_matchup.finished .admin_matchup_section .abtn_main.update {
  pointer-events: unset
}

.admin_matchup.finished .admin_matchup_section .abtn_secondary.collapse_match {
  display: flex
}

.bracket_top_score .admin_matchup.done .admin_matchup_section .abtn_main:nth-child(2) {
  display: none
}

.bracket_top_score .admin_matchup.done .admin_matchup_section .abtn_main:nth-child(3) {
  display: none
}

.bracket_top_score .admin_matchup.done .admin_matchup_section .abtn_main:nth-child(4) {
  display: inline-flex
}

.bracket_top_score .admin_matchup.restart .admin_matchup_section .abtn_main:nth-child(2) {
  display: none
}

.bracket_top_score .admin_matchup.restart .admin_matchup_section .abtn_main:nth-child(3) {
  display: none
}

.bracket_top_score .admin_matchup.finished .admin_matchup_section .abtn_main:nth-child(2) {
  display: none
}

.bracket_top_score .admin_matchup.finished .admin_matchup_section .abtn_main:nth-child(3) {
  display: none
}

.admin_matchups {
  width: 100%;
  display: flex;
  align-items: flex-start
}

.admin_matchup_item {
  width: 100%;
  display: flex
}

.admin_matchup_item.disqualified {
  opacity: .5
}

.admin_matchup_item .admin_label {
  margin-bottom: 5px
}

.admin_matchup_container {
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  background-color: var(--bg-color-secondary);
  position: relative
}

.admin_matchup_container.disqualified div:not(.admin_matchups_user):not(.replace_player) {
  opacity: .5
}

.admin_matchup_container.disqualified .admin_matchups_usericon,
.admin_matchup_container.disqualified .admin_matchups_username,
.admin_matchup_container.disqualified .admin_matchups_userwin {
  opacity: .5
}

.admin_matchups_user {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative
}

.admin_matchups_usericon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 2px;
  overflow: hidden
}

.admin_matchups_username {
  position: relative;
  cursor: text;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 500;
  transition: color .3s ease-in-out
}

.admin_matchups_user.win .admin_matchups_userwin {
  display: flex
}

.admin_matchups_userwin {
  display: none;
  align-items: center;
  height: 22px;
  padding: 0 10px;
  margin-left: 15px;
  border-radius: 13px;
  background-color: #1e232f;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase
}

.admin_matchup_vs {
  flex-shrink: 0;
  margin: 128px 7px 0;
  color: #464b5b;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase
}

.admin_matchup_line {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px
}

.admin_matchup_sec {
  width: 50%;
  padding: 0 5px;
  margin-top: 15px
}

.admin_matchup_sec.remove_margin {
  margin-top: 0
}

.admin_matchup_sec.small {
  width: calc(100%/5*1.5)
}

.admin_matchup_sec.mid {
  width: calc(100%/5*2)
}

.admin_matchup_sec .admin_small {
  margin-bottom: 6px
}

.admin_matchup_sec .admin_drop_content {
  background-color: #1e202d;
  width: 200px;
  position: absolute;
  z-index: 10
}

.admin_matchup_sec .admin_drop_item {
  border-bottom: 1px solid #292c3a;
  padding: 5px 0 0 5px
}

.admin_matchup_sec .admin_drop_item:hover {
  background-color: #38393e;
  cursor: pointer
}

.admin_matchup_sec .admin_drop_item:last-child {
  border-bottom: none
}

.admin_matchup_value {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 13px;
  border-radius: 2px;
  border: 1px solid #353748;
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500
}

.admin_matchup_value.big {
  width: 100%
}

textarea.admin_matchup_value {
  background-color: transparent;
  line-height: 26px;
  height: 60px
}

.admin_matchup_sec .admin_item_btn {
  max-width: initial;
  margin: 0
}

.admin_matchup_item.reset .admin_matchup_reset {
  display: flex
}

.admin_matchup_reset {
  display: none;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  margin-top: 15px;
  border-radius: 2px;
  background-color: #b62f40
}

.admin_matchup_reset .admin_label {
  width: 100%;
  padding-right: 11px;
  margin: 0
}

.admin_reset_btns {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: -4px
}

.admin_reset_btn {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 15px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: rgba(140, 24, 38, .9);
  color: rgba(255, 255, 255, .9);
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color .3s ease-in-out, background-color .3s ease-in-out
}

.admin_reset_btn:hover {
  background-color: rgba(248, 248, 255, .9);
  color: rgba(35, 37, 51, .9)
}

.admin_matchup.finished .admin_matchup_item.advance .admin_matchup_advance {
  display: inline-flex
}

.admin_matchup.finished .admin_matchup_items.advance .admin_matchup_advance.team {
  display: inline-flex
}

.admin_matchup.finished .admin_matchup_item.advance .admin_matchup_container {
  border: 2px solid #db3345;
  padding: 18px
}

.admin_matchup_team_container {
  display: flex;
  flex-wrap: wrap
}

.admin_matchup .admin_matchup_items .admin_matchup_team_container {
  margin-top: 10px
}

.admin_matchup.finished .admin_matchup_items.advance .admin_matchup_team_container {
  border: 2px solid #db3345;
  background-color: #1e232f
}

.admin_matchup_advance {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 24px;
  margin-top: 20px;
  border-radius: 2px;
  background-color: #c83143;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: .9;
  cursor: pointer
}

.admin_main_container.s6>.admin_title {
  text-align: center;
  margin-bottom: 40px
}

.admin_teams {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px
}

.admin_teams:last-child {
  margin-bottom: 0
}

.admin_team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-width: 310px;
  border-radius: 2px;
  border: 2px solid rgba(40, 42, 56, .5);
  background-color: rgba(41, 43, 58, .5);
  position: relative
}

.admin_team_user {
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px
}

.admin_team_user:last-child {
  margin-bottom: 0
}

.admin_team_usericon {
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 2px;
  margin-right: 17px
}

.admin_team_username {
  pointer-events: all;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  transition: color .3s ease-in-out;
  cursor: text
}

.admin_team_username:hover {
  color: rgba(255, 255, 255, .75)
}

.admin_team_winners {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 12px;
  margin-left: 20px;
  background-color: #84b06d;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 22px;
}

.admin_team.small {
  min-width: 220px;
  margin: 0 40px
}

.admin_team.small .admin_team_user:first-child {
  margin-bottom: 12px
}

.admin_team.small .admin_team_usericon {
  width: 30px;
  height: 30px;
  margin-right: 15px
}

.admin_team.small .admin_team_winners {
  display: none
}

.admin_team.not_win .admin_team_winners {
  display: none
}

.admin_team.compact {
  min-width: initial;
  margin: 0 35px 40px 35px
}

.admin_team.compact .admin_team_user:first-child {
  margin-bottom: 12px
}

.solos .admin_team_user {
  margin-bottom: 0!important
}

.solos .admin_team_user:nth-child(n+2) {
  display: none
}

.admin_team.compact .admin_team_usericon {
  width: 30px;
  height: 30px;
  margin: 0
}

.admin_team.compact .admin_team_username,
.admin_team.compact .admin_team_winners {
  display: none
}

.input_calendar {
  border: none;
  padding: 0;
  color: var(--text-color);
}

.admin_step.on .astep_num {
  cursor: pointer
}

.admin_small.disabled {
  pointer-events: none;
  color: rgba(255, 255, 255, .15)
}

.gray {
  color: gray
}

.gray p {
  color: gray
}

.gray .admin_item_username {
  color: gray
}

.refresh {
  top: 5px;
  right: 0;
  width: 22px;
  cursor: pointer
}

.refresh.spin {
  animation: spin 1s linear infinite
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%
}

.admin_label {
  width: 49%
}

.admin_label .form-control {
  width: 100%;
  border: 1px solid #fff;
  padding: 5px 0;
  color: #fff
}

.admin_label.admin_label_team {
  /*width: 10%*/
  padding: 0 20px;
}

table.table-bordered>tbody>tr>td {
  border: 1px solid #1e232f
}

table.table-bordered>thead>tr>th {
  border: 1px solid #1e232f
}

table.table-bordered {
  border: 1px solid #1e232f
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 5px;
  line-height: 1.42857143
}

table.table-bordered>tbody>tr:first-child>td {
  border-top: none!important
}

.form_input_match {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.add_match_label {
  color: var(--text-color);
  width: 300px;
  padding: 7px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
}

.replace_player .replace_player_btn {
  text-transform: inherit;
  position: absolute;
  top: 50%;
  right: 94px;
  transform: translate(0, -50%);
  width: 105px;
  pointer-events: inherit!important;
  color: #fff!important
}

.replace_player .replace_team_btn {
  text-transform: inherit;
  top: 40%;
  transform: translate(0, -50%);
  width: 105px;
  pointer-events: inherit!important;
  color: #fff!important
}

.title_replace_team {
  color: #fff;
  margin-left: 20px
}

.match_popup_holder {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 100;
  background-color: var(--bg-color-secondary);
  transition: opacity .3s ease-in-out;
  overflow-y: auto
}

.match_popup_holder.show {
  pointer-events: all;
  opacity: 1
}

.match_popup_parent {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

.match_popup_children {
  width: 100%;
  max-width: 490px;
  padding: 35px;
  border-radius: 2px;
  border: 1px solid #353748;
  background-color: var(--admin-match-popup-children)
}

.choose_team_bracket_popup {
  max-width: 1000px
}

.admin_inspect_close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: initial;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  float: right
}

.admin_team_item {
  width: 100%;
  margin-bottom: 25px
}

.admin_team_item:last-child {
  margin-bottom: 0!important
}

.admin_team_item_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px
}

.admin_team_item_name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px
}

.admin_team_item_action {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
  line-height: 18px;
  color: #9a9ba3;
  font-size: 13px;
  font-weight: 600
}

.admin_team_item .admin_team_item_action span {
  display: none
}

.admin_team_item.expand .admin_team_item_action .collapse {
  display: flex
}

.admin_team_item.collapse .admin_team_item_action .expand {
  display: flex
}

.admin_team_item_action_icon {
  position: relative;
  width: 12px;
  height: 12px;
  margin-left: 10px
}

.admin_team_item_action_icon:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #9a9ba3
}

.admin_team_item_action_icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  width: 2px;
  height: 100%;
  border-radius: 1px;
  background-color: #9a9ba3;
  transform: scale(1);
  transition: transform .3s ease-in-out
}

.admin_team_item.expand .admin_team_item_action_icon:after {
  transform: scale(1, .16)
}

.admin_team_item_users_container {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.admin_team_item_users {
  position: relative;
  width: 100%;
  padding-bottom: 0;
  transition: padding-bottom .3s ease-in-out
}

.admin_team_item_users_vs {
  display: flex;
  align-items: center;
  width: 12px;
  flex-shrink: 0;
  margin: 0 9px
}

.admin_team_item_users_team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  padding: 0 15px;
  border-radius: 2px;
  background-color: rgba(30, 35, 47, .5)
}

.admin_team_item_users_team .admin_input {
  max-width: 200px;
  width: 100%
}

.admin_team_item_users_team .match_btn {
  margin-left: 20px
}

.match_btn {
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 25px;
  height: 36px;
  border-radius: 2px;
  background-color: #ee733a;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  transition: background-color .3s ease-in-out, color .3s ease-in-out
}

.match_btn.secondary {
  background-color: #586890
}

.match_btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease-in-out
}

.match_btn:hover:before {
  background-color: rgba(0, 0, 0, .1)
}

.match_btn span {
  position: relative
}

.match_btn.disabled {
  pointer-events: none;
  background-color: var(--admin-disabled-bg-color) !important;
}

.admin_team_item_user_list {
  pointer-events: none;
  position: absolute;
  top: 71px;
  left: 0;
  width: 100%;
  padding: 15px;
  border-radius: 2px;
  background-color: rgba(30, 35, 47, .5);
  transform-origin: top;
  transform: rotateX(90deg);
  transition: transform .3s ease-in-out
}

.admin_team_item.expand .admin_team_item_user_list {
  pointer-events: all;
  transform: rotateX(0)
}

.admin_team_item_user {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 15px
}

.admin_team_item_user:last-child {
  margin-bottom: 0
}

.admin_team_item_usericon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  border-radius: 2px;
  overflow: hidden
}

.admin_team_item_usericon {
  width: 32px;
  height: 32px;
  margin-right: 13px
}

.admin_team_item_userinfo {
  width: 100%;
  margin-left: 15px
}

.admin_team_item_userinfo .admin_dropdown_main {
  justify-content: flex-start;
  border: none;
  height: 20px;
  margin-bottom: 3px;
  padding: 0 3px;
  font-size: 11px;
  color: #fff;
  line-height: 16px
}

.admin_team_item_userinfo .admin_input {
  max-width: initial
}

.admin_team_item_user_title {
  margin: 0 0 5px;
  line-height: 16px;
  color: #fff;
  font-size: 11px;
  font-weight: 600
}

.admin_team_item.collapse {
  position: relative;
  border-radius: 2px;
  background-color: rgba(30, 35, 47, .5)
}

.admin_team_item.collapse .admin_team_item_nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin: 0;
  z-index: 1;
  pointer-events: none
}

.admin_team_item.collapse .admin_team_item_action,
.admin_team_item.collapse .admin_team_item_name {
  pointer-events: all
}

.admin_team_item.collapse .admin_team_delete {
  display: none
}

.admin_team_item.collapse .admin_team_item_users {
  position: relative;
  padding: 10px 0 10px 150px;
  align-items: center;
  min-height: 68px;
  margin: 0;
  width: 400px
}

.admin_team_item.collapse .admin_team_item_users:last-child {
  padding-left: 0;
  padding-right: 140px
}

.admin_team_item.collapse .admin_team_item_user {
  margin: 0;
  padding: 0;
  background-color: rgba(30, 35, 47, 0);
  width: initial
}

.admin_team_item.collapse .admin_team_item_user .btn_update {
  display: none
}

.admin_team_item.collapse .admin_team_item_user .admin_matchup_line {
  display: none
}

.admin_team_item.collapse .admin_team_item_user .admin_team_item_user_info {
  margin: 5px;
  margin-right: 25px
}

.admin_team_item.collapse .admin_team_item_user .admin_team_item_user_info.no-info .icon_noinfo {
  display: flex
}

.admin_team_item.collapse .admin_team_item_user .admin_team_item_user_info.no-info .admin_team_item_user_name {
  color: rgba(255, 255, 255, .5)
}


.btn_add_team {
  margin-left: 20px
}

.team_popup_children {
  max-width: 580px;
  position: relative
}

.team_popup_children .admin_inspect_close {
  position: absolute;
  top: 15px;
  right: 15px
}

.team_popup_children .admin_inspect_close img {
  width: 15px
}

.admin_matchup_value.twitch_name_add,
.admin_matchup_value.game_name_add {
  margin-right: 5px;
}

.admin_normal_text {
  color: var(--text-color);
  font-size: 13px
}

.admin_main_btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media (max-width:800px) {
  .admin_side .abtn_main,
  .admin_side .abtn_secondary {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 36px
  }

  .admin_main {
    left: 0;
    width: 100vw;
    padding: 30px 10px;
    height: calc(100vh - 133px)
  }

  .admin_mod.admin_mod_input {
    display: block;
    height: unset
  }

  .admin_mod.admin_mod_input input {
    margin: 5px 0
  }

  .admin_settings_line {
    flex-flow: row wrap
  }

  .admin_settings_line .admin_settings_item {
    width: 100%
  }

  .admin_form_team {
    flex-flow: row wrap
  }

  .admin_label {
    width: 100%
  }

  .admin_inspect {
    padding: 0
  }

  .admin_inspect .admin_matchup_title {
    margin-bottom: 0
  }

  .admin_inspect .admin_matchup_container {
    padding: 10px
  }

  .admin_inspect .admin_matchups_user {
    width: fit-content;
    width: -moz-fit-content
  }

  .admin_inspect .admin_matchups_username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
}

@media (max-width:640px) {
  .admin_main_btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    justify-content: center;
    padding-top: 10px;
    background: var(--bg-color)
  }

  .abtn_main {
    min-width: 160px;
    height: 38px
  }

  .body_boom_modal {
    padding: 15px
  }

  .body_boom_modal .body_boom_modal_content {
    width: 100%
  }
}
