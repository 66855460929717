#main_profile{
  min-height: 700px;
  background-color: var(--profile-background-color);
  font-family: 'Open Sans', sans-serif;
}
.user_profile_modals {
    z-index: 100 !important;
}
.profile_page main {
    padding: 50px 20px 0;
}
.video-container {
    width: 100vw;
    height: 0;
    opacity: 0.5;
}
.video-container iframe {
    position: absolute;
    top: 31vw;
    left: 50%;
    width: 100vw;
    height: 106vh;
    transform: translate(-50%, -50%);
}
.profile_page_bg {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 570px;
    object-fit: cover;
    background-size: cover !important;
}
.profile_banner {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 30px auto 0;
}
.profile_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	max-width: 1296px;
    margin: 252px auto 0;
    padding: 12px 90px 30px;
	flex-wrap: wrap;
    background: var(--bg-color-primary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: unset;
    border-radius: 10px 10px 0px 0px;
}
.has_live_stream .profile_header {
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    align-items: flex-start;
}
.profile_header_buttons {
    position: absolute;
    right: 0;
    top: -50px;
    display: flex;
}
.profile_header_btn {
    width: 131px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EE733A;
    border-radius: 2px;
    cursor: pointer;
}
.profile_header_btn:nth-child(2) {
    margin-left: 20px;
}
.profile_header_btn span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 6px;
}
.profile_header_btn svg {
    width: 16px;
    height: 16px;
}
.profile_header_info {
    display: flex;
    align-items: center;
}
.has_live_stream .profile_header_info {
    background: var(--profile-header-stat-bg-color);
    width: 528px;
    padding: 20px 10px;
    border-radius: 10px;
}
.profile_header_icon {
    width: 106px;
    height: 106px;
    margin-right: 15px;
}
.profile_header_icon img {
    width: 106px;
    height: 106px;
    object-fit: cover;
    border: 2px solid #EE733A;
    border-radius: 50%;
}
.profile_header_status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 17px;
    background-color: var(--profile-live-label-color);
    border-radius: 3px;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255,255,255,0.9);
    margin: 0 0 8px;
}
.profile_header_username {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 34px;
    line-height: 46px;
    color: var(--profile-name-text-color);
    max-width: 252px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.profile_header_social_name img {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}
.profile_header_current_event {
    margin: 0;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    color: #A4A5A9;
    max-width: 252px;
}
.profile_header_info.no_social_connected .profile_header_current_event {
    max-width: 500px;
}
.profile_header_current_event a {
    color: #E97B26;
}
.profile_header_social_mobile {
    display: none;
    margin-top: 5px;
}
.profile_header_partner_label {
    color: var(--text-color);
    margin: 0 0 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 20px;
}
.profile_header_partner_label img {
    width: 18px;
    height: 15px;
    margin-right: 5px;
}
.profile_header_date {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--profile-sign-up-color);
}
.profile_header_social_container {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    cursor: pointer;
}
.has_live_stream .profile_header_social_container {
    background: var(--profile-header-stat-bg-color);
    width: 292px;
    justify-content: left;
    padding: 6px 20px;
}
.profile_header_social_logo {
    margin-right: 15px;
    width: 22px;
    display: flex;
}
.profile_header_social_logo .youtube_logo {
    width: 29px;
}
.profile_header_social_name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 136%;
    color: var(--text-color);
}
.profile_header_social_follow {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    color: var(--text-color-secondary);
}
.profile_header_social_follow span {
    color: #E97B26;
    margin-right: 5px;
}
.profile_header_social_followers {
    display: flex;
    align-items: center;
    margin-right: 9px;
}
.profile_header_social_followers img {
    width: 14px;
    margin-right: 3px;
}
.profile_header_social_following {
    display: flex;
    align-items: center;
}
.profile_header_social_following img {
    width: 14px;
    margin-right: 3px;
}
.profile_header_stats {
    display: flex;
    align-items: center;
}
.profile_header_stat {
    position: relative;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 145px;
    padding: 0 13.5px;
    margin-left: 10px;
    background-color: var(--profile-header-stat-bg-color);
    overflow: hidden;
    cursor: pointer;
}
.profile_header_stat:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1.5px solid #343744;
    border-radius: 2px;
    box-sizing: border-box;
}
.profile_header_stat_icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    flex-shrink: 0;
}
.profile_header_stat_icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 132px;
    height: 139px;
    background: var(--profile-bacground-stat-icon);
    background-size: contain;
    background-position: 50% 50%;
    transform: translate(-50%,-50%);
}
.profile_header_stat_icon img {
    position: relative;
    width: initial;
    height: initial;
}
.profile_header_stat_info {
    position: relative;
    margin-top: -20px;
}
.profile_header_stat_num {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 20px;
    line-height: 28px;
    color: var(--profile-number-stat-color);
    text-align: center;
}
.profile_header_stat_tag {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--profile-label-stat-color);
}
.profile_header_stat_more {
    cursor: pointer;
    position: absolute;
    top: -1.5px;
    right: -1.5px;
    display: flex;
    align-items: center;
    width: 24px;
    z-index: 1;
}
.profile_header_stat_more:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 208px;
    background-color: #E97B26;
    transform-origin: top right;
    transform: rotate(45deg) translate(50%,calc(-100% + 18px));
    transition: transform .3s ease-in-out;
}
.profile_header_stat_more.yellow:before{
    background-color: yellow;
}
.body_main.stat_more {
    color: #FFFFFF;
    font-weight: bold;
    pointer-events: none;
    touch-action: none;
    position: absolute;
    width: 132px;
    height: 145px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: 1;
    display: flex;
    align-items: center;
    transform: translateX(22%);
}
.body_main.stat_more.profile_view_drops {
    transform: translateX(20%);
}
.profile_header_stat_more:hover ~ .body_main.stat_more {
    opacity: 1;
}
.profile_header_stat:hover .body_main.stat_more {
    opacity: 1;
}
.profile_header_stat:hover .profile_header_stat_more:before {
    transform: rotate(45deg) translate(50%,0%);
}

.profile_header_stat_more:hover:before {
    transform: rotate(45deg) translate(50%,0%);
}
.profile_header_stat_more img {
    position: absolute;
    top: 5.5px;
    right: 5.5px;
    width: 6px;
    height: auto;
}
.profile_menu_info{
    width: 100%;
    padding: 0 30px;
}

.profile_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 62px;
    max-width: 1296px;
    padding: 0 90px;
    margin: 0 auto;
    background: var(--bg-color-primary);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.has_live_stream .profile_menu {
    height: 90px;
}
.profile_tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 1296px;
    padding: 90px 90px 15px;
    margin: 0 auto;
    background: var(--bg-color-secondary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 10px 10px;
}
.profile_list_header{
    display: flex;
    align-items: center;
    position: relative;
}
.profile_tab_detail {
    max-width: 1200px;
    width: 100%;
}
.profile_menu_section {
    display: flex;
    align-items: center;
}
.profile_menu_section:first-child {
    margin-left: -30px;
}
#connected_social_accounts{
    margin-bottom: -10px;
}
.profile_default_stream{
  width: 100%;
  height: 300px;
  background-color: #1E232F;
}
.profile_menu_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Open Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: var(--profile-menu-text-color);
    transition: color .3s ease-in-out;
    margin: 0 30px;
    padding-bottom: 19px;
    white-space: pre;
}
.has_live_stream .profile_menu_link {
    padding-top: 23px;
    padding-bottom: 26px;
}
.profile_menu_link svg {
    margin-right: 15px;
}
.profile_menu_link_border {
    width: 2px;
    height: 42px;
    margin: 0 60px 20px;
    background-color: #343744;
}
.has_live_stream .profile_menu_link_border {
    height: 65px;
    margin-bottom: 0;
}
.profile_menu_link.active{
    border-bottom: 3px solid #D93932;
    color: var(--profile-menu-active-color);
}
.profile_menu_social {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 10px;
    background-color: var(--profile-background-social-icon-color);
    transition: background-color .3s ease-in-out;
}
.profile_menu_social:hover {
    background-color: #ae2d28;
}
.profile_menu_social img {
    width: 16px;
    height: auto;
}
.profile_menu_bar {
    position: absolute;
    bottom: -1.5px;
    left: 0;
    height: 3px;
    width: 100px;
    background-color: #D93932;
    transform-origin: left;
    transform: translate(0px,0) scale(0.72,1);
    transition: transform .3s ease-in-out;
}
.profile_menu.s1 .profile_menu_bar {
	transform: translate(0px,0) scale(0.72,1);
}
.profile_menu.s2 .profile_menu_bar {
	transform: translate(112px,0) scale(0.72,1);
}
.profile_menu.s3 .profile_menu_bar {
	transform: translate(224px,0) scale(0.97,1);
}
.profile_menu.s4 .profile_menu_bar {
	transform: translate(361px,0) scale(0.63,1);
}

.profile_overview_stat_icon img {
    width: initial;
    height: initial;
}
.land_event_card{
    margin: 0 10px 40px;
}
.land_event_card:first-child{
    margin-left: 0;
}
.land_event_card.big_card {
    width: 275px;
}
.land_event_card.medium_card {
    width: 217px;
}
.land_event_card_box {
    position: relative;
    height: 308px;
    background: var(--bg-color-secondary);
    border-radius: 5px;
}
.land_event_card_icon {
    position: relative;
    display: flex;
    width: 100%;
    height: 130px;
}
/* .community_event_help {
    cursor: pointer;
    position: absolute;
    top: 13px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
} */
.land_event_card_icon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(17, 21, 33, 0.7) 0%, rgba(17, 21, 33, 0) 100%);
}
.land_event_card_points {
    position: absolute;
    bottom: 0;
    left: 10px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    height: 22px;
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: var(--bg-color-primary);
    border-radius: 2px 2px 0 0;
    z-index: 1;
}
.land_event_card_points img {
    flex-shrink: 0;
    width: 16px;
    margin-left: 3px;
}
.land_event_card_icon > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.land_event_card_info {
    width: 100%;
    padding: 10px;
    text-align: left;
}
.land_event_card_video_info{
    width: 100%;
    padding: 20px;
    text-align: left;
}
.land_event_card_info_title {
    position: relative;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 0 0 8px;
}
.land_event_card_info_desc {
    position: relative;
    margin: 0;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: #A4A5A9;
}
.land_event_card_info_action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 40px;
    border-top: 1px solid #4C4E5B;
}
.land_event_card_signed {
    position: relative;
    width: 100%;
    margin: 10px 0 0;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    color: #FFFFFF;
}

.card_name {
    display: flex;
    align-items: center;
}

.big_avatar_icon {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
.big_avatar_icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-title-nowrap {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.name_context {
    color: var(--text-color);
    font-size: 18px;
    font-weight: 400;
    will-change: opacity;
    transition: opacity .2s cubic-bezier(.46,.03,.52,.96);
}
.name_context .ingame_name{
    font-size: 14px;
    font-weight: bold;
}
.name_context .top_game_name{
    font-size: 12px;
}
.connected_game_card{
    height: 200px !important;
}
.profile_title_type_of_activity{
    margin-bottom: 30px;
    margin-right: 30px;
    font-size: 18px;
    font-weight: bold;
    color: var(--profile-name-text-color);
}

.hub-card {
    width: 178px;
    margin: 0 10px 40px;
    position: relative
}
.hub-card:first-child{
    margin-left: 0;
}

.hub-card .card_wrap {
    width: 100%;
    position: relative;
    border-radius: var(--hub-card-wrap-border-radius);
    filter: var(--hub-card-wrap-filter);
    overflow: hidden
}

.hub-card .card_image {
    width: 100%;
    height: 170px;
    overflow: hidden;
    padding: 12px 12px 4px 12px;
    background-color: var(--video--bg-color)
}

.hub-card .card_properties {
    padding: 4px 8px;
    color: #fff;
    font-size: 11px;
    min-height: 110px;
    font-weight: 600
}

.hub-card .card_image img {
    width: 100%;
    height: 154px;
    min-height: 110px;
    border: 1px solid var(--hub-card-image-border-color)
}

.hub-card .label {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 8px;
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    min-width: 50px;
    z-index: 2;
    color: #fff;
    text-align: center;
    opacity: .9
}

.hub-card .label.new:before {
    content: 'NEW'
}

.hub-card .label.new {
    background-color: #6c7fb2
}

.hub-card .checkout_btn {
    text-transform: uppercase;
    position: absolute;
    bottom: 6px;
    right: 8px;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    opacity: 1
}

.hub-card .checkout_btn:hover {
    opacity: .8
}

.hub-card .checkout_btn.disabled {
    color: grey;
    pointer-events: none
}

.hub-card .total_member {
    color: #6c7fb2;
    position: absolute;
    bottom: -18px;
    right: 5px;
    font-size: 11px;
    font-weight: 700
}
.hub-card .event_number {
    color: #6c7fb2;
    position: absolute;
    bottom: -18px;
    left: 5px;
    font-size: 11px;
    font-weight: 700
}
.hub-card .card_properties .hub-name {
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6em
}

.hub-card .hub-name a {
    font-size: 13px;
    font-weight: 700;
    transition: opacity .3s ease-in-out;
    opacity: 1
}
.hub-card .hub-name a:hover {
    opacity: .8
}
.community_prize {
    position: absolute;
    color: #fff;
    bottom: 0;
    z-index: 9999;
    background-color: #1e232f;
    text-align: center;
    left: 7%;
    top: var(--community-event-prize-top);
    height: 31px;
    /* padding: 7px 37px 0 5px; */
    padding: 7px 10px 0 5px;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
}
.community_prize:after {
    /* display: inline-block; */
    display: none;
    content: '';
    background-image: url(../../assets/v4/event/event-card-crown.png);
    width: 32px;
    height: 22px;
    position: absolute;
    right: 5px;
    top: 0;
    background-repeat: no-repeat;
    bottom: 0;
    margin: auto;
    background-size: 53%;
    background-position: center;
}
.community_event.big .community_event_tag {
    background-color: #4770e6;
}
/* .community_event_tag {
    position: absolute;
    top: 8px;
    right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 17px;
    border-radius: 3px;
    background-color: #4469D4;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    opacity: .85;
    z-index: 1;
}
.community_event_tag.live {
    background-color: red;
    animation: FLICKER 2s infinite;
    width: 50px;
}
.community_event_tag.past {
    background-color: #979797;
} */
.list_filter{
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 160px;
}
.admin_dropdown_main.game_type{
    padding: 0 8px;
    color: var(--profile-name-text-color) !important;
}
.admin_dropdown_items.active{
    transform: rotateX(0deg);
    max-height: unset;
}
.admin_dropdown_item.current_user{
    background: linear-gradient(rgb(217, 57, 50) 0%, rgba(217, 57, 50, 0.24) 100%);
}
/* .community_data {
    position: relative;
    height: calc(100% - 36px);
    padding: 15px 15px 10px;
    background-color: #1E232F;
}

.community_data_top {
    width: 100%;
    height: calc(335px - 165px);
}

.community_data_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -5px 15px;
}

.community_data_close {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 12px;
    height: 12px;
    margin: 0 5px;
}

.community_data_platform {
    position: relative;
    display: flex;
    align-items: center;
    width: 12px;
    margin: 0 5px;
}
.community_data_platform > i {
    width: 12px;
    height: 11px;
}
.community_data_platform.big {
    width: 15px;
}
.community_data_platform.big > i {
    width: 15px;
}
.community_data_section {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.community_data_section_icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.community_data_section_icon > i {
    width: 14px;
    height: 15px;
}
.community_data_bot {
    width: 100%;
}

.community_data_bot .cr_btn {
    margin: 10px auto 0;
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    padding: 0 20px;
    border-radius: 2px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color .3s ease-in-out;
    white-space: nowrap;
}

.community_data_link {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    color: #EE733A;
}

.community_event_title {
    margin: 0 0 7px;
    font-size: 14px;
    color: var(--text-color) !important;
    line-height: 19px;
    font-weight: 600;
}

.community_event_title.left_event_title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.community_event_title.right-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
} */
.community_event_desc{
    color: var(--text-color-secondary);
}
.fade-hide{
    display: none;
}
.fade-display{
    display: block;
}

/* settings tab */
.profile_tab_layout {
    display: flex;
}
.profile_layout_menu_mobile {
    display: none;
}
.profile_layout_menu_mobile .list_filter {
    max-width: none;
}
.current_dropdown_item {
    display: flex;
    align-items: center;
}
.profile_layout_menu_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    width: 225px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: var(--profile-menu-text-color);
    transition: color .3s ease-in-out;
    margin-bottom: 30px;
}
.profile_layout_menu_link.active {
    border-right: 3px solid #D93932;
    color: var(--profile-menu-active-color);
}
.profile_layout_menu_link:hover {
    color: var(--profile-menu-active-color);
}
.light .profile_layout_menu_link {
    color: var(--profile-menu-text-color);
}
.light .profile_layout_menu_link.active,
.light .profile_layout_menu_link:hover {
    color: #EE733A;
}
.profile_layout_menu_icon {
    width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
}
.settings_menu_link:hover {
    color: var(--profile-menu-active-color);
}
.settings_menu_link .settings_menu_icon {
    color: var(--profile-menu-text-color);
    font-size: 20px;
    margin-right: 10px;
}
.profile_layout_menu_icon img {
    height: 20px;
    width: auto;
}
.profile_arrow_icon img {
    height: 10px;
    width: auto;
}
.profile_layout_content {
    width: 100px;
    flex: 1 0 auto;
    border-left: 1px solid #343744;
    padding-left: 45px;
}
.profile_tab_layout {
    color: var(--profile-name-text-color);;
}
#queue_up_overview .share_queueup {
    display: flex;
    background: #FF7A00;
    color: #FFFFFF;
    border-radius: 12px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 150px;
}
.queueup-provider-profile .share_queueup {
    position: relative;
    display: inline-flex;
    background: #FF7A00;
    color: #FFFFFF;
    border-radius: 12px;
    width: 135px;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    top: -12px;
    left: 0;
    margin-left: 20px;
}
#settings_tab {
    color: var(--profile-name-text-color);
}
.light .profile_tab_layout {
    color: #201E39;
}
.queue_up_title_container {
    display: flex;
    align-items: center;
}
.queue_up_title_container.mobile {
    display: none;
}
.queue_up_title_container .icon {
    height: 24px;
    margin-right: 14px;
}
.queue_up_title_container .title {
    font-size: 24px;
    font-weight: bold;
}
.queueup_container_empty {
    width: 100%;
    height: 80px;
    background: white;
    margin-bottom: 20px;
}
#settings_profile,
#settings_connections,
#settings_rewards,
#settings_pub_data {
    display: flex;
    flex-direction: column;
    margin-left: -10px
}
.settings_row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
}
.profile_filter_header{
  display: flex;
}
#profile_filter_cover_image{
  margin: 0 20px;
}
.settings_item_wrapper {
    position: relative;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.settings_styled_border {
    border-radius: 2px;
    border: 2px solid var(--profile-input-border-color);
}
#settings_profile_about {
    background-color: rgba(0,0,0,0);
}
.user-selection-container::-webkit-scrollbar,
.incoming_request_description::-webkit-scrollbar,
#settings_profile_about::-webkit-scrollbar {
    width: 6px;
}
.user-selection-container::-webkit-scrollbar-track,
.incoming_request_description::-webkit-scrollbar-track,
#settings_profile_about::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f4f7fd;
}
.user-selection-container::-webkit-scrollbar-thumb,
.incoming_request_description::-webkit-scrollbar-thumb,
#settings_profile_about::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #A0AAC7;
}
.settings_sub_title {
    font-size: 20px;
}
.settings_sub_title img, .settings_label img{
    width: 15px;
    cursor: pointer;
}
.settings_label {
    display: flex;
    align-items: center;
    height: 20px;
    margin: 10px 0;
}
.settings_label_image {
  display: flex;
  align-items: center;
  height: 20px;
  margin: 15px 0;
}
.settings_label_details {
    margin: 0 0 0 4px;
    color: #898B9C;
}
.settings_profile_user_image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
#settings_profile_user_image_wrapper {
    width: 200px;
}
.settings_profile_cover_image {
    height: 200px;
    object-fit: cover;
}
.settings_hoverable_border {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--profile-name-text-color);
    font-weight: 600;
    transition: border-color .3s ease-in-out;
    background-color: var(--profile-input-background-color) !important;
}
.settings_hoverable_border:hover {
    border-color: rgba(255,255,255,.5);
}
.settings_item_wrapper a.settings_text_button {
    font-size: 13px;
    color: #D93932;
    padding: 10px 0 20px;
    cursor: pointer;
}
.settings_image_upload_container a.settings_text_button {
    margin-right: auto;
}
.edittings_text_button{
    font-size: 13px;
    color: #D93932;
    margin-left: 5px;
    cursor: pointer;
}
.settings_item_wrapper input {
    padding: 0 18px;
    height: 45px;
}
.settings_item_wrapper textarea {
    padding: 10px 18px;
    height: 100px;
}
.settings_red_button {
    background-color: #D93932;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
    width: 160px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: none;
}
.settings_red_button:before {
    content: '';
    position: absolute;
    top: 0;
    left: -130%;
    width: 120%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
    transition: transform .2s ease-in-out;
    transform: translate(0,0) skewX(-25deg);
}
.settings_red_button:hover:before {
    transform: translate(100%, 0) skewX(-25deg);
}
.settings_red_button.submit_paypal_email {
  background-color: var(--profile-background-social-icon-color);
  margin-left: auto;
}
#settings_profile_password_reset,
#settings_profile_update_email {
    margin-left: auto;
}
.settings_modal {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings_modal .settings_modal_header {
    margin-top: 0px;
}
.settings_modal .settings_modal_row {
    margin-top: 30px;
}
.settings_modal .settings_modal_dialog {
    margin: 12px;
    padding: 30px;
    background-color: var(--profile-modal-background);
    width: 500px;
    max-width: 500px;
}
.settings_modal_close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.settings_modal_close:hover,
.settings_modal_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
/* animation loading*/
@-webkit-keyframes spinLoaderSaving {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

@keyframes spinLoaderSaving {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

#loaderSaving::after {
    margin-left: 5px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: var(--text-color);
    border-top-color: transparent;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: spinLoaderSaving .8s linear infinite;
    animation: spinLoaderSaving .8s linear infinite;
}
.loaderSaving::after {
    margin-left: 5px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: var(--text-color);
    border-top-color: transparent;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: spinLoaderSaving .8s linear infinite;
    animation: spinLoaderSaving .8s linear infinite;
}
#bigLoaderSaving::after{
    position: absolute;
    top: calc(50% - 20px); left: calc(50% - 20px);
    margin-left: 5px;
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: var(--text-color);
    border-top-color: transparent;
    border-width: 5px;
    border-radius: 50%;
    -webkit-animation: spinLoaderSaving .8s linear infinite;
    animation: spinLoaderSaving .8s linear infinite;
}
#existing_fund #bigLoaderSaving::after{
    left: calc(100% + 10px);
}
/* end animation loading */
#settings_connections_twitch {
    background-color: #6546a4;
}
#settings_connections_twitter {
    background-color: #2e9de1;
}
#settings_connections_discord {
    background-color: #7289da;
}
#settings_connections_youtube {
    background-color: #D93932;
}
.settings_connections_social_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: #ffffff;
}
.settings_connections_social_button:before {
    content: '';
    position: absolute;
    top: 0;
    left: -130%;
    width: 120%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
    transition: transform .2s ease-in-out;
    transform: translate(0,0) skewX(-25deg);
}
.settings_connections_social_button:hover:before {
    transform: translate(100%, 0) skewX(-25deg);
}
.settings_connections_social_button img {
    width: 16px;
    height: 16px;
}
.settings_connections_social_button_text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    margin-left: 10px;
}
.settings_connections_social_label {
    display: flex;
    margin-top: 5px;
    pointer-events: none;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}
.settings_connections_social_label input {
    display: none;
}
.settings_connections_social_checkbox {
    pointer-events: all;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 6px;
    background-color: rgba(238, 115, 58, 0);
    border: 2px solid grey;
    transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
}
.settings_connections_social_label input:checked ~ .settings_connections_social_checkbox {
    border-color: #EE733A;
    background-color: rgba(238, 115, 58, 0.7);
}
.settings_connections_social_label input:checked ~ .settings_connections_social_checkbox.disabled {
    pointer-events: none;
    opacity: 0.6;
}
.btn_sms {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: #ffffff;
    width: 230px;
    background: #D93932;
}
.profile_disconnect_social_account_icon{
    cursor: pointer;
    position: absolute;
    top: -1.5px;
    right: -1.5px;
    display: flex;
    align-items: center;
    width: 24px;
    z-index: 1;
}
.profile_disconnect_social_account_icon:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 350px;
    height: 208px;
    background-color: #353548;
    transform-origin: top right;
    transform: rotate(45deg) translate(50%,-100%);
    transition: transform .3s ease-in-out;
}
.profile_disconnect_social_account_icon:hover ~ .profile_disconnect_social_account {
    opacity: 1;
}
.settings_connections_social_button:hover .profile_disconnect_social_account {
    opacity: 1;
}
.settings_connections_social_button:hover .profile_disconnect_social_account_icon:before {
    transform: rotate(45deg) translate(50%,0%);
}

.profile_disconnect_social_account_icon:hover:before {
    transform: rotate(45deg) translate(50%,0%);
}
.profile_disconnect_social_account{
    color: #FFFFFF;
    font-weight: bold;
    pointer-events: none;
    touch-action: none;
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: 1;
}
.profile_disconnect_social_account_icon i{
    z-index: 100;
    position: absolute;
    top: 1px;
    right: 3px;
}
.profile_disconnect_social_account_icon:hover ~ .profile_disconnect_social_account_icon i {
    opacity: 0;
}
.settings_connections_social_button:hover .profile_disconnect_social_account_icon i {
    opacity: 0;
}
#settings_tab .land_event_card.big_card {
    width: 268px;
}
#settings_connected_games {
    width: calc(100% - 10px);
}
#settings_connected_games .landing_slider .slick-next,
#settings_connected_games .landing_slider .slick-prev {
    top: -35px;
}
.disconnect_game_account {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.land_event_card_icon:hover .disconnect_game_account {
    opacity: 1;
    background: linear-gradient(180deg, rgba(17, 21, 33, 0.7) 0%, rgba(17, 21, 33, 0) 100%);
}
.connect_game_button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    background-color: var(--profile-connect-button-color) !important;
}
.disconnect_game_account_btn {
    background-color: var(--profile-connect-button-color);
    color: var(--profile-name-text-color);
    padding: 15px 20px;
    border-radius: 2px;
}
.disconnect_game_account_btn:hover {
    background-color: var(--profile-hover-connect-button-color);
}
#settings_tab .land_event_card_info{
    height: 70px;
}
#settings_tab .card_name {
    width: 100%;
    height: 100%;
    padding: 5px;
}
#settings_tab .land_event_card_box {
    background-color: var(--profile-connected-game-card-background-color);
}
#settings_rewards .profile_menu_info {
    padding: 0;
}
#settings_rewards .profile_menu {
    margin-bottom: 10px;
}
.settings_rewards_tab {
    width: 100%;
}
/* end of settings tab */

/* settings pagination, table */
.settings_pagination {
    display: flex;
}
.settings_pagination_item {
    display: inline-block;
}
.settings_pagination_item button {
    padding: 15px;
    margin: 1px;
    background-color: var(--profile-reward-table-header-bacground-color);
    border: none;
    color: var(--profile-reward-table-page-text-color);
    cursor: pointer;
    font-weight: bold;
}
.settings_pagination_item button:hover:enabled {
    background-color: var(--bg-color-secondary);
}
.settings_pagination_item button.active {
    color: #D93932!important;
}
.settings_pagination_item button:disabled {
    color: grey;
    cursor: not-allowed;
}
.light .settings_pagination .settings_pagination_item button {
    background-color: #ffffff;
    color: #A0AAC7;
}
.light .settings_pagination .settings_pagination_item button.direction {
    color: #34324C;
}
.settings_pagination_item button.active {
    color: #EE733A!important;
}

.settings_rewards_data_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
}
.settings_rewards_data_table tr {
    margin-bottom: 2px;
}
.settings_rewards_data_table thead tr {
    background-color: var(--profile-reward-table-header-bacground-color);
}
.settings_rewards_data_table tbody tr {
    background-color: var(--bg-color-primary);
}
.settings_rewards_data_table th,
.settings_rewards_data_table td {
    color: #a5a7ab;
    width: 33.33%;
    font-size: 13px;
    font-weight: bold;
}
.settings_rewards_data_table th {
    padding: 10px;
    color: var(--profile-reward-table-header-text-color);
}
.settings_rewards_data_table td {
    padding: 12px 10px;
    color: var(--profile-reward-table-column-text-color);
}

.profile_table_container {
  overflow-x: auto;
  margin-bottom: 20px;
}
.transaction_history_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.transaction_history_table thead tr {
  background-color: var(--profile-reward-table-header-bacground-color);
  border-bottom: 2px solid var(--profile-background-color);
}
.transaction_history_table tbody tr {
  background-color: var(--bg-color-primary);
}
.transaction_history_table th {
  color: var(--profile-reward-table-header-text-color);
}
.transaction_history_table td {
  color: var(--profile-reward-table-column-text-color);
}
.transaction_history_table th span {
  position: relative;
  cursor: pointer;
}
.transaction_history_table th span:before {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 9px;
  right: -14px;
  content: "";
  border: 6px solid transparent;
  border-bottom: 8px solid var(--profile-reward-table-header-text-color);
}
.transaction_history_table th span:after {
  position: absolute;
  width: 0;
  height: 0;
  bottom: -7px;
  right: -14px;
  content: "";
  border: 6px solid transparent;
  border-top: 8px solid var(--profile-reward-table-header-text-color);
}
.transaction_history_table th span.asc:before {
  border-bottom-color: var(--profile-reward-table-column-text-color);
}
.transaction_history_table th span.desc:after {
  border-top-color: var(--profile-reward-table-column-text-color);
}
.transaction_history_table td.rating .stars {
  display: flex;
}
.transaction_history_table td.rating .stars .fa-star {
  margin: 0 1px;
}
.transaction_history_table td.rating .stars .fa-star.checked {
  color: #F09711;
}
.transaction_history_table td.status {
  min-width: 130px;
}
.transaction_history_table td.description {
  max-width: 500px;
}

.transaction_history_table th,
.transaction_history_table td {
  padding: 15px;
}
.transaction_history_table tbody tr:not(:last-child) td {
  border-bottom: 2px solid var(--profile-background-color);
}
.transaction_history_table td .cancel_action {
  color: #D14243;
  cursor: pointer;
  text-transform: capitalize;
}

.transaction_history_table th {
  font-size: 14px;
  font-weight: 700;
}
.transaction_history_table td {
  font-size: 12px;
}
/* end of settings pagination */

/* wallet page */
#wallet_tab {
  color: var(--profile-name-text-color);
  min-height: 650px;
}
.wallet_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#wallet_tab .wallet_row {
  margin-bottom: 80px;
}
.wallet_item_wrapper {
  width: calc(50% - 22px);
  display: flex;
  align-items: center;
}
.wallet_styled_wrapper {
  position: relative;
  width: 100%;
  /* background: linear-gradient(180deg, rgba(93, 106, 150, 0.15) 0%, rgba(0, 0, 0, 0) 100%); */
  background: var(--bg-color-primary);
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  border-radius: 5px;
}
.wallet_sub_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-decoration: underline;
}

#wallet_fund_management {
  width: 100%;
  max-width: 480px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile_fund_container {
  align-items: baseline;
}
.wallet_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#wallet_fund_management .wallet_details {
  /* max-width: 475px; */
  margin: 30px 0;
}
#wallet_fund_management .fund_summary {
  background: white;
  padding: 20px 30px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 0;
}
.wallet_amount_loading {
  position: relative;
}
.wallet_details.fund_action_details {
  margin-bottom: 0px !important;
}
.fund_action_details .circle {
  margin-right: 4px;
}
.fund_action_details label {
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 12px;
}
#existing_fund {
  height: 54px;
  display: flex;
  align-items: center;
}
#existing_fund .queue_up_title_container {
  margin-right: 13px;
}
#existing_fund .fund_amount {
  color: #1D1B34;
  font-size: 54px;
  line-height: 54px;
  margin: 0;
}
#existing_fund span {
  font-size: 20px;
}
.more_tournament {
  color: #D14243;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 600;
}

.wallet_upcoming_events {
  align-items: baseline;
}
#wallet_upcoming_events {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.upcoming_event_header {
  padding: 22px 33px 0px;
}
#incoming_request {
  display: flex;
  flex-direction: column;
}
#incoming_request .wallet_row {
  flex-wrap: wrap;
  gap: 10px;
}

.incoming_request_header,
.transaction_history_header {
  padding: 25px 51px;
}
.incoming_request_description {
  max-height: 44px;
  overflow: auto;
}
.incoming_request_wrapper {
  display: block;
  flex-direction: column;
  background: var(--bg-color-primary);
  padding: 10px 32px 20px;
  border-radius: 5px;
  flex: auto;
  max-width: 50%;
}
.incoming_request_wrapper .wallet_details {
  margin: 8px 0;
}
.incoming_request_wrapper .wallet_details:first-child {
  margin-bottom: 0;
}
.incoming_request_wrapper .wallet_details:nth-child(2){
  margin-top: 0;
}
.incoming_request_title {
  font-size: 21px;
  font-weight: bold;
}
.incoming_request_prize {
  font-size: 31px;
  font-weight: bold;
}
.incoming_request_meta {
  font-size: 10px;
  display: flex;
  flex-direction: column;
}
.incoming_request_action {
  display: flex;
}
.incoming_request_action a.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 30px;
  border-radius: 2px;
  font-size: 14px;
  margin-left: 15px;
  opacity: 1;
  cursor: pointer;
}
.incoming_request_action a.button:hover {
  opacity: 0.9;
}
.incoming_request_action a.button.accept {
  background: var(--profile-background-social-icon-color);
  color: white;
}
.incoming_request_action a.button.decline {
  background: var(--profile-connect-button-color);
  color: var(--profile-reward-table-column-text-color);
}
.incoming_request_action a span {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

#wallet_upcoming_events > .upcoming_event_container {
  padding: 13px 20px;
  margin-bottom: 7px;
}
#wallet_upcoming_events .wallet_sub_title {
  font-size: 16px;
}

#wallet_upcoming_events .event_entry {
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #9C9C9A;
}
#wallet_upcoming_events .event_title {
  font-size: 16px;
  line-height: 23px;
  margin-right: 25px;
}
#wallet_upcoming_events .event_price {
  font-size: 20px;
  font-weight: bold;
}
#wallet_upcoming_events .event_price_mobile {
  display: none;
  font-weight: bold;
  font-size: 22px;
  margin-top: 7px;
}
#wallet_upcoming_events .event_created {
  font-size: 12px;
  font-weight: normal;
}
#wallet_upcoming_events .event_action {
  font-size: 12px;
  font-weight: 600;
  color: var(--profile-background-social-icon-color);
  cursor: pointer;
}

/* fund action popup */
.fund_management_container {
  position: relative;
}
.wallet_details.fund_actions {
  margin: 0 !important;
  padding: 15px 0;
  border-bottom: 1px solid #A0AAC7;
}
.fund_action_popup_container {
  position: absolute;
  top: 0;
  left: -30px;
  width: 100%;
  height: 100%;
}
.fund_action_type_container {
  margin-right: auto;
  margin-bottom: 30px;
}
.fund_action_popup_close {
  display: none;
}
.circle_icon_container.add_fund .fund_action_popup {
  left: -2px;
}
.circle_icon_container.withdraw_fund .fund_action_popup {
  right: -23px;
}

.fund_action_popup {
  width: 500px;
  padding: 20px 30px;
  position: absolute;
  top: calc(100% + 2px);
  background: var(--popup-fund-action-bg-color);
  box-shadow: 0px 16px 32px rgba(0,0,0,0.15);
  border-radius: 5px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fund_action_popup .wallet_details {
  margin: 10px 0 !important;
}
.fund_action_popup::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--popup-fund-action-bg-color) transparent;
}
.fund_action_popup::after {
  top: -10px;
  left: 30px;
}
.fund_popup_title {
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
}
.fund_popup_title.small {
  font-size: 18px;
}
.fund_popup_title.mobile {
  margin-right: auto;
  margin-bottom: 10px;
  display: none;
}
.fund_amount_input_container {
  display: flex;
  align-items: center;
}
.fund_amount_input_container label {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.fund_amount_input_container input {
  display: inline-block;
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #1D1B34;
  opacity: 0.75;
}
.fund_amount_input_container span {
  font-size: 13px;
}
.fund_amount_input_container input {
  font-size: 16px;
  width: 100%;
  margin: 0;
  padding: 0;
}
.fund_amount_info {
  position: relative;
}
.fund_action_popup .fund_action_details {
  margin-bottom: 10px;
}
.real_transaction_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
}
.real_transaction_info span {
  margin-left: auto !important;
}
.paypal_info {
  display: flex;
  align-items: flex-end;
}
.paypal_email_input {
  flex: 1;
  margin-right: 10px;
}
.paypal_email_input .boom-input {
  width: 100%;
}
.paypal_email_modify_container {
  height: 45px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.save_paypal_recipient_btn {
  border: none;
  cursor: pointer;
  border-radius: 2px;
  background: var(--profile-background-social-icon-color);
  color: #fff;
  padding: 0 20px;
  height: 100%;
  font-weight: bold;
}
.paypal_email_edit {
  cursor: pointer;
}
.wallet_fund_management hr {
  margin: 0;
  width: 100%;
  border: 1px solid var(--profile-menu-border-bottom-color);
}
.fund_transfer_container {
  width: 100%;
}
.fund_transfer_container.message_box {
  margin-bottom: 30px;
}
.fund_transfer_container label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #A0AAC7;
  margin-top: 20px;
  margin-bottom: 10px;
}
.fund_transfer_container textarea {
  min-height: 135px;
}
.method_buttons_container {
  display: flex;
  flex: 1;
}
.fund_method_btn {
  width: calc(50% - 15px);
  height: 45px;
  border-radius: 2px;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.fund_method_btn:hover {
  /* opacity: 0.9; */
}
.fund_method_btn.paypal {
  background-color: #6881ca;
}
.fund_method_btn.paypal img {
  width: 125px;
}
.fund_method_btn.credit {
  background-color: var(--profile-background-social-icon-color);
}
.fund_method_btn.credit img {
  width: 64px;
}
.fund_action_button {
  flex: 1;
  background-color: var(--profile-background-social-icon-color);
  font-size: 30px;
  font-weight: 600;
  min-height: 45px;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.fund_action_button span {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}
.fund_action_popup.withdraw .fund_action_button {
  font-size: 25px;
  width: 100%;
}
.fund_action_button:not(.disabled):hover {
  opacity: 0.9;
}
.fund_action_button.disabled {
  cursor: not-allowed;
    background-color: #8e8a8a;
}

.fund-users-selector {
  position: relative;
}
.fund-users-selector .user-name {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none;
  background: var(--color-dark-alt);
}
.fund-users-selector .arrow-caret {
  --arrow-width: 14px;
  --arrow-height: 9px;
  --arrow-color: var(--color-text-light);
  top: -1px;
  margin-left: auto;
}
.fund-users-selector .user-selection-container {
  max-height: 195px;
  overflow: auto;
  position: absolute;
  width: 100%;
  margin-top: 2px;
  filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.15));
  -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.fund-users-selector .selection-input {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0 15px;
  height: 48px;
  border-bottom: 1.5px solid #A0AAC7;
  background: var(--color-dark-alt);
}
.fund-users-selector .selection-input img {
  width: 13px;
  height: 13px;
  margin-right: 9px;
  -o-object-fit: contain;
      object-fit: contain;
}
.fund-users-selector .selection-input input {
  color: var(--color-text-dark-alt);
  font-size: 13px;
  font-weight: 600;
  height: 100%;
  width: 100%;
}
.fund-users-selector .selection-input svg {
  margin-right: 10px;
  margin-left: 10px;
}
.fund-users-selector .status-info svg {
  margin-left: 10px;
}
.fund-users-selector .selection-user {
  display: flex;
  align-items: center;
  background-color: var(--color-dark-alt);
  font-size: 11px;
  padding: 0 15px;
  height: 48px;
  cursor: pointer;
}
.fund-users-selector .selection-user:hover{
  background-color: var(--color-dropdown-hover);
}
.fund-users-selector .selection-user.selected {
  background-color: var(--qualifier-round-bg-color);
}
.fund-users-selector .selection-info {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
}
.fund-users-selector .selection-user.selected .selection-avatar {
  border: 2px solid #EE733A;
}
.fund-users-selector .selection-avatar {
  width: 32px;
  height: 32px;
  border: 2px solid #A0AAC7;
  border-radius: 2px;
  padding: 3px;
  margin-right: 9px;
}
.fund-users-selector .selection-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
      object-fit: cover;
}

/* endof fund action popup */

/* icons within a circle */

.circle_icon_container {
  display: flex;
  align-items: center;
  position: relative;
}
.circle_icon_container span {
  margin-left: 8px;
}
.circle_icon_container.fund_action {
  cursor: pointer;
  height: 57px;
  border-radius: 3px;
  justify-content: center;
  font-weight: bold;
}
.circle_icon_container.active .fund_action_title {
  color: var(--profile-background-social-icon-color);
}

.circle.xl {
  width: 71px; height: 71px;
  border-width: 4px;
}
.circle.lg {
  width: 47px; height: 47px;
  border-width: 2px;
}
.circle.md {
  width: 34px; height: 34px;
  border-width: 2px;
}
.circle.sm {
  width: 19px; height: 19px;
  border-width: 2px;
}
.circle.xs {
  width: 13px; height: 13px;
  border-width: 1px;
}
.circle.colored.plus {
  border-color: var(--circle-plus-table);
}
.circle.colored.plus:before,
.circle.colored.plus:after {
  background: var(--circle-plus-table);
}
.circle.colored.minus {
  border-color: var(--circle-minus-table);
}
.circle.colored.minus:before {
  background: var(--circle-minus-table);
}

.circle {
  border-style: solid;
  border-color: var(--profile-name-text-color);
  border-radius: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.circle.clickable {
  cursor: pointer;
}
.circle:before,
.circle:after {
  content:'';
  position: absolute;
  top:0;left:0;right:0;bottom:0;
}
.circle.plus:before,
.circle.plus:after {
  background: var(--profile-name-text-color);
}
.circle.minus:before {
  background: var(--profile-name-text-color);
}

.circle_icon_container.fund_action .circle {
  border-color: var(--profile-name-text-color);
}
.circle_icon_container.fund_action .circle.plus:before,
.circle_icon_container.fund_action .circle.plus:after {
  background: var(--profile-name-text-color);
}
.circle_icon_container.fund_action .circle.minus:before {
  background: var(--profile-name-text-color);
}

.circle_icon_container.fund_action.active .circle {
  border-color: var(--profile-background-social-icon-color);
}
.circle_icon_container.fund_action.active .circle.plus:before,
.circle_icon_container.fund_action.active .circle.plus:after {
  background: var(--profile-background-social-icon-color);
}
.circle_icon_container.fund_action.active .circle.minus:before {
  background: var(--profile-background-social-icon-color);
}
/* .circle_icon_container.add_fund {
  background: var(--profile-background-social-icon-color);
}
.circle_icon_container.withdraw_fund {
  background: #6881CA;
} */

.circle.plus.xl:before {
  width: 4px;
  margin: 9px auto;
}
.circle.plus.lg:before {
  width: 2px;
  margin: 5px auto;
}
.circle.plus.md:before {
  width: 2px;
  margin: 4px auto;
}
.circle.plus.sm:before {
  width: 2px;
  margin: 3px auto;
}
.circle.plus.xs:before {
  width: 1px;
  margin: 2px auto;
}

.circle.plus.xl:after {
  margin: auto 9px;
  height: 4px;
}
.circle.plus.lg:after {
  margin: auto 5px;
  height: 2px;
}
.circle.plus.md:after {
  margin: auto 4px;
  height: 2px;
}
.circle.plus.sm:after {
  margin: auto 3px;
  height: 2px;
}
.circle.plus.xs:after {
  margin: auto 2px;
  height: 1px;
}

.circle.minus.xl:before {
  margin: auto 9px;
  height: 4px;
}
.circle.minus.lg:before {
  margin: auto 5px;
  height: 2px;
}
.circle.minus.md:before {
  margin: auto 4px;
  height: 2px;
}
.circle.minus.sm:before {
  margin: auto 3px;
  height: 2px;
}
.circle.minus.xs:before {
  margin: auto 2px;
  height: 1px;
}
/* end of icons within a circle */
/* end of wallet page */

/* settings switch button */
.settings_switch_control {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
}
.settings_switch_button {
    position: relative;
    width: 22px;
    height: 14px;
    background-color: var(--profile-switch-button-background-color);
    border-radius: 14px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.settings_switch_button .button {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    background-color: var(--profile-switch-button-color);
    transform: translate(8px, 0);
}
.settings_switch_button.enabled .button {
    background-color: #EE733A;
    transform: translate(0, 0)
}
.settings_switch_container {
    display: flex;
    background-color: var(--bg-color-primary);
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}
.settings_switch_status_controller {
    display: flex;
    align-items: center;
}
.settings_switch_description {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: var(--profile-public-data-label-color);
    margin-right: 10px;
}
.settings_switch_status {
    color: #96999d;
    font-size: 12px;
    font-weight: bold;
}
/* end of settings switch button */

/* queueUp */

.profile_styled_wrapper_header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #A0AAC7;
    padding: 15px 0;
}
.profile_styled_wrapper_header > * {
    display: flex;
}
.profile_queue_up_meta {
    font-weight: 600;
}
.profile_queue_up_meta span {
  margin-right: 5px;
}
.profile_queue_up_meta span:not(:first-child) {
  margin-left: 5px;
}
.queue_view_details {
    display: flex;
    align-items: center;
}
.queue_view_details .status {
    text-transform: uppercase;
    mix-blend-mode: normal;
    opacity: 0.9;
    border-radius: 3px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 10px;
}
.queue_view_details .status.pending {
    background-color: #4B65B1;
}
.queue_view_details .status.complete {
    background-color: #6BD865;
}
.queue_view_details .status.cancel {
    background-color: #FF4646;
}
.view_details_btn {
    font-weight: 700;
    cursor: pointer;
    border-bottom: 2px solid #34324C;
    display: flex;
    align-items: center;
}
.view_details_btn:after {
    content: '\2192';
    font-size: 26px;
    padding-left: 5px;
}
.view_details_icon {
    width: 24px;
    height: 24px;
    position: relative;
}
.view_details_icon:after {
    content: '\00B7\00B7\00B7';
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 50%;
    font-size: 19px;
    cursor: pointer;
}

.profile_styled_wrapper_content {
    padding: 20px 0;
    min-height: 130px;
}
.profile_styled_wrapper_content .profile_about {
    font-size: 12px;
    margin: 0;
    white-space: pre-line;
}
.order_summary_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.orderer_info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.orderer_info .icon_tooltip_container {
    margin-right: 10px;
}
.orderer_info .name {
    color: #EE733A;
}
.queue_up_user_image img {
    width: 100%;
    height: 100%;
}
.queue_up_user_image {
    width: 30px;
    height: 30px;
    margin: 0 8px;
    border-radius: 4px;
    overflow: hidden;
    clip-path: polygon(0 0, calc(100% - 4px) 0, 100% 4px, 100% 100%, 0 100%, 0 0);
}
.order_types {
    margin-bottom: 20px;
}
.order_type {
    display: flex;
    width: calc(50% - 10px);
    align-items: center;
    background: #E6E9F2;
    border-radius: 5px;
}
.order_type .type {
    font-size: 14px;
    font-weight: 400;
    min-width: 95px;
    text-align: center;
}
.order_type_details {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.order_type_details .order_title {
    z-index: 2;
    color: #ffffff;
    position: absolute;
    display: flex;
    text-align: center;
}
.order_type_details .order_mask {
    background: #1D1B34;
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.order_type_details .order_bg_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.order_type_details .order_bg_img img {
    height: 100%;
    width: auto;
}
.order_details_sub_title {
    font-size: 12px;
    color: #A0AAC7;
    font-weight: bold;
}
.session_actions_container,
.order_actions_container {
    width: calc(50% - 10px);
}
.order_requirements li {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 1em;
    position: relative;
}
.order_requirements li::before {
    content: "\2022";
    color: #EE733A;
    font-weight: bold;
    position: absolute;
    top: -2px;
    font-size: 18px;
    margin-left: -15px;
}
.order_actions_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.session_actions_container {
    display: flex;
}
.order_actions {
    display: flex;
    margin-top: 33px;
}
.order_actions .reschedule {
    background-color: #6881CA;
    margin-left: 10px;
}
.order_actions .accept {
    background-color: #EE733A;
}
.order_actions span {
    color: #ffffff;
    padding: 15px 25px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}
.profile_order_details_item {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-top: 10px;
}
.profile_order_details_item .date {
    color: var(--profile-name-text-color);
}
.profile_order_details_item .price {
    color: #A0AAC7;
}
.profile_order_details_item .price:before {
    content: '\00a0|\00a0';
}
.profile_order_details_item .icon_tooltip_container {
    margin-right: 10px;
}
.icon_tooltip_container {
    position: relative;
    display: flex;
    align-items: center;
}
.icon_tooltip_container.clickable {
    cursor: pointer;
}
.icon_tooltip_container
.icon_tooltip_container img {
    height: 20px;
    width: auto;
}
.icon_tooltip_container .tooltip {
    position: absolute;
    padding: 7px 12px;
    background-color: #EDF0F6;
    border: 0.5px solid #A0AAC7;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 12px;
    z-index: 1;
}
.icon_tooltip_container .tooltip.favicon::before,
.icon_tooltip_container .tooltip.status::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    left: 11px;
    bottom: 100%;
    border-bottom-color: #A0AAC7;
}
.icon_tooltip_container .tooltip.favicon::after,
.icon_tooltip_container .tooltip.status::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    left: 12px;
    bottom: 100%;
    border-bottom-color: #EDF0F6;
}
.icon_tooltip_container .tooltip.menu::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    top: 100%;
    left: 123px;
    border-top-color: #A0AAC7;
}
.icon_tooltip_container .tooltip.menu::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    top: 100%;
    left: 124px;
    border-top-color: #EDF0F6;
}

.icon_tooltip_container .tooltip.menu {
    bottom: 35px;
    left: -119px;
    width: 160px;
    color: #000000;
    font-weight: normal;
    text-align: center;
}
.icon_tooltip_container .tooltip.menu hr {
    width: 110px;
}
.icon_tooltip_container .tooltip.menu span {
    cursor: pointer;
}
.icon_tooltip_container .tooltip.favicon {
    top: 30px;
    left: -12px;
    font-weight: 450;
    white-space: nowrap;
}
.icon_tooltip_container .tooltip.status {
    top: 30px;
    left: -12px;
    width: 200px;
    font-weight: 450;
}

.profile_order_details_container {
    position: relative;
    align-items: baseline;
}
.order_total .label {
    font-size: 20px;
    font-weight: 600;
}
.order_total .value {
    color: #EE733A;
    font-size: 24px;
    font-weight: bold;
}
/* end of queueUp */

/* overview tab */
.profile_overview {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 60px;
}
.profile_overview_holder {
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
    width: 100%;
}
.profile_overview_config_section_btn {
    width: 100%;
    height: 42px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background: #586890;
    border: 1px solid #69769B;
    border-radius: 2px;
    cursor: pointer;
}
.profile_overview_config_section_btn img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.profile_overview_editing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}
.profile_overview_add_new_section_btn {
    width: 264px;
    height: 45px;
    background: #EE733A;
    border-radius: 2.12903px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
}
.profile_overview_edit_options {
    display: flex;
}
.profile_overview_save_all_btn {
    margin-right: 20px;
    width: 172px;
    height: 45px;
    background: #EE733A;
    border-radius: 2.12903px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
}
.profile_overview_cancel_btn {
    width: 89px;
    height: 45px;
    border: 1.5px solid #EE733A;
    border-radius: 2px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
}
.modal_change_banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(34,36,49,.5);
    z-index: 99;
    overflow: auto;
}
.modal_change_banner_container {
    width: 100%;
    max-width: 550px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: var(--content-modal-game-account-bg);
    border-radius: 10px;
}
.modal_change_banner_container .settings_label_image {
    color: var(--text-color)
}
.modal_change_banner_btns {
    width: 90px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.modal_change_banner_btn_save {
    color: #ee733a;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}
.modal_change_banner_btn_save.disabled {
    pointer-events: none;
    color: #8e8a8a;
}
.modal_change_banner_btn_close {
    color: #D93932;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}
.private_profile_modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(34,36,49,.5);
    z-index: 10;
    overflow: auto;
}
.profile_overview_empty_card {
    width: 350px;
    height: 451px;
    margin: 0 auto;
    padding: 150px 50px;
    background-image: url(../../assets/profile/add_section_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
.profile_overview_empty_card_title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    max-width: 200px;
    margin: 0 auto 28px;
}
.profile_overview_empty_card_cta {
    height: 45px;
    background: #EE733A;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.profile_overview_sections {
    width: calc(100% + 34px);
    display: flex;
    flex-flow: wrap;
    margin: 0 -17px;
}
.profile_overview_section {
    position: relative;
    height: 450px;
    width: calc(100%/3 - 34px);
    margin: 0 17px 120px;
}
.profile_overview_section.editing_mode {
    cursor: move;
}
.profile_overview_section_title {
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--text-color);
    margin-bottom: 15px;
}
.editing_mode .profile_overview_section_title {
    margin-bottom: 0;
    padding: 15px;
    height: 60px;
    justify-content: space-between;
    background: var(--avgl-bg-secondary);
    border: 3px solid var(--bracket-line-border-color);
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    border-bottom: unset;
}
.editing_mode .profile_overview_section_title.editing_section {
    border: 1px solid #EE733A;
    border-bottom: unset;
    cursor: pointer;
}
.profile_overview_section_title.include_filter {
    justify-content: space-between;
}
.profile_overview_section_title.include_filter .list_filter {
    margin: 0;
}
.profile_overview_section_title_left {
    display: flex;
    align-items: center;
}
.profile_overview_section_title .bio {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.profile_overview_section_title .game_account {
    width: 31px;
    height: 22px;
    margin-right: 10px;
}
.profile_overview_section_title .social_feed_twitter {
    width: 31px;
    height: 25px;
    margin-right: 10px;
}
.profile_overview_section_title .social_feed_youtube {
    width: 32px;
    height: auto;
    min-height: 20px;
    margin-right: 10px;
}
.profile_overview_section_title .social_feed_twitch {
    width: 28px;
    height: 26px;
    margin-right: 10px;
}
.profile_overview_section_title .activity {
    width: 31px;
    height: 37px;
    margin-right: 10px;
}
.profile_overview_section_title .pinned {
    width: 27px;
    height: 28px;
    margin-right: 10px;
}
.profile_overview_section_title .events {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.profile_overview_section_title .hubs {
    width: 34px;
    height: 20px;
    margin-right: 10px;
}
.profile_overview_section_title .clips {
    width: 28px;
    height: 26px;
    margin-right: 10px;
}
.profile_overview_section_title .social_account {
    width: 31px;
    margin-right: 10px;
}
.profile_overview_section_title .add_section {
    width: 29px;
    height: 29px;
    margin-right: 10px;
}
.profile_overview_section_title_panel {
    width: 19px;
}
.profile_overview_section_mobile_edit {
    width: 19px;
    position: absolute;
    top: 18px;
    right: 60px;
    cursor: pointer;
}
.profile_overview_section_body {
    height: 452px;
    background: var(--bg-color-primary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 24px 10px 32px 30px;
}
.profile_overview_section_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
}
.profile_overview_section_remove {
    position: absolute;
    top: 7px;
    left: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #D93932;
}
.profile_overview_section_cancel_edit {
    border: 1px solid #EE733A;
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color);
    width: 58px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.profile_overview_section_save_edit {
    background: #EE733A;
    border-radius: 2px;
    width: 76px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}
.editing_mode .profile_overview_section_body {
    position: relative;
    border-radius: 0 0 10px 10px;
    border: 3px solid var(--bracket-line-border-color);
    border-top: unset;
}
.editing_mode .profile_overview_edit_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 152px 65px 0;
    background: var(--profile-overview-edit-mask-bg-color);
    border-radius: 0 0 5px 5px;
    z-index: 2;
}
.profile_overview_edit_mask .profile_overview_action_edit_section {
    margin-bottom: 50px;
    background: #EE733A;
    border-radius: 2px;
    height: 45px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.profile_overview_edit_mask .profile_overview_action_remove_section {
    background: #D93932;
    border-radius: 2px;
    height: 45px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.editing_mode .profile_overview_section_body.editing_section {
    border: 1px solid #EE733A;
    border-top: unset;
    cursor: pointer;
}
.profile_overview_section_body.add_section {
    padding: 120px 50px;
    background-image: url(../../assets/profile/add_section_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.profile_overview_section_body.add_section.adding {
    padding: 24px 10px 32px 30px;
    background-image: unset;
}
.profile_overview_section_social_feed_body {
    background: var(--bg-color-primary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 452px;
    border-radius: 10px;
}
.editing_mode .profile_overview_section_social_feed_body {
    position: relative;
    border-radius: 0 0 10px 10px;
    border: 3px solid var(--bracket-line-border-color);
    border-top: unset;
}
.editing_mode .profile_overview_section_social_feed_body.editing_section {
    border: 1px solid #EE733A;
    border-top: unset;
    cursor: pointer;
}
.profile_overview_section_content {
    padding-right: 20px;
    color: var(--text-color);
    max-height: 394px;
    overflow-y: auto;
}
.profile_overview_section_content.bio {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    word-wrap: break-word;
}
.quill-editor.profile_overview_section_content.bio {
    height: 100%;
    color: unset;
}
.profile_overview_section_content.game_account .profile_game {
    border-bottom: 2px solid var(--bracket-line-border-color);
    margin-bottom: 22px;
}
.profile_overview_section_content.game_account .profile_game:last-child {
    border-bottom: none;
}
.profile_overview_section_content.game_account .game_info {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}
.profile_overview_section_content.game_account .game_info_left {
    display: flex;
    margin-right: 20px;
}
.profile_overview_section_content.game_account .game_info_left img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.profile_overview_section_content.game_account .game_info_left.social_account img {
    width: 32px;
    height: auto;
    border-radius: unset;
}
.profile_overview_section_content.game_account .game_info_right {
    min-width: 200px;
}
.profile_overview_section_content.game_account .game_info_name {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--text-color);
}
.profile_overview_section_content.game_account .player_game_name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6A7CB0;
}
.profile_overview_section_content.game_account .game_more {
    display: block;
}
.profile_overview_section_content.game_account .game_more img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.profile_overview_section_content.game_account .game_stats {
    margin: 12px 0 22px;
    padding: 8px 2px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: repeat(2,20px);
    background: var(--bg-color);
    border-radius: 4px;
    text-align: center;
}
.profile_overview_section_content.game_account .game_stats_title {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #A4A5A9;
}
.profile_overview_section_content.game_account .game_stats_value {
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: var(--text-color);
}
.profile_overview_section_content.game_account .game_stats_value img {
    width: 15px;
}
.profile_overview_section_content.game_account .social_info {
    margin: 12px 0 22px;
    padding: 5px 15px 10px;
    background: var(--bg-color);
    border-radius: 4px;
}
.profile_overview_section_content.my_events {
    max-height: 415px
}
.profile_overview_section_content.my_events .landing_slider.event_slider {
    margin-top: 20px;
}
.profile_overview_section_content.my_events .landing_slider .slick-list {
    padding: 22px 50px 0 !important;
}
.profile_overview_section_social_feed_content .social_feed_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color);
    padding: 10px 15px 15px;
    border-radius: 10px 10px 0 0;
}
.editing_mode .profile_overview_section_social_feed_content .social_feed_header {
    border-radius: unset;
}
.profile_overview_section_social_feed_content.editing {
    padding: 50px 30px;
}
.profile_overview_section_social_feed_content.editing .social_feed_label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
    margin-bottom: 6px;
}
.profile_overview_section_social_feed_content.editing .social_feed_input {
    border: 2px solid #343744;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 45px;
    margin-bottom: 24px;
    padding: 0 10px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}
.profile_overview_section_social_feed_content .social_feed_info {
    display: flex;
    align-items: center;
}
.profile_overview_section_social_feed_content .social_feed_avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 8px;
}
.profile_overview_section_social_feed_content .social_feed_name {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: var(--text-color);
}
.profile_overview_section_social_feed_content .social_feed_logo {
    width: 28px;
}
.profile_overview_section_social_feed_content .social_feed_empty {
    color: var(--text-color);
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}
.profile_overview_section_social_feed_content .social_feed_body {
    padding: 20px;
    max-height: 350px;
    overflow-y: auto;
    margin-right: 10px;
}
.profile_overview_section_social_feed_content .social_feed_body .broadcast_thumbnail {
    position: relative;
}
.profile_overview_section_social_feed_content .social_feed_body .broadcast_length {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 400;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #FFF;
    padding: 0 4px;
}
.profile_overview_section_social_feed_content .social_feed_body .broadcast_title {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.profile_overview_section_social_feed_content .social_feed_body .broadcast_info {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-color);
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_card {
    border-bottom: 2px solid #343744;
    margin-bottom: 10px;
    padding-bottom: 20px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_card_header {
    display: flex;
    align-items: center;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_avatar {
    width: 48px;
    border-radius: 50%;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_header_info {
    margin-left: 10px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_published {
    color: #898b9c;
    font-size: 14px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_name {
    color: var(--text-color);
    font-weight: 600;
    font-size: 15px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_screenname {
    color: #898b9c;
    font-size: 14px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_card_body {
    margin-top: 15px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_content {
    font-size: 15px;
    color: var(--text-color)
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_react {
    display: flex;
    margin: 10px 0;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_like {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: var(--text-color);
    font-size: 14px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_like img {
    width: 16px;
    margin-right: 5px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_retweet {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 14px;
}
.profile_overview_section_social_feed_content .social_feed_body .tweet_retweet img {
    width: 19px;
    margin-right: 5px;
}
.profile_event_custom_arrows {
    width: 100%;
    display: flex;
    justify-content: center;
}
.profile_event_custom_arrows .left {
    margin-right: 50px;
    width: 25px;
    height: 25px;
    position: relative;
    background-color: #ee733a;
    border-radius: 50%;
    cursor: pointer;
}
.profile_event_custom_arrows .right {
    margin-left: 50px;
    width: 25px;
    height: 25px;
    position: relative;
    background-color: #ee733a;
    border-radius: 50%;
    cursor: pointer;
}
.profile_event_custom_arrows .slick-disabled {
    pointer-events: none;
    background-color: #686a7b;
}
.profile_event_custom_arrows .arrow_left {
    position: absolute;
    top: 5px;
    color: #fff;
    left: 8px;
    font-size: 16px;
}
.profile_event_custom_arrows .arrow_right {
    position: absolute;
    top: 5px;
    color: #fff;
    right: 7px;
    font-size: 16px;
}
.profile_overview_activity_container {
    border-bottom: 2px solid var(--bracket-line-border-color);
    margin-bottom: 16px;
    padding-bottom: 16px;
}
.profile_overview_activity_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.profile_overview_activity_avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.profile_overview_activity_main_action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    min-width: 148px;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: var(--text-color);
    mix-blend-mode: normal;
    opacity: 0.9;
    border-radius: 3px;
}
.profile_overview_activity_main_action.yellow {
    background: linear-gradient(90deg, #FFC701 -84.8%, var(--bg-color-primary) 100%);
    border-left: 5px solid #FFC701;
}
.profile_overview_activity_main_action.silver {
    background: linear-gradient(90deg, #C0C0C0 -84.8%, var(--bg-color-primary) 100%);
    border-left: 5px solid #C0C0C0;
}
.profile_overview_activity_main_action.bronze {
    background: linear-gradient(90deg, #CD7F32 -84.8%, var(--bg-color-primary) 100%);
    border-left: 5px solid #CD7F32;
}
.profile_overview_activity_main_action.green {
    background: linear-gradient(90deg, #4CB05B -84.8%, var(--bg-color-primary) 100%);
    border-left: 5px solid #4CB05B;
}
.profile_overview_activity_main_action.blue {
    background: linear-gradient(90deg, #4770E6 -84.8%, var(--bg-color-primary) 100%);
    border-left: 5px solid #4770E6;;
}
.profile_overview_activity_date {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    color: var(--text-color);
}
.profile_overview_activity_time {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    color: var(--text-color);
}
.profile_overview_activity_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--bg-color);
    border-radius: 4px;
    padding: 8px 14px;
}
.editing_section .profile_overview_activity_body {
    justify-content: flex-start;
}
.profile_overview_activity_body .profile_overview_activity_action_title {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--text-color);
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.profile_overview_activity_body .profile_overview_activity_title_info {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 10.606px;
    line-height: 15px;
    color: #A4A5A9;
}
.profile_overview_activity_body .profile_overview_activity_hub_member {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1.44627px solid #4C4E5B;
}
.profile_overview_activity_body .profile_overview_activity_body_star {
    width: 19px;
    margin-right: 26px;
    cursor: pointer;
}
.profile_overview_activity_body .profile_overview_activity_body_right {
    width: 30px;
    cursor: pointer;
}
.profile_hub_card_notice {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
}
.profile_hub_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-color-secondary);
    padding: 8px;
    margin-bottom: 10px;
}
.profile_hub_card_logo {
    border: 1px solid #4C4E5B;
    box-sizing: border-box;
    border-radius: 2px;
    width: 47px;
    height: 47px;
}
.profile_hub_card_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.profile_hub_card_info {
    width: calc(100% - 60px);
}
.profile_hub_card_name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--text-color);
}
.profile_hub_card_more {
    display: flex;
    justify-content: space-between;
}
.profile_hub_card_action {
    display: block;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #EE733A;
}
.profile_hub_card_member {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    color: #5F7194;
    text-transform: uppercase;
}
.profile_overview_section_content.my_clips .landing_slider.event_slider {
    margin-top: 20px;
}
.profile_overview_section_content.my_clips .landing_slider .slick-list {
    padding: 22px 35px 0 !important;
}
.profile_video_card {
    filter: var(--community-event-filter);
}
.add_section .profile_overview_section_content {
    text-align: center;
    padding-right: 0;
}
.add_section.adding .profile_overview_section_content {
    padding-right: 20px;
}
.add_section .profile_overview_section_content img {
    width: 85px;
    margin-bottom: 55px;
}
.add_section .profile_overview_section_content .add_new_section {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EE733A;
    border-radius: 2px;
    height: 45px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.add_section .profile_overview_section_content .add_new_section.disabled {
    background-color: grey;
    pointer-events: none
}
.add_section.adding .profile_overview_add_section_title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--text-color);
    text-align: left;
    margin-bottom: 25px;
 }
.add_section.adding .profile_section_to_add {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    background-image: url(../../assets/profile/add_section_bg_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #343744;
    border-radius: 6px;
    cursor: pointer;
}
.add_section.adding .profile_section_to_add.disabled {
    background-image: unset;
    pointer-events: none;
}
.add_section.adding .profile_section_to_add.selected {
    border: 2px solid #E97B26;
}
.add_section.adding .profile_section_to_add img {
    width: 16px;
    margin-bottom: 0;
    margin-right: 10px;
}
.add_section.adding .profile_section_to_add_name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
}
.add_section.adding .disabled .profile_section_to_add_name {
    color: #808080;
}
.profile_overview_section_content::-webkit-scrollbar,
.social_feed_body::-webkit-scrollbar {
    width: 3px;
}
.profile_overview_section_content::-webkit-scrollbar-track,
.social_feed_body::-webkit-scrollbar {
    border-radius: 1.5px;
    background-color: var(--profile-stat-border-color);
}
.profile_overview_section_content::-webkit-scrollbar-thumb,
.social_feed_body::-webkit-scrollbar-thumb {
    border-radius: 1.5px;
    background-color: rgba(137, 139, 156, 0.35);
}
.profile_overview_left {
    width: 100%;
    margin: 0 20px 0 30px;
    max-width: 430px;
}
.profile_overview_stats {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 30px;
}
.profile_overview_stat {
    background-color: #1E232F;
    border-radius: 5px;
    width: calc(50% - 10px);
    margin: 0 5px 10px;
    padding: 15px;
}
.profile_overview_stat_icon {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.profile_overview_stat_icon img {
    width: initial;
    height: initial;
}
.profile_overview_stat_num {
    margin: 0 0 2px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
}
.profile_overview_stat_num span {
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #5CA868;
}
.profile_overview_stat_text {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #A4A5A9;
}
.profile_overview_about {
    width: 100%;
    margin: 0 0 30px;
}
.profile_overview_session{
    margin: 0 0 20px;
    position: relative;
}
.profile_overview_session.drops_ranks{
  	padding: 0px;
  	height: 580px;
	background: none;
	border: none;
}
.profile_overview_session.drops_ranks .drops_ranks_item {
	margin: 0;
	border-radius: 0;
}
.profile_wrapper_leaderboard {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 89%;
    background-color: #1E232F;
    opacity: 0.8;
}
.profile_overview_leaderboard_item {
    height: 89%;
    overflow-y: auto;
}
.profile_leaderboard_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile_leaderboard_info #profile_filter_game{
    margin-bottom: 0px;
}
.profile_overview_leaderboard_item::-webkit-scrollbar {
    width: 6px;
}
.profile_overview_leaderboard_item::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #3e404e;
}
.profile_overview_leaderboard_item::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #6a7cb0;
}
.profile_overview_title {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 7px;
    margin: 0 0 20px;
    border-bottom: 1px solid #343744;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: var(--profile-name-text-color);
}
.profile_overview_title img {
    width: auto;
    height: 20px;
    margin-right: 10px;
}
.profile_overview_about_desc {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--profile-about-content);
    white-space: pre-line;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.profile_overview_about_desc a {
    color: #ae2d28;
}
.profile_overview_about_desc::-webkit-scrollbar {
    width: 6px;
}
.profile_overview_about_desc::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #3e404e;
}
.profile_overview_about_desc::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #6a7cb0;
}
.profile_overview_image {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto 40px;
}
.profile_overview_image:last-child {
    margin-bottom: 0px;
}

.profile_overview_right {
    width: 100%;
}
.profile_overview_activity_title {
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: var(--profile-name-text-color);
}
.profile_overview_activity {
    padding: 20px;
    width: 100%;
    margin: 0 auto 10px;
    background-position: 0 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: var(--profile-background-activity-card-color);
    border-radius: 5px;
}
.profile_overview_activity_top {
    display: flex;
    width: 100%;
}
.profile_overview_activity_icon {
    margin-right: 20px;
}
.profile_overview_activity_icon img {
    height: 50px;
    width: auto;
}
.profile_overview_activity_info {
    width: 100%;
}
.profile_overview_activity_info_default{
  width: 100%;
  color: var(--profile-name-text-color);
}
.profile_overview_activity_info_default p{
  font-weight: bolder;
  font-size: 16px;
  margin: 5px 0;
}
.profile_overview_activity_status {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #898B9C;
    margin-bottom: 5px;
}
.profile_overview_activity_tag {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    height: 17px;
    padding: 0 10px;
    margin-right: 10px;
    background-color: #4770E6;
    opacity: 0.9;
    border-radius: 3px;
    text-transform: uppercase;
}
.profile_overview_activity_tag.blue {
    background-color: #4770E6;
}
.profile_overview_activity_tag.blue2 {
    background-color: #4B65B1;
}
.profile_overview_activity_tag.red {
    background-color: #E64747;
}
.profile_overview_activity_tag.green {
    background-color: #62B76F;
}
.profile_overview_activity_tag.orange {
    background-color: #EE733A;
}
.profile_overview_activity_desc {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--profile-name-text-color);
}
.profile_overview_activity_desc span, .profile_overview_activity_desc span a {
    color: var(--profile-activity-card-link-color);
}
.profile_overview_activity_bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.profile_overview_activity_data {
    display: flex;
    align-items: center;
}
.profile_overview_activity_data_item {
    display: flex;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--profile-name-text-color);
    padding: 0 14px;
    border-right: 1px solid rgba(255,255,255,0.2);
}
.profile_overview_activity_data_item:first-child {
    padding-left: 0;
}
.profile_overview_activity_data_item:last-child {
    padding-right: 0;
    border-right: none;
}
.profile_overview_activity_action {
    cursor: pointer;
    position: relative;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--profile-background-social-icon-color);
}
.profile_overview_activity_clip {
    display: flex;
    align-items: center;
    width: 114px;
    flex-shrink: 0;
    margin-left: 20px;
}

.profile_overview_about_edit{
    margin-left: 10px;
    cursor: pointer;
}
.profile_overview_activity_loadmore{
    color: var(--profile-name-text-color);
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
}
.profile_overview_activity_loadmore:hover{
    text-decoration: underline;
    cursor: pointer;
}
.profile_overview_no_activities{
    color: #ffffff;
    text-align: center;
    width: 100%;
}
.profile_connected_stream_area{
    position: relative;
}
.connected_stream_embed{
    margin-top: 20px;
}
.profile_wrapper_connected_stream{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: #1E232F;
  opacity: 0.8;
}
.profile_not_connect_stream{
  position: absolute;
  display: flex;
  justify-content: center;
  top: 35%;
  width: 100%;
  color: #ffffff;
  background-color: #1E232F;
}
.profile_button_show_connect_stream{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: #ffffff;
    background-color: #6546a4;
    margin-bottom: 15px;
}
.profile_label_note_connect_stream{
    text-align: center;
    white-space: pre-line;
}
/* end overview tab */

/* responsive */
@media only screen and (max-width: 1280px) {
    .profile_header_info.no_social_connected .profile_header_current_event {
        max-width: 350px;
    }
}
@media only screen and (max-width: 1024px) {
    .profile_header_info.no_social_connected .profile_header_current_event {
        max-width: 252px;
    }
}
@media only screen and (max-width: 768px) {
    .profile_menu_link.not_mobile {
      display: none;
    }
    .profile_header_info {
      width: 100%;
      justify-content: center;
      margin-bottom: 30px;
      flex-direction: column;
    }
    .profile_header_social_mobile {
        display: flex;
    }
    .profile_header_social_mobile img {
        width: 28px;
        margin-right: 10px;
        vertical-align: middle;
    }
    .profile_header_icon,
    .profile_header_stat_icon {
      margin-right: 0;
    }
    .profile_header_status {
      margin: auto;
    }
    .profile_header_date,
    .profile_header_username {
      text-align: center;
    }
    .profile_header_stats {
      width: 100%;
      padding: 0 20px;
      justify-content: center;
      gap: 10px;
    }
    .profile_header_stats > * {
      flex: 1;
    }
    .profile_header_stats > *:not(:last-child) {
        margin-right: 10px;
    }
    .profile_header_stats .not_mobile {
        display: none;
    }
    .profile_overview_holder{
        flex-wrap: wrap;
    }
    .profile_overview_left{
        max-width: 100%;
        margin: 10px 0;
    }
    .profile_overview_right{
        margin: 100px 0;
    }
    .profile_tab_layout {
        display: flex;
        flex-direction: column;
    }
    .profile_layout_content {
        width: 100%;
        border: none;
        padding: 0;
    }
    .profile_layout_menu_desktop {
        display: none;
    }
    .profile_layout_menu_mobile {
        display: block;
    }
    .profile_layout_menu_mobile .admin_dropdown_main.game_type {
        border: unset;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        background-color: var(--bg-color-primary);
    }
    .profile_layout_menu_mobile .admin_dropdown_main.game_type span{
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }
    .profile_fund_container {
        flex-direction: column;
        align-items: center;
    }
    #wallet_fund_management {
        margin-right: 0;
    }
    #existing_fund {
        justify-content: space-between;
    }
    #wallet_fund_management .fund_summary {
        padding: 12px 25px;
    }
    #existing_fund .fund_amount {
        font-size: 27.2px;
        line-height: 37px;
    }
    .fund_action_title {
        font-size: 13px;
    }
    #wallet_upcoming_events .event_title {
        margin-right: 0;
    }
    #wallet_upcoming_events .event_price {
        display: none;
    }
    #wallet_upcoming_events .event_price_mobile {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    .incoming_request_wrapper {
        max-width: 100%;
        width: 100%;
    }
    .profile_leaderboard_item_name_mobile {
        width: 100px !important;
    }
    .profile_leaderboard_item_points_mobile {
        white-space: nowrap;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .profile_header_stat{
      margin: 0;
      padding: 0;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 130px;
    }
    .profile_menu_info{
        padding: 0 15px;
    }
    .profile_menu{
        flex-wrap: wrap;
        border-bottom: unset;
        margin: 0 auto 10px;
    }
    .profile_menu_section{
        overflow-x: auto;
    }
    .profile_menu_section:first-child {
        margin-left: 0;
        border-bottom: 1px solid var(--profile-menu-border-bottom-color);
    }
    .profile_menu_link:first-child {
        margin-left: 0;
    }
    #connected_social_accounts {
        justify-content: center;
        width: 100%;
    }
    .settings_row {
        flex-wrap: wrap;
    }
    #settings_connections_button_container > div {
        margin-bottom: 5px;
    }
    .settings_pagination li:first-child,
    .settings_pagination li:last-child {
        display: none;
    }
    .settings_switch_container {
        flex-direction: column;
    }
    .settings_switch_status_controller {
        margin-top: 5px;
    }
    .fund_action_popup {
        position: unset;
        margin: 0 15px;
    }
    .fund_action_popup_close {
        width: 100%;
        display: block;
    }
    .fund_action_popup_close .settings_modal_close {
        line-height: 10px;
    }
    .fund_action_popup_container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        background: #201E39B3;
    }
    .fund_popup_title.mobile {
        display: block;
    }
    .fund_popup_title,
    .fund_action_details .circle,
    .fund_action_details label {
        display: none;
    }
    .fund_amount_input_container {
        position: relative;
        width: 100%;
    }
    .real_transaction_info span {
        margin-left: 0 !important;
    }
    .body_main.stat_more {
        transform: translateX(27%);
        zoom: 0.8;
    }
    .body_main.stat_more.profile_view_drops {
        zoom: 0.8;
    }
}
@media only screen and (max-width: 500px) {
    .order_types {
        flex-direction: column;
    }
    .order_type {
        margin: 6px 0;
        width: 100%;
    }
    .profile_order_details_container {
        width: 100%;
        flex-direction: column;
    }
    .order_dates.order .profile_order_details_item {
        flex-direction: column;
    }
    .order_dates.session .profile_order_details_item {
        margin-bottom: 10px;
        justify-content: space-between;
    }
    .order_dates.order .price:before {
        content: '';
    }
    .order_dates.session {
        width: 100%;
    }
    .order_dates.session .price:before {
        content: '\00a0|\00a0\00a0\00a0\00a0\00a0';
    }
    .order_dates.order .price {
        margin: 10px 0;
    }
    .order_actions_container {
        width: 100%;
        flex-direction: column;
    }
    .view_details_icon {
        margin-left: 10px;
    }
    .queue_up_title_container .title {
        font-size: 14px;
    }
    .view_details_btn.not_mobile {
        display: none;
    }
    .queue_up_title_container.mobile {
        display: block;
    }
    .order_summary_title {
        font-weight: normal;
    }
    .order_total {
        width: 100%;
        text-align: center;
    }
    .order_actions {
        width: 100%;
        margin-top: 20px;
    }
    .order_actions span {
        width: 100%;
        padding: 10px 0;
    }
    .fund_amount_input_container span {
        font-size: 16px;
    }
    #queue_up_overview .share_queueup {
        position: relative;
        top: 0;
        left: 0;
    }
    .queueup-provider-profile .share_queueup {
        display: none;
    }

    /* new profile */
    .profile_page_bg {
        height: 111px;
        top: 61px;
        background-position: center center !important;
    }
    .profile_header {
        margin: 85px auto 0;
        padding: 12px 12px 26px;
        justify-content: center;
        background: unset;
        border: unset;
    }
    .profile_header_buttons {
        display: none;
    }
    .profile_header_social {
        display: none;
    }
    .profile_header_info {
        flex-direction: unset;
        padding: 0 20px;
        justify-content: flex-start;
    }
    .profile_header_icon {
        margin-right: 15px;
        width: 70px;
        height: 70px;
    }
    .profile_header_icon img {
        width: 70px;
        height: 70px;
    }
    .profile_header_date, .profile_header_username {
        font-size: 24px;
        line-height: 33px;
        text-align: left;
    }
    .profile_header_stat {
        height: 62px;
    }
    .profile_header_stat_icon {
        display: none;
    }
    .profile_header_stat_info {
        margin-top: 5px;
    }
    .profile_header_stat_num {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }
    .profile_header_stat_tag {
        font-size: 11px;
        line-height: 18px;
    }
    .profile_menu {
        background: unset;
        padding: 0 15px;
        border: unset;
        align-items: start;
    }
    .profile_menu_section {
        width: 100%;
        border: unset;
    }
    .profile_menu_section:first-child {
        border-bottom: unset;
    }
    .profile_menu_section .list_filter {
        max-width: unset;
        margin: 0 0 226px;
    }
    .profile_tabs {
        padding: 0 15px 15px;
        background: unset;
        border: unset;
    }
    .profile_overview_editing {
        flex-flow: wrap;
    }
    .profile_overview_config_section_btn {
        margin-bottom: 35px;
    }
    .profile_overview_add_new_section_btn {
        width: 100%;
        margin-bottom: 26px;
    }
    .profile_overview_edit_options {
        width: 100%;
        justify-content: space-between;
    }
    .profile_overview_sections {
        width: 100%;
        margin: 0;
    }
    .profile_overview_section {
        width: 100%;
        margin: 0 0 40px;
        height: auto;
    }
    .profile_overview_section_body {
        padding: 24px 10px 32px 12px;
    }
    .editing_mode .profile_overview_section_title.collapse {
        position: relative;
        border-bottom: 3px solid var(--bracket-line-border-color);
        border-radius: 10px;
    }
    .profile_overview_section_content.game_account .game_info_name {
        max-width: 200px;
    }
    .profile_overview_section_footer {
        position: relative;
    }
}
@media only screen and (max-width: 415px) {
    .profile_overview_section_content .profile_tournament_card {
        width: 216px !important;
    }
    /* reponsive for tournament card, connected game card, top clip card have only 1 card on mobile screen */
    .profile_tournament_card, .profile_connected_game_card, .profile_video_card{
        width: 91vw !important;
    }
}

@media only screen and (max-width: 360px) {
    /* new profile */
    .profile_overview_section_content.game_account .game_info_right {
        min-width: 170px;
    }
    .profile_overview_section_content.game_account .game_stats_title {
        font-size: 10px;
    }
    .profile_overview_section_content.game_account .game_stats_value {
        font-size: 10px;
    }
    .profile_overview_section_title.include_filter .list_filter {
        max-width: 140px;
    }
    .profile_overview_activity_avatar {
        width: 45px;
        height: 45px;
    }
    .profile_overview_activity_main_action {
        min-width: 120px;
        font-size: 10px;
    }
}

@media (min-aspect-ratio: 16/9) {
    .video-container iframe {
        height: 56.25vw;
    }
}
@media (max-aspect-ratio: 16/9) {
    .video-container iframe {
        width: 177.78vh;
    }
}
/* end responsive */
