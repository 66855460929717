.player_statistics {
  --player-statistics-height: 90px;

  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border);

  .player-info-container {
    display: flex;
    grid-gap: 20px;
    margin-right: 20px;
    flex: 1;

    .avatar {
      width: var(--player-statistics-height);
      height: var(--player-statistics-height);
      border-radius: 9px;
      -o-object-fit: cover;
         object-fit: cover;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;

      .player-name {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
      }

      .rank {
        display: flex;
        margin-top: 7px;

        .rank-avatar {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          -o-object-fit: contain;
             object-fit: contain;
        }
        .rank-info {
          display: flex;
          flex-direction: column;

          .rank-name {
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
          }
          .rank-info {
            font-size: 11px;
            font-weight: bold;
            color: var(--color-secondary);
          }
        }
      }
    }
  }

  .stats-container {
    display: grid;
    grid-template-columns: repeat(3, 168px);
    grid-gap: 8.5px;
    grid-auto-rows: 100%;
    justify-content: flex-end;
    height: auto;

    .stat {
      display: flex;
      flex-direction: column;
      border-radius: var(--statistics-border-radius);
      background-color: var(--color-dark-alt);
      padding: 15px;
      padding-bottom: 10px;
      height: 100%;

      .stat-icon {
        width: 22px;
        height: 22px;
        -o-object-fit: contain;
           object-fit: contain;
      }

      .stat-rank {
        font-size: 16px;
        line-height: 24px;
        margin-top: 3px;
      }
      .stat-location {
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;

        color: var(--color-text-light-alt);

        strong {
          color: var(--color-primary);
        }
      }
    }
  }
}