.slick-prev {
    top: 0;
    left: 0;
    z-index: 1;
    height: 18px;
    width: 75px;
    height: 152px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.slick-next {
    top: 0;
    right: 0;
    z-index: 1;
    height: 18px;
    width: 75px;
    height: 152px;
    background-color: rgba(86, 85, 97, 0);
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.slick-prev:before {
    margin-left: 11px;
    left: 0
}

.slick-next:before {
    margin-right: 11px;
    right: 0
}

.slick-next:before,
.slick-prev:before {
    content: '';
    height: 18px;
    width: 11px;
    display: flex;
    align-items: center;
    opacity: .5;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.variable-width-big .slick-next,
.variable-width-big .slick-prev {
    width: 35px;
    top: 40%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-image: none;
    background-color: var(--slick-arrow-bg-color)
}

.variable-width-big .slick-next:focus,
.variable-width-big .slick-next:hover,
.variable-width-big .slick-prev:focus,
.variable-width-big .slick-prev:hover {
    background-color: var(--slick-arrow-hover-bg-color)
}

@media all and (max-width:430px) {
    .variable-width-big .slick-next,
    .variable-width-big .slick-prev {
        top: 33%
    }
}

@media all and (max-width:330px) {
    .variable-width-big .slick-next,
    .variable-width-big .slick-prev {
        top: 30%
    }
}