/* hub drops general */
.community_drop .community_menu {
    height: 50px;
}
.community_drop .community_panel_mobile {
    display: none;
}
.community_drop .community_bg_mobile {
    display: none;
}
.community_drop .community_logo_mobile {
    display: none;
}

.community_drop .community_social_menu {
    background-color: var(--hub-drop-community-social-menu-bg);
}
.community_drop .community_leaderboard {
    background-color: var(--hub-drop-community-leaderboard-bg);
}
.community_drop .community_membercount {
    color: #FFF;
}
.community_section_hub_drop .community_membercount {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 9.36px;
    color: #FFFFFF;
}
.community_drop .btn_action_left {
    height: 50px;
    width: 103px;
    color: #FF393F !important;
    background: #FFF !important;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 0;
}
.community_drop .btn_action_left img {
    display: none;
}
.community_drop .cr_btn {
    color: #FFF;
}
/* hub drops feature event */
.community_drop_featured_menu_title {
    line-height: 28px;
    color: var(--text-color);
    font-size: 20.4932px;
    font-weight: 800;
    margin: 0;
    margin-right: 20px;
}
#list_hub_drop .community_event {
    width: 216px;
}
#list_hub_drop .community_event.big {
    width: 457px;
    margin: 0 20px 30px 0;
}
#list_hub_drop .community_event.big:after {
    background-image: linear-gradient(180deg,rgba(14,12,19,0), #11152100, #111521a8,#111521);
}
#list_hub_drop .community_event.big .community_event_bg img {
    max-height: none;
    height: 325px;
    object-fit: cover;
}
#list_hub_drop .community_event.big .community_event_tag.live {
    width: 66px;
    height: 14px;
    font-weight: 600;
    font-size: 12.0203px;
    line-height: 16px;
    opacity: 0.9;
    border-radius: 2.20962px;
}
.community_event.big .community_event_tag.past {
    background-color: #979797 !important;
    color: #FFFFFF !important;
    font-weight: bold;
}
#list_hub_drop .community_event.big .community_event_title {
    color: #FFF !important;
    font-size: 16px;
    font-weight: 700;
}
#list_hub_drop .community_event.big .community_event_desc {
    color: #FFF;
    font-style: normal;
    font-weight: normal;
}
#list_hub_drop .community_event.big .community_event_drop_info {
    position: absolute;
    top: 8px;
    left: 10px;
    background: rgba(87, 102, 143, 0.7);
    min-width: 150px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border-radius: 6px;
}
#list_hub_drop .community_event.big .community_event_drop {
    font-weight: bold;
    font-size: 19.8866px;
    line-height: 27px;
    color: #FFFFFF;
}
#list_hub_drop .community_event.big .community_event_drop_point_unit {
    font-size: 12px;
    margin-left: 3px;
}
#list_hub_drop .community_event.big .community_event_drop_close_time {
    margin-left: 5px;
    font-size: 8px;
    line-height: 10px;
    color: #FFFFFF;
}
#list_hub_drop .community_event .cr_btn {
    background: #E97B26;
}
#list_hub_drop .community_event .cr_btn:hover {
    background: #d76e1d;
}
#list_hub_drop .community_event.big .cr_btn {
    height: fit-content;
    min-height: 34px;
}
#list_hub_drop .community_event_info .cr_btn span{
    width: 100%;
    white-space: pre-line;
    text-align: center;
}
.btn_drop_disabled {
    cursor: unset !important;
    background: #808080 !important;
    user-select: none !important;
    pointer-events: none;
}
#upcoming_drops {
    width: 42.5vw;
    height: 320px;
    padding: 20px 15px;
    //margin-left: 20px;
    background: var(--hub-drop-upcoming-drop-bg);
    filter: var(--hub-drop-upcoming-drop-filter);
    border-radius: var(--hub-drop-upcoming-drop-border-radius);
}
#upcoming_drops.show_items_center {
    display: flex;
}
#upcoming_drops.show_items_left {
    max-width: 440px;
}
#upcoming_drops.show_items_center > .community_events {
    padding: 20px;
}
#upcoming_drops .upcoming_drops_menu_title {
    font-weight: bold;
    font-size: 18.987px;
    line-height: 141.18%;
    color: var(--text-color);
}
#upcoming_drops .slick-slider .community_event {
    width: 175px;
    height: fit-content !important;
    margin-top: 20px;
}

#upcoming_drops .community_event_link {
    display: flex;
    align-items: center;
    cursor: pointer;
}
#upcoming_drops .community_event_link img {
    width: 9px;
    height: 7px;
    margin-left: 3px;
}
#upcoming_drops .community_event_info {
    min-height: 130px;
}
#upcoming_drops .community_event_bar {
    width: 175px;
    left: 0;
    bottom: 35px !important;
}
#upcoming_drops .community_event_bar_2 {
    width: 160px;
    left: 1px;
}
#upcoming_drops .community_event_link {
    bottom: 10px;
}
#upcoming_drops .community_event_tag {
    top: 8px;
}
#upcoming_drops .community_event_tag.upcoming {
    background: #57668F;
    opacity: 0.7;
    border-radius: 1.32448px;
}
#upcoming_drops .slick-prev,
#upcoming_drops .slick-next {
    top: -26px;
}
#upcoming_drops .community_event_desc {
    font-size: 10px;
    font-style: normal;
    font-weight: normal;
    color: var(--text-color);
}
/* hub drops work */
.hub_drops_works {
    position: relative;
    width: 100%;
    /*max-width: 858px;*/
    padding: 30px;
    margin: 0 auto 60px;
    border: var(--drops-header-border);
    background-color: var(--landing-social-bg-color);
    /*background: var(--drops-works-bg);*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: 50% 50%;*/
    /*filter: var(--drop-rank-filter);*/
}
.hub_drops_works.progress {
    border-radius: var(--hub-drop-progress-border-radius);
    filter: var(--community-event-filter);
}
.hub_drops_works.progress.show_verizon_background {
    background-color: unset;
    background-image: url("../../assets/v4/community/new_verizon_background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    filter: unset;
}
.hub_drops_works .slick-track {
    padding: 10px 0;
}
.hub_drops_works_header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 15px;
    margin: 0 auto 5px;
    border-bottom: 2px solid #343744;
}
.hub_drops_works_title {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 0;
}
.hub_drops_works_title img {
    width: 195px;
    height: 52px;
    /* margin-right: 4.74px; */
    object-fit: contain;
}
.hub_drops_works_title div {
    display: flex;
}
.hub_drops_works_title_text {
    margin: 0;
    font-family: Open Sans, sans-serif;
    margin-right: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 19.5194px;
    line-height: 30px;
    letter-spacing: -0.36827px;
    color: var(--hub-drop-title-text-color);
    margin-top: 0;
}
.hub_drops_works_title_text_city {
    font-style: italic;
    font-weight: bold;
    font-size: 19.5194px;
    line-height: 8px;
    color: #E97B26;
    text-shadow: 0.736541px 1.47308px 0px rgba(255, 255, 255, 0.25);
    float: right;
}
.hub_drops_works_desc {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
    width: 100%;
    padding-right: 130px;
    max-width: 690px;
}
.hub_drops_works.progress .hub_drops_works_desc {
    padding: 0;
    max-width: initial;
}
.hub_drops_today_quest {
    margin-left: 100px;
}
.hub_drops_today_quest_reward {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.hub_drops_quest_title {
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    margin-right: 8px;
}
.hub_drops_today_quest_reward_value {
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--text-color);
}
.today_quest_point {
    color: #E97B26;
}
.hub_drops_today_quest_question {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color);
}
.hub_drops_btn_submit_answer {
    width: 204px;
    min-width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    color: #FFFFFF;
    padding: 12px 0;
    margin-left: 36px;
    cursor: pointer;
}
.triangle_bottom {
    clip-path: polygon(0 0,100% 0,100% calc(100% - 8px),calc(100% - 9px) 100%,0 100%);
}
.hub_drops_time_city {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.hub_drops_current_city {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: var(--hub-drop-current-city-color);
    margin-right: 15px;
    text-transform: capitalize;
}
.hub_drops_time {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #2E2E2E;
}
.hub_drops_works_progress_title {
    margin: 0 0 4px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: var(--hub-drop-progress-title-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.hub_drops_works_progress_container {
    padding-top: 65px;
}
.hub_drops_works_progress {
    width: 100%;
}
.hub_drops_works_progress_lineholder {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100%/6*5);
    height: 24px;
    margin: 0 auto 8px;
}
.hub_drops_works_progress_line_relative {
    position: relative;
    height: 45px;
    padding: 20px 0;
    overflow: hidden;
    width: 100%;
}
.hub_drops_works_progress_line {
    /* position: relative; */
    width: 100%;
    height: 5px;
    background: #343744;
    overflow: hidden;
}
.hub_drops_works_progress_fill {
    position: absolute;
    top: 21px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 8px;
    background-color: #FF3932;
    transform: translate(-100%,0);
    transition: transform .3s ease-in-out;
    z-index: 3;
    box-shadow: 0px 0px 18.2549px #C32130;
}
.hub_drops_works_progress_record {
    position: absolute;
    width: 1.12px;
    height: 86.32px;
    background: #C4C4C4;
    bottom: -5px;
}
.hub_drops_works_current_progress {
    position: absolute;
    bottom: 30px;
    left: 0%;
    height: 50px;
    padding: 0 10px;
    background: #FF3932;
    border-radius: 3px;
    display: flex;
    align-items: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.hub_drops_works_current_progress:before {
    content: '';
    position: absolute;
    bottom: -7px;
    width: 14px;
    height: 14px;
    margin: 0 50%;
    background-color: inherit;
    -webkit-transform: rotate(45deg);
    z-index: 1;
}
.hub_drops_works_current_progress_title {
    width: 40px;
    text-align: right;
    font-size: 11px;
    line-height: 12px;
    margin-right: 10px;
}
.hub_drops_works_current_progress_score {
    text-transform: uppercase;
    font-size: 23.84px;
    line-height: 25px;
    font-weight: 800;
    width: 100%;
    text-align: center;
}
.hub_drops_works_current_progress_score_type {
    text-transform: uppercase;
    font-size: 7px;
    line-height: 8px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    z-index: 2;
}
.hub_drops_works_progress_point {
    position: absolute;
    top: 6px;
    left: 0;
    width: 13.05px;
    height: 12.17px;
    background-color: #453541;
    border-radius: 50%;
    box-shadow: 0px 0px 30px rgba(195, 33, 48, 0);
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    z-index: 2;
}
.hub_drops_works_progress_point.start {
    width: 4.78px;
    height: 13px;
    background-color: #453541;
    border-radius: unset;
    z-index: 3;
}
.hub_drops_works_progress_point.on {
    width: 4.78px;
    height: 13px;
    background-color: #FF3932;
    border-radius: unset;
    box-shadow: 0px 0px 18.2549px #C32130;
}
.hub_drops_works_progress_infos {
    display: flex;
    align-items: flex-start;
}
.hub_drops_works_progress_info {
    width: 100%;
    text-align: center;
}
.hub_drops_works_progress_info .section {
    justify-content: center;
}
.hub_drops_works_progress_info .drops_works_crown {
    margin-right: 5px;
}
.hub_drops_works_progress_text_point_reward {
    font-size: 9.62px;
    font-weight: 700;
    line-height: 13.1px;
    text-align: center;
    width: 100%;
    color: var(--hub-drop-text_point_reward-color);
}
.hub_drops_works_crown {
    display: flex;
    width: 26px;
    flex-shrink: 0;
    margin-right: 5px;
    justify-content: center;
}
.hub_drops_works_crown_title {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--text-color);
}
.hub_drops_final_reward {
    width: 131.45px;
    height: 39.54px;
    background: var(--hub-drop-final-reward-bg);
    box-shadow: 0px 0px 4.0372px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5.93px 7.91px;
    border: var(--hub-drop-final-reward-border);
    box-sizing: border-box;
    margin: 0 auto;
}

.hub_drops_final_reward_point {
    text-align: center;
    width: 50px;
}

.hub_drops_final_reward_point_value {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    color: var(--hub-drop-progress-title-color);
}

.hub_drops_works_qualifier {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 7.10781px;
    line-height: 8px;
    text-transform: uppercase;
    color: var(--hub-drop-qualifier-color);
    width: 100px;
    text-align: center;
}

.hub_drops_works_qualifier span {
    font-weight: 800;
}
/* hub drop leaderboard  */
.community_drop_leaderboard_title {
    font-style: normal;
    font-weight: 900;
    font-size: 19.5194px;
    line-height: 29px;
    letter-spacing: -0.36827px;
    color: var(--hub-drop-leaderboard-title-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.community_drop_leaderboard_dropdowns .community_dropdown_main {
    font-weight: normal;
}
.community_drop_leaderboard_dropdowns .community_dropdown_item {
    font-weight: normal;
    word-break: break-word;
}
.community_drop_leaderboard_dropdowns .community_dropdown_main span {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.community_my_drop_leaderboard_position {
    position: relative;
}
.community_my_drop_leaderboard_position_btn_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.community_my_drop_leaderboard_position_btn {
    text-align: center;
    padding: 4px 15px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, .3) 2px 2px;
    background-color: rgb(233, 123, 38);
    color: #FFF;
}
.community_drop_leaderboard_title .hub_drops_works_title {
    margin-right: 0;
}
.community_drop_leaderboard_title .hub_drops_works_title img {
    width: 120px;
    height: 32px;
    /* margin-right: 3.34px; */
    object-fit: contain;
}
.community_drop_leaderboard_title .hub_drops_works_title .hub_drops_works_title_text {
    margin-right: 0px;
    margin-top: 0;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 11.8814px;
    line-height: 18px;
    letter-spacing: -0.224165px;
    color: var(--input-text-color);
}
.community_drop_leaderboard_title .hub_drops_works_title_text_city {
    font-family: Open Sans, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 13.0653px;
    line-height: 7px;
    color: #E97B26;
    text-shadow: 0.462066px 0.924132px 0px rgba(255, 255, 255, 0.25);
    float: right;
}
.community_drop_leaderboard_items {
    margin: 0 -14px;
    height: 78%;
    padding-left: 14px;
    padding-right: 8px;
    overflow-y: auto;
}
.community_drop_leaderboard_items::-webkit-scrollbar {
    width: 6px;
}
.community_drop_leaderboard_items::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--scrimmage-scrollbar-track-color);
}
.community_drop_leaderboard_items::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #6a7cb0;
}
.community_drop_leaderboard_item {
    width: 100%;
    height: 40px;
    border-radius: 2.42229px;
    padding: 6.06px 12px 6.06px 6.06px;
    display: flex;
    align-items: center;
    margin: 3.39px 0;
    background: var(--hub-drop-leaderboard-item-bg);
    justify-content: space-between;
}
.community_drop_leaderboard_item.leaderboard_top {
    background: var(--hub-drop-leaderboard-item-top-bg);
    border: var(--hub-drop-leaderboard-item-top-border);
}
.community_drop_leaderboard_item.me {
    border: 0.736541px solid #EA7F32;
    box-shadow: 0px 0px 2.94616px #EA7F32;
}
.community_my_drop_leaderboard_position .community_drop_leaderboard_item {
    border: none;
    box-shadow: unset;
    background: linear-gradient(180deg, #E97B26 -12.84%, rgba(233, 123, 38, 0.24) 87.16%);
    margin-bottom: 20px;
}
.community_drop_leaderboard_users {
    display: flex;
    align-items: center;
    width: 55%;
}
.community_drop_leaderboard_users img {
    width: 30px;
    height: 30px;
    border-radius: 1.21115px;
    object-fit: cover;
    margin-right: 12.11px;
}
.community_drop_leaderboard_username {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);
    width: calc(100% - 24.22px - 12.11px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.community_my_drop_leaderboard_position .community_drop_leaderboard_username {
    color: #FFFFFF;
}
.community_drop_leaderboard_stats {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);
}
.community_my_drop_leaderboard_position .community_drop_leaderboard_stats {
    color: #FFFFFF;
}
.community_drop_leaderboard_ranks {
    width: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    text-align: right;
    color: var(--text-color);
}
.community_my_drop_leaderboard_position .community_drop_leaderboard_ranks {
    color: #FFFFFF;
}
.community_drop_leaderboard_qualified {
    color: var(--hub-drop-leaderboard-qualified-color);
    font-weight: 800;
}
.community_my_drop_leaderboard_position .community_drop_leaderboard_qualified {
    color: #FFF;
}
.hub_drops_desc {
    max-width: 1000px !important;
    padding-right: 20px !important;
    color: var(--hub-drop-description-color) !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
.hub_drops_desc span{
    font-weight: 800;
    font-size: 18px;
    color: var(--hub-drop-how-to-win-desc-city-color);
}
.hub_drops_gradient_background {
    /*background-image: -moz-linear-gradient(left, #ffa92e, #ac0b5c);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffa92e), to(#ac0b5c));*/
    background-image: -webkit-linear-gradient(left, #ffa92e, #f52122, #ac0b5c) !important;
    border: none !important;
    /*background-image: -o-linear-gradient(left, #ffa92e, #ac0b5c);*/
}
/* end hub drop leaderboard  */
@media (max-width: 1720px) {
    #upcoming_drops {
        width: 40.5vw;
    }
}
@media (max-width: 1650px) {
    #upcoming_drops {
        width: 37.5vw;
    }
}
@media (max-width: 1570px) {
    #upcoming_drops {
        width: 34.5vw;
    }
}
@media (max-width: 1500px) {
    #upcoming_drops {
        width: 32.5vw;
    }
}
@media (max-width: 1440px) {
    .hub_drops_works {
        padding: 25px;
    }
    #upcoming_drops {
        margin-left: 0;
        width: 29.5vw;
    }
    /* .hub_drops_final_reward {
        width: 130px;
        padding: 6px;
    } */
    /* .hub_drops_final_reward_point {
        width: 32px;
    } */
    /* .hub_drops_final_reward_point_value {
        font-size: 10px;
    } */
    /* .hub_drops_works_qualifier {
        font-size: 9px;
        width: 80px;
        line-height: 12px
    } */
}
@media (max-width: 1000px) {
    .community_drop .community_header {
        padding: 0;
        justify-content: unset;
        background-image: unset !important;
        flex-direction: column;
        height: 162px !important;
    }
    .community_drop .community_panel_mobile {
        padding: 40px 20px 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: var(--bg-color);
    }
    .community_drop .community_bg_mobile {
        display: block;
        width: 100%;
        height: 90px;
    }
    .community_drop .community_logo_mobile {
        display: block;
        position: relative;
        top: -6px;
        left: 0px;
    }
    .community_drop .community_bg_mobile img {
        height: 100%;
        object-fit: cover;
    }
    .community_drop .community_info {
        display: none;
    }
    .community_drop .community_panel {
        display: none;
    }
    .community_drop .community_menu {
        padding: 0 30px;
        height: 65px;
    }
    .community_drop .community_logo {
        margin-bottom: 0px;
        width: 71px;
        height: 71px;
    }
    .community_drop .community_logo img {
        object-fit: contain;
    }
    .community_drop .community_about {
        display: none;
        margin-right: 0;
    }
    .community_drop .community_mobile_desc {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .community_drop .community_mobile_desc .community_title {
        display: none;
    }
    .community_drop .community_mobile_desc .community_desc {
        display: none;
    }
    .community_drop .community_title {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: var(--text-color);
    }
    .community_drop .community_desc {
        font-size: 12px;
        line-height: 16px;
        color: #979797;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 4em;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .community_drop .community_mobile_desc .community_membercount {
        position: absolute;
        right: 160px;
    }
    .community_drop .btn_action_left {
        height: 42px;
        width: 108px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        border-radius: 4px;
    }
    .community_drop .com_hamburger {
        width: 24px;
        border-radius: unset;
        background-color: unset;
        background-image: url("../../assets/v4/community/drop_hamburger.png");
    }
    .hub_drops_works_progress {
        margin-left: 25px;
    }
    .hub_drops_works_progress_container {
        overflow-x: scroll;
    }
    .hub_drops_works_progress_container::-webkit-scrollbar {
        height: 12px;
        padding: 2px 0;
    }
    .hub_drops_works_progress_container::-webkit-scrollbar-track {
        background: #E7E7E7;
        border-radius: 6.3311px;
    }
    .hub_drops_works_progress_container::-webkit-scrollbar-thumb {
        background: #BABABA;
        box-shadow: 0px 0px 50.6488px rgba(233, 123, 38, 0.35227);
        border-radius: 6.3311px;
    }
    .hub_drops_works_progress {
        min-width: 892px;
        height: 90px
    }
    .hub_drops_works_current_progress_title {
        display: none;
    }
    .hub_drops_works_progress_lineholder {
        margin: 5px 0;
    }
    .hub_drops_works_progress_info {
        display: flex;
        justify-content: flex-start;
    }
    .hub_drops_works_progress_info .hub_drops_works_progress_title {
        margin-left: -21px;
        text-align: left;
    }
    .hub_drops_works_progress_info:first-child .hub_drops_works_progress_title {
        margin-left: 0;
    }
    .hub_drops_final_reward {
        margin: 0 0 0 -65px;
    }
}
@media (max-width: 576px) {
    #quest_reward_hub {
        padding: 20px;
    }
    .hub_drops_desc {
        max-width: 100% !important;
        max-height: 50px;
        padding-right: 0 !important;
        color: var(--hub-drop-description-color) !important;
        font-size: 12.96px;
    }
    .hub_drops_desc p {
        margin: 0;
    }
    .hub_quest_card, .hub_completed_quest_card {
        width: calc(100vw - 50px) !important;
        margin: 0 10px !important;
    }
    .completed_challenges .slick-prev {
        position: absolute !important;
        left: calc(100vw - 110px) !important;
        top: -53px !important;
    }
    .completed_challenges .slick-next {
        position: absolute !important;
        left: calc(100vw - 70px) !important;
        top: -53px !important;
    }
    .hub_drop_open_quest .slick-prev, .hub_drop_open_quest .slick-next {
        display: none !important;
    }
    .hub_drops_works.progress {
        padding: 25px 15px;
        border-radius: unset;
        filter: unset;
        margin-bottom: 0;
        padding-bottom: 60px;
        border-bottom: 1.89933px solid #E7E7E7;
        background: transparent;
    }
    .hub_drops_works.progress.show_verizon_background {
        background-color: var(--landing-social-bg-color);
        background-image: unset;
    }
    .hub_drops_works_progress_point.on {
        width: 5px;
    }
    .community_drop .community_menu {
        padding: 0 20px;
    }
    #upcoming_drops.show_items_center {
        display: block;
    }
    #upcoming_drops.show_items_center > .community_events {
        padding: 0px;
    }
}
@media (max-width: 440px) {
    .community_drop_featured_menu_title {
        margin-left: 20px;
    }
    #list_hub_drop .community_event.big {
        width: 375px;
        height: 275px !important;
    }
    #list_hub_drop .community_event.big .community_event_num {
        display: none;
    }
    #list_hub_drop .community_event.big .community_event_desc {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 6em;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    #list_hub_drop .community_event.big .cr_btn {
        margin-bottom: 10px;
    }
    #upcoming_drops {
        width: 340px !important;
    }
    .hub_drops_works_header {
        border-bottom: 1.89933px solid #E7E7E7;
    }
    .hub_drops_time_city {
        margin-top: 15px;
        padding-left: 15px;
        border-left: 5px solid #FF3932;
    }
    .community_drop_leaderboard_items {
        height: 70%;
    }
    .hub_drops_today_quest_mobile {
        padding: 5px 0 20px;
        border-bottom: 1.89933px solid #E7E7E7;
    }
    .hub_drops_today_quest {
        margin: 15px 0 0 0;
    }
    .hub_drops_quest_title {
        color: var(--text-color);
        font-weight: 600;
        font-size: 15px;
    }
    .hub_drops_today_quest_reward {
        padding-left: 10px;
        border-left: 5px solid #FF3932;
    }
    .hub_drops_today_quest_question {
        margin: 20px 0;
        color: var(--hub-drop-today-quest-question-color);
    }
    .hub_drops_btn_submit_answer {
        width: 100%;
        margin: 10px 0 0 0;
    }
}
@media (max-width: 392px) {
    #list_hub_drop .community_event.big {
        width: 350px;
        height: 250px !important;
    }
}
@media (max-width: 375px) {
    #list_hub_drop .community_event.big {
        width: 335px;
        height: 250px !important;
    }
}
@media (max-width: 360px) {
    #list_hub_drop .community_event.big {
        width: 320px;
    }
}
