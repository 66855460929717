.tooltip-simple {
  display: none;
  position: absolute;
  background-color: var(--color-dark-alt);
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 10px 8px;
  white-space: nowrap;
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--global-tooltip-z);

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: -9px;
    left: calc(50% - 8px);
    transform: rotate(45deg);
    background-color: var(--color-dark-alt);
    border: 1px solid black;
    border-top-width: 0;
    border-left-width: 0;
  }

  strong {
    color: var(--color-secondary);
  }
}