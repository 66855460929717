.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    overflow-x: auto;
    overflow-y: scroll;
    max-height: 200px;
}
.autosuggest__results::-webkit-scrollbar {
    width: 5px;
}
.autosuggest__results::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0d6dbe;
}

.autosuggest__results ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results-item {
    border-top: none;
}

.autosuggest__results .autosuggest__results-item {
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
    color: #000000;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #ddd;
}
/* recuiter page */
.recruiter_page_container{
    width: 100vw;
    font-family: 'GothamPro', sans-serif !important;;
    font-weight: 200;
    padding-bottom: 100px;
    background-size: 100% !important;
    margin-bottom: -40px;
}
.recruiter_page_background{
    position: absolute;
    width: 100%;
    z-index: -1;
}
.recruiter_page_header{
    height: 550px;
    padding: 80px 20px 30px;
}
.recruiter_page_middle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.recruiter_page_logo{
    width: 275px;
    height: 160px;
}
.recruiter_page_title{
    margin: 40px 0 30px;
    color: white;
}
h2.recruiter_big_title{
    font-weight: 900;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
}
.recruiter_small_title{
    font-weight: 400;
    font-size: 22px;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
}
.recruiter_page_games{
    color: white;
}
.recruiter_page_option_label{
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 22px;
    text-align: center;
    text-transform: uppercase;
}
.recruiter_page_game_options{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.recruiter_page_game_option{
    width: 160px;
    height: 80px;
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
}
.recruiter_page_game_logo{
    width: 94px !important;
}
.recruiter_page_content{
    position: relative;
    padding: 0 140px 80px 140px;
}
.recruiter_page_filter{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.filter_search_players{
    width: 200px;
    border: 2px solid #a6b4c2;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    height: 60px;
}
.input_filter_players{
    color: #E5E5E5;
    font-size: 16px;
    width: 100% !important;
    font-weight: 200;
}
.input_filter_players.block {
    display: block;
}
.input_with_border {
    border: 1px solid #a6b4c2;
    text-align: center;
    width: 80%;
    padding: 10px 0;
}
.input_filter_players::placeholder{
    color: #E5E5E5;
}
.content_filter {
    margin-top: 15px;
}
.up_down_icon {
    font-size: 13px;
    cursor: pointer;
}
.no_players_found {
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-style: italic;
    font-size: 14px;
    font-weight: 200;
    color: #000000;
}
.close_filter_tab_icon {
    position: absolute;
    top: 20px;
    right: 15px;
}
.filter_search_players_icon{
    color: #E5E5E5;
    margin-right: 8px;
    cursor: pointer;
}
.filter_search_role {
    width: 400px;
}
.filter_min_max_section {
    position: absolute;
    background: rgba(33, 90, 136, 0.85);
    border: 2px solid rgba(255, 255, 255, 0.5);
    top: 57px;
    width: 250px;
    z-index: 2;
}
.expand .filter_min_max_section {
    width: 300px;
}
.min_max_section {
    margin-bottom: 10px;
}
.min_max_title {
    text-transform: uppercase;
    font-weight: 600;
}
.current_value_min_max {
    text-decoration: underline;
}
.filter_search_state {
    position: absolute;
    background: rgba(33, 90, 136, 0.85);
    border: 2px solid rgba(255, 255, 255, 0.5);
    top: 57px;
    width: 250px;
    z-index: 2;
}
.expand .filter_search_state {
    width: 300px;
}
.filter_search_rank {
    width: 400px;
}
.filter_search_rank_range {
    width: 100%;
}
.filter_gpa_section {
    position: absolute;
    background: rgba(33, 90, 136, 0.85);
    border: 2px solid rgba(255, 255, 255, 0.5);
    top: 57px;
    width: 250px;
    z-index: 2;
}
.filter_search_champion_poor {
    width: 300px;
}
.expand .filter_gpa_section {
    width: 200px;
}
.filter_search_goal {
    width: 300px;
}
.filter_search_champion {
    width: 500px;
}
.expand .filter_search_goal {
    width: 400px;
}
.filter_value {
    text-transform: uppercase;
}
.item_goals,
.item_roles {
    text-transform: uppercase;
}
.state_list,
.champion_list {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
}
.recruiter_page_overall_filter{
    display: flex;
    width: 900px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 10px 0;
}
.recruiter_lol_filter {
    display: flex;
    width: 1200px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 10px 0;
}
.recruiter_fornite_filter {
    display: flex;
    width: 500px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 10px 0;
}
.recruiter_page_overall_filter.expand {
    width: 1200px;
}
.recruiter_page_table{
    position: relative;
    width: 100%;
    background-color: white;
    display: flex;
}
.recruiter_page_header_table{
    width: 100%;
    height: 48px;
    background-color: #D5D5D5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: 100% 100% !important;
}
.header_table_left{
    display: flex;
    margin-left: 60px;
    justify-content: flex-start;
    align-items: center;
    color: #050C20;
}
.header_table_right{
    display: flex;
    margin-left: 60px;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
}
.header_table_left img, .header_table_right img{
    margin-right: 8px;
    width: 30px !important;
}
.recruiter_page_table_left{
    width: 70%;
}
.recruiter_page_table_right{
    width: 30%;
    border-left: 2px solid #888888;
}
.recruiter_page_header_table_title{
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
}
.recruite_page_row.table_gray_row{
    background: #E5E5E5;
}
.recruite_page_row{
    height: 50px;
    width: 100%;
    display: flex;
    border-left: 2px solid hsla(0,0%,100%,.5);
}
.recruite_page_row.active{
    border-top: 2px solid #006DC0;
    border-bottom: 2px solid #006DC0;
}
.recruite_page_cell{
    text-align: left;
    font-size: 14px;
    color: #000000;
    border-right: 1.5px solid #e5e5e54d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    padding: 10px;
}
.recruite_page_cell:last-child {
    border-right: unset;
}
.recruiter_page_additional_info{
    position: absolute;
    display: flex;
    left: -80px;
}
.recruiter_page_additional_info_after{
    width: 0px;
    height: 0px;
    border-top: 18px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 15px solid #006DC0;
}
.recruiter_page_additional_info_before{
    background-color: #006DC0;
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 5px;
}
.recruiter_page_view_profile{
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 10px;
}
.recruiter_page_view_profile:hover{
    text-decoration: none;
    color: #FFFFFF;
}
.recruite_page_head{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    color: #FFFFFF;
    justify-content: space-between;
}
.recruite_page_head.align_center {
    justify-content: center;
}
.recruiter_page_responsive_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: grid;
}
.avatar_recruiter_player {
    width: 20%;
    margin-right: 10px;
}
.player_cell {
    justify-content: left;
}
.sort-icon {
    font-size: 16px;
}
.sort_icon_section {
    cursor: pointer;
}
.sort_icon_section.disabled {
    cursor: default;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}
.sort_icon_section.disabled .sort-icon {
    color: #e5e5e5;
}
.recruite_page_row:nth-child(odd){
    background: #f5f5f5;
}
.recruite_page_row.head{
    height: 57px;
    padding: 10px 0;
    background: rgba(13, 111, 191, 0.75) !important;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
}
.rotate_arrow{
    transform: rotate(180deg);
}
.table_head_filter_arrow{
    width: 8px;
}
.table_head_filter_arrow img{
    cursor: pointer;
}
.table_head_select {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #a6b4c2;
}
.table_head_select.active {
    background: #ee733a;
}
.table_head_select:hover {
    background: #ee733a;
}
.table_head_select.active:hover {
    background: #ffffff;
}

.champion_pool_area{
    display: flex;
    flex-wrap: wrap;
}
.champion_pool_area img{
    width: 16px;
    margin: 4px;
}

.header_expand_img{
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.recruiter_page_export{
    position: absolute;
    bottom: -35px;
    right: 0px;
    padding: 10px 0;
    height: 35px;
    background-color: #006DC0;
    display: flex;
}
.recruiter_page_export .btn_export_data{
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    padding: 0 15px;
    cursor: pointer;
}
.recruiter_page_export .btn_export_data.left{
    border-right: 2px solid #FFFFFF;
}
.intel_discord_link {
    color: rgb(255, 0, 0);
    text-decoration: underline;
}
.intel_discord_link:hover {
    color: rgb(255 119 0);
}
@media screen and (max-width: 768px){
    .recruiter_page_container{
        background-size: 140% !important;
        background-position-x: 50% !important;
    }
    .recruiter_page_background{
        width: 140%;
        transform: translate(-15%, 0);
    }
    .recruiter_page_logo{
        width: 220px;
        height: 110px;
    }
    h2.recruiter_big_title{
        font-size: 30px;
    }
    .recruiter_small_title{
        font-size: 18px;
    }
    .recruiter_page_game_option{
        margin-top: 10px;
        width: 145px;
        height: 75px;
    }
}
@media screen and (max-width: 576px){
    .recruiter_page_container{
        background-size: 250% !important;
        background-position-x: 40% !important;
    }
    .recruiter_page_background{
        width: 250%;
        transform: translate(-25%, 0);
    }
    .recruiter_page_header{
        padding: 30px 10px;
    }
    .recruiter_page_logo{
        width: 220px;
        height: 110px;
    }
    h2.recruiter_big_title{
        font-size: 30px;
    }
    .recruiter_small_title{
        font-size: 18px;
    }
    .recruiter_page_game_option{
        margin-top: 10px;
        width: 145px;
        height: 75px;
    }
    .recruiter_page_content{
        padding: 0 10px 10px;
    }
    .recruiter_page_export{
        height: 50px;
        bottom: -50px;
    }
    .intel_list_page{
        margin-top: 60px;
    }
    .header_table_left{
        margin-left: 5px;
    }
    .header_table_right{
        margin-left: 10px;
    }
    .header_expand_img{
        margin-right: 10px;
    }
}
/* end recruiter page */
.filter_checkbox_intel [type="checkbox"]:not(:checked),
.filter_checkbox_intel [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
}
.filter_checkbox_intel [type="checkbox"]:not(:checked) + label,
.filter_checkbox_intel [type="checkbox"]:checked + label {
    font-family: Open Sans, sans-serif;
    position: relative;
    padding-left: 2.3em;
    font-weight: 600;
    font-size: 12px;
    line-height: 159.5%;
    text-transform: uppercase;
    cursor: pointer;
}

/* checkbox aspect */
.filter_checkbox_intel [type="checkbox"]:not(:checked) + label:before,
.filter_checkbox_intel [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    background: rgba(33, 90, 136, 0.85);
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1))
}

/* checked mark aspect */
.filter_checkbox_intel [type="checkbox"]:not(:checked) + label:after,
.filter_checkbox_intel [type="checkbox"]:not(:checked) + label:after,
.filter_checkbox_intel [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: 8px;
    left: -1px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 0;
}

/* checked mark aspect changes */
.filter_checkbox_intel [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
}

.filter_checkbox_intel [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
}

.filter_checkbox_intel {
    text-align: left;
    padding: 14px;
}

.name_zone .search-title {
    margin-top: 10px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 118px;
}
