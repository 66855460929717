.overview_statistics {
  display: flex;
  flex-direction: column;

  .overview-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    .overview-graph {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-dark-alt);
      border-radius: 5px;
      padding: 20px;
      flex: 1;

      .graph-circle {
        width: 138px;
        height: 138px;
        border-radius: 100%;
        background-color: var(--color-dark);
        position: relative;

        .progress-circle {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          transform: rotate(-90deg);
          overflow: visible;

          .circle {
            cx: 50;
            cy: 50;
            r: 25;
            fill: none;
            stroke: var(--color-circle-stroke);
            stroke-width: 3;
            stroke-dasharray: 157;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            transition: stroke-dashoffset var(--global-animation-long);
          }

          .active {
            stroke: var(--color-primary);
            stroke-dashoffset: 156;
          }
        }
      }

      .graph-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .data-icon {
          width: 26px;
          height: 26px;
          -o-object-fit: contain;
             object-fit: contain;
        }
        .data-wins {
          font-size: 18px;
          line-height: 25px;
        }
        .data-win-ratio {
          color: var(--color-secondary);
          font-size: 11px;
          font-weight: bold;
        }
      }
    }

  }
}