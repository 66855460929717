.core_statistics {
  $grid-line-size: 8px;
  $grid-line-padding: 8px;

  display: flex;
  flex-direction: column;
  height: 276px;

  .statistics-header .extra-info {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    .info {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 0 5px;
    }
    .player {
      color: var(--color-primary);
    }
    .avg {
      color: var(--color-secondary);
    }
  }

  .failed-data {
    margin-top: 10px;
  }

  .stats-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .stats {
      display: flex;
      align-items: flex-end;
      flex: 1;
      position: relative;

      .stats-grid-lines {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-bottom: 1.5px solid var(--color-border);

        .grid-line {
          flex: 1;
          background-image: linear-gradient(to right, var(--color-border) $grid-line-size, rgba(0,0,0,0) 0%);
          background-position: top left;
          background-size: ($grid-line-size + $grid-line-padding) 1px;
          background-repeat: repeat-x;
          &:first-child {
            background-image: none;
          }
        }
      }

      .stat {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        flex: 1;
        z-index: 1;
        cursor: pointer;
        &:hover {
          -webkit-filter: brightness(125%);
                  filter: brightness(125%);

          .tooltip-simple {
            display: block;
          }
        }

        .bar {
          width: 5px;
          height: 1%;
          min-height: 1%;
          border-radius: 5px;
          transition: height var(--global-animation-long);
          margin: 0 3.5px;
        }
        .bar-player {
          background-color: var(--color-primary);
        }
        .bar-avg {
          background-color: var(--color-secondary);
        }

        .tooltip-simple {
          top: 0;
          margin-left: -6px;
          text-transform: capitalize;
        }
      }
    }

    .stats-names {
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-align: center;
      color: var(--color-text-dark-alt);
      font-size: 12px;
      font-weight: 600;
      padding-top: 6px;
      min-height: 23px;
      text-transform: capitalize;

      .name {
        flex: 1;
      }
    }


  }

}