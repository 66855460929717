.modal_big {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(34,36,49,.5);
    z-index: 10;
    overflow: auto;
}

.modal_big.show {
    display: flex;
}

.modal_rule_container {
    min-width: 800px;
    border-radius: 40px;
    max-height: 90%;
}

.top_rule_modal {
    background: var(--popup-top-modal-bg-color);
    padding: 25px 10px 15px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    width: 100%;
}

.top_rule_modal .daily_title {
    font-size: 20px;
}

.body_invite_codered_modal {
    background: var(--popup-body-modal-bg-color);
    min-height: 300px;
    width: 100%;
    padding: 15px 80px 15px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.q_link_invite_box {
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 45px;
    margin: auto;
    align-items: center;
    position: relative;
}

.q_link_invite_left {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.q_link_invite_right {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.q_link_invite_right img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color .2s ease-in-out;
    padding: 6px;
}

.q_link_invite_box {
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 45px;
    margin: auto;
    align-items: center;
    position: relative
}

.q_link_invite_box .q_game_name_not_found,
.q_link_invite_box .q_verified_game_name,
.q_link_invite_box .q_verifying_game_name,
.q_summonner_name_not_found {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 14px;
    color: var(--text-color);
    display: none;
    white-space: nowrap
}

.q_link_invite_box .q_summonner_name_not_found {
    bottom: -21px;
    color: red
}

.q_link_invite_box .q_game_name_not_found {
    bottom: -20px
}

.q_link_invite_box .q_verified_game_name img {
    width: 14px
}

.q_link_invite_box .q_verifying_game_name img {
    width: 14px
}

.q_link_invite_box.verifying .q_verifying_game_name {
    display: block;
    color: #6f7caf
}

.q_link_invite_box.verified .q_verified_game_name {
    display: block
}

.q_link_invite_box.verified .q_verifying_game_name {
    display: none
}

.q_link_invite_box.not_found .q_game_name_not_found {
    color: red;
    display: block
}

#modal_provide_game_name_admin_app .q_modal_title_content {
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
}

.q_modal_title_content, .q_title_pending {
    text-align: center;
    color: #fff;
}

#modal_provide_game_name_admin_app {
    z-index: 10
}

#modal_provide_game_name_admin_app .top_rule_modal .daily_close {
    top: 20px;
    right: 35px;
}

#modal_provide_game_name_admin_app .q_modal_title_content {
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px
}

#modal_provide_game_name_admin_app .q_link_invite_input {
    background: #22242f
}

#modal_provide_game_name_admin_app .q_link_invite_box:first-child {
    margin-top: 25px
}

#modal_provide_game_name_admin_app .q_link_invite_box:last-child {
    margin-bottom: 25px
}

#modal_provide_game_name_admin_app .q_link_invite_box:not(:first-child) {
    margin-top: 40px
}

#modal_provide_game_name_admin_app .label_game_name {
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 14px
}

#modal_provide_game_name_admin_app .label_game_name i {
    font-weight: 100
}

#modal_provide_game_name_admin_app .q_link_invite_right {
    margin-top: 24px
}

#modal_provide_game_name_admin_app .landing_btn {
    background-color: #e97b26
}

#modal_provide_game_name_admin_app .q_link_invite_input {
    color: #fff
}
.q_group_cod {
    display: flex;
}
.q_input_cod {
    width: 65%;
    border-radius: 5px;
}
.q_platform_cod {
    width: 35%;
    background: #22242f;
    color: #ffffff;
    border: none;
    border-radius: 5px;
}

@media (max-width: 800px) {
    .modal_rule_container {
        width: 95%;
        min-width: unset !important;
        zoom: .7;
        -moz-transform: scale(.7);
        -moz-transform-origin: 0 0;
    }
}





