.search_container {
    width: 1000px;
    transition: width 0.5s;
}

.search_container.short_view {
    width: 360px;
}

.search_container.search_webpage {
    width: 750px;
}

.bar {
    display: flex;
    justify-content: flex-end;
    height: 45px;
}

.search_webpage .bar {
    justify-content: flex-start;
}

.search_bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 833px;
    padding: 7.5px 0 7.5px 25px;
    background: #4D4F5D;
    border: 1px solid #898B9C;
    box-sizing: border-box;
}

.search_webpage .search_bar_container {
    width: 360px;
}

.search_input {
    width: 100% !important;
    height: 30px !important;
    padding: 0 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
    background-color: unset !important;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}

.search_input::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    color: #FFFFFF !important;
}

.search_action {
    display: flex;
    justify-content: center;
    width: 45px;
    border-left: 2px solid #898B9C;
}

.search_action img {
    width: 19px;
    height: 19px;
    cursor: pointer;
}

.filter_bar_container {
    width: 167px;
    background: #353748;
    border: 1px solid #898B9C;
    border-right: none;
    box-sizing: border-box;
}

.short_view .filter_bar_container {
    display: none;
}

.filter_dropdown {
    position: relative;
    width: 100%;
}

.filter_dropdown_main {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    border-radius: 2px;
    padding: 0 18px 0 24px;
}

.filter_dropdown_main span {
    pointer-events: none;
    position: relative;
    color: rgba(255, 255, 255, 0.75);
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
}

.filter_dropdown_main img {
    width: 10px;
    height: 7px;
    pointer-events: none;
    position: relative;
    transform: rotate(0deg) translate(0,1px);
    transition: transform .3s ease-in-out;
}

.filter_dropdown.show .filter_dropdown_main img {
    transform: rotate(180deg) translate(0,1px);
}

.filter_dropdown_items {
    position: absolute;
    top: 44px;
    left: 0;
    width: fit-content;
    max-height: 800px;
    background: #1E232F;
    box-shadow: 0 4px 12px rgba(0 0 0 0.59);
    border-radius: 2px;
    overflow: auto;
    transform-origin: top;
    transform: rotateX(90deg);
    transition: transform .3s ease-in-out;
    z-index: 3;
}

.filter_dropdown_items::-webkit-scrollbar {
    width: 4px;
}

.filter_dropdown_items::-webkit-scrollbar-track {
    background-color: #3e404e;
}

.filter_dropdown_items::-webkit-scrollbar-thumb {
    background-color: #8A8A8A;
}

.filter_dropdown.show .filter_dropdown_items {
    transform: rotateX(0deg);
}

.filter_dropdown_item {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 0 10px 0 15px;
    background-color: #1E232F;
    transition: background-color .3s ease-in-out;
}

.filter_dropdown_item.active {
    background-color: #4E4F5E;
}

.filter_dropdown_item.child {
    border-bottom: 1px solid #6A7CB0;
    padding: 0 10px 0 30px;
}

.filter_dropdown_item.child.child_2x {
    border-bottom: 1px solid #6A7CB0;
    padding: 0 5px 0 50px;
}

.filter_dropdown_item:hover {
    background-color: #4E4F5E;
}

.filter_dropdown_item img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.filter_dropdown_item span {
    color: #FFFFFF;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter_check_label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    pointer-events: none;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

.filter_check_label input[type="checkbox"] {
    display: none;
}

.filter_check_label input:checked ~ .filter_checkbox {
    background-color: #EE733A;
}

.filter_check_label input:checked ~ .filter_checkbox:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url('../../assets/search_bar/tick.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
}

.filter_checkbox {
    position: relative;
    pointer-events: all;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 1.30435px;
    background-color: rgba(233, 123, 38, 0);
    border: 2px solid #EE733A;
}

.search_dropdown {
    position: absolute;
    width: 1000px;
}

.results {
    background: #353747;
    overflow: auto;
    max-height: 800px;
}

.results::-webkit-scrollbar {
    width: 6px;
}

.results::-webkit-scrollbar-track {
    background-color: #3e404e;
}

.results::-webkit-scrollbar-thumb {
    background-color: #8A8A8A;
}

.search_notice {
    text-align: center;
    margin: 20px 0;
    color: #FFF;
    font-weight: 600;
}

.more_results {
    background: #1E232F;
    padding: 20px 0;
    border-top: 1px solid rgba(137, 139, 156, 0.5);
}

.btn_view_more_result {
    width: 268px;
    height: 44.74px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(-45deg, transparent 10px, #EE733A 10px);
    cursor: pointer;
}
/* tournament search result */
.search_event {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 183.16px;
    padding: 16px 25px;
    background: #353747;
    border-bottom: 1px solid rgba(137, 139, 156, 0.5);
}

.search_event_left {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    height: fit-content;
    background: #171c29;
}

.search_event_line_up {
    display: flex;
    align-items: center;
}

.search_event_icon {
    position: relative;
    display: flex;
    align-items: center;
    width: 327.58px;
    flex-shrink: 0;
}

.search_event_icon img {
    height: 183.16px;
    object-fit: cover;
}

.search_event_prize_pool {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 20.5621px;
    line-height: 22px;
    text-transform: uppercase;
    color: #303030;
    width: fit-content;
    /*min-width: 116px;*/
    height: 37px;
    padding: 0 10px;
    background: linear-gradient(-45deg, transparent 10px, #EDF0F8 10px);
    filter: drop-shadow(2px 2px 0px rgb(0 0 0 / 25%));
}

.search_event_prize_pool_text {
    display: inline-block;
    color: #303030;
    width: 32px;
    font-size: 9.11707px;
    line-height: 10px;
    font-weight: bold;
    text-align: left;
    margin-left: 5px;
}

.search_event_info {
    width: calc(100% - 327.58px);
    padding: 0 20px;
}

.search_event_info a {
    display: flex;
    width: fit-content;
}

.search_event_title {
    color: #FFFFFF;
    transition: color .2s ease-in-out;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.search_event_desc {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.search_event_about {
    display: flex;
    align-items: center;
}

.search_event_social {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 10px;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    transition: color .2s ease-in-out;
    cursor: pointer;
    width: fit-content;
}

.search_event_social img {
    width: 15px;
    margin-right: 8px;
}

.search_event_right {
    position: relative;
    display: flex;
    background: linear-gradient(-45deg, transparent 10px, #1D1F2C 10px);
}

.search_event_detail {
    width: 208.68px;
    height: 140px;
    padding: 16px 15px;
    border-top: 4px solid #586890;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.search_event_detail.event_required_paid {
    height: 68px;
}

.search_event_action {
    flex-shrink: 0;
}

.search_event_date, .search_event_time, .search_event_type_team {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 19px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
}

.search_event_date svg, .search_event_time svg, .search_event_type_team svg {
    width: 12.55px;
    height: 12.55px;
}

.search_event_date span, .search_event_time span, .search_event_type_team span {
    font-size: 11px;
    line-height: 195.68%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-weight: normal;
    margin-left: 8px;
}

.search_entry_fee {
    background: #252734;
    text-transform: uppercase;
    color: #A0AAC7;
    font-weight: bold;
    font-size: 30px;
    line-height: 21px;
    height: 42.39px;
    width: 100%;
    position: absolute;
    bottom: 42.52px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Titillium Web', sans-serif;
}

.search_entry_fee_text {
    display: block;
    width: 35px;
    font-size: 12px;
    line-height: 13px;
    margin-left: 8px;
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1.32071px;
    font-weight: bold;
}

.landing_btn.search_event_tournament {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    background: #EE733A;
    border-radius: 0;
    font-size: 14px !important;
    line-height: 19px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 42.52px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.landing_btn.search_event_tournament.disabled {
    pointer-events: none;
    background: linear-gradient(-45deg, transparent 5px,grey 5px);
}
/*  end tournament search result */

/** News */
.newsletter_results {
    display: flex;
    padding: 16px 25px;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(137, 139, 156, 0.5);
}
.search_container .news-card,
.search_page .news-card {
    margin: 0 15.45px 30.51px 0;
    width: calc(100%/3 - 15.45px);
    /* box-shadow: 8px 8px 0px #303141; */
}
.search_container .community_event.article,
.search_page .community_event.article{
    width: 100%;
    height: 320px !important;
}
.search_container .community_event.article {
    filter: unset;
}
.search_container .community_event.article .community_event_info,
.search_page .community_event.article .community_event_info{
    min-height: 185px;
}
.search_container .community_event.article .community_event_info {
    background: #222431;
}
.search_container .community_event.article .community_event_info::after {
    content: none;
}
.search_container .community_event_author,
.search_page .community_event_author{
    font-size: 9.96881px;
    line-height: 14px;
}
.search_container .community_event.article .community_event_title.left_event_title,
.search_page .community_event.article .community_event_title.left_event_title{
    font-size: 14.5872px;
    line-height: 19px;
}
.search_container .community_event.article .community_event_title.left_event_title {
    color: #FFFFFF !important;
}
.search_container .community_event.article .community_event_desc_line,
.search_page .community_event.article .community_event_desc_line{
    font-size: 10.206px;
    line-height: 16px;
}
.search_container .community_event.article .community_event_desc_line {
    color: #a4a5a9;
}
.search_container .community_event.article .community_event_bar,
.search_page .community_event.article .community_event_bar{
    width: 100%;
}
.search_container .community_event.article .community_event_bar {
    opacity: 1;
    height: 2px;
    background-color: #282A38;
}
.search_container .community_event.article .community_event_bar_2 {
    display: none;
}
/** End News */

/** Hubs */
.hub_results {
    display: flex;
    padding: 16px 25px;
    flex-wrap: wrap;
}
.search_container .hub-card {
    width: 178px;
    margin: 0 10px 30px 0;
}

.search_container .hub-card .card_wrap {
    filter: unset;
}

.search_container .hub-card .card_image {
    background-color: #1e232f;
}

.search_container .hub-card .card_image img {
    border: 1px solid #555662;
}

.search_container .hub-card .card_properties {
    background-color: #1e232f;
}
/** End Hubs */

/* ---------------------------------------------------------- SEARCH PAGE --------------------------------------------------------------------- */
.search_page {
    color: var(--text-color);
    padding: 120px 208px;
}
.search_header {
    display: flex;
    justify-content: space-between;
}
.query_text_search {
    font-size: 34px;
    line-height: 46px;
    font-weight: bold;
}
.number_search_result {
    font-size: 23.1818px;
    line-height: 32px;
}
.search_sort_by {
    display: flex;
    align-items: center;
}
.search_page .community_dropdown {
    width: 192px;
}
.search_page .community_dropdown_main {
    background-color: unset;
    border: 1px solid #343744;
}
.search_page  .community_dropdown_items {
    top: 45px;
}
.search_page .community_event_bg img {
    display: block;
}
.search_page .community_event_bar {
    width: 100%;
    margin: 30px 0 20px;
    position: unset;
    background-color: rgba(137, 139, 156, 0.5);
}
.search_page .filter_bar_container {
    width: unset;
    background: unset;
    border: unset;
    box-sizing: unset;
}
.search_page .filter_dropdown.show .filter_dropdown_items {
    box-shadow: 6px 8px 0px rgba(0 0 0 0.1);
}
.search_content {
    display: flex;
}
.search_content_body {
    width: 100%;
}
.search_filter_left {
    width: 225px;
    max-height: 850px;
    margin-right: 35px;
    box-shadow: 6px 8px 0px rgb(0 0 0 / 10%);
    background: var(--community-modal-background-color);
}
.search_filter_container {
    padding: 10px 10px 0 15px;
}
.search_page .search_filter_left .community_event_bar {
    margin: 5px 0;
    background-color: rgba(137, 139, 156, 0.5);
}
.search_page .filter_dropdown_items {
    position: unset;
}
.search_page .filter_text {
    font-weight: bold;
}
.search_page .news-card {
    width: 375px;
}
.search_page .results {
    background: unset;
    position: unset;
    width: 100%;
    max-height: unset;
}
.search_page .tournament_results {
    display: flex;
    margin-top: 20px;
    padding: 16px 0;
    flex-wrap: wrap;
    justify-content: unset;
}
.search_page .newsletter_results {
    border-bottom: none;
    justify-content: flex-start;
}
/*.search_page .newsletter_results.has_border,
.search_page .tournament_results.has_border {
    border-bottom: 1px solid rgba(137, 139, 156, 0.5);
}*/
.search_page .community_event {
    display: flex!important;
    width: 216px;
    margin: 0 10px 25px;
}
.search_page .filter_dropdown_item {
    background-color: var(--community-modal-background-color);
}
.search_page .filter_dropdown_item.active {
    background-color: #4E4F5E;
}
.search_page .filter_dropdown_item span {
    color: var(--cr-event-title-color);
}
.search_page .filter_dropdown_item.active span {
    color: #FFFFFF;
}
.search_pagination {
    display: flex;
    justify-content: flex-end;
}
.search_page_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 51px;
    color: #646464;
    background: var(--community-modal-background-color);
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    border: 1px solid #CECECE;
    cursor: pointer;
}
.search_page_item:hover,
.search_page_item.active {
    background: #EE733A;
    color: #ffffff;
}
.prev_item,
.next_item {
    font-weight: 400;
    width: 128px;
}
.prev_item {
    margin-right: 27px;
}
.next_item {
    margin-left: 27px;
}
.prev_item span {
    color: #EE733A;
    font-weight: bold;
    margin-right: 5px;
}
.next_item span {
    color: #EE733A;
    font-weight: bold;
    margin-left: 5px;
}
.search_page_item.disabled {
    pointer-events: none;
    background-color: #cecece!important;
}
.search_page .search_notice {
    color: var(--text-color);
    font-size: 32px;
}
.search_page .newsletter_results {
    justify-content: flex-start;
    padding: 16px 10px;
}
.search_page .hub_results {
    justify-content: flex-start;
    padding: 16px 10px;
}
.search_page .hub-card {
    margin-left: 0;
    margin-right: 43px;
}
.search_page .hub-card:last-child {
    margin-right: 0;
}
.filter_section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.filter_tag_names {
    background: #586890;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    border-radius: 2px;
    margin-right: 10px;
    text-transform: initial;
    padding: 5px 10px;
}
.filter_tag {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.clear_filter {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;
    color: #EE733A;
    font-weight: bold;
    cursor: pointer;
}
.clear_filter:hover {
    color: #d16e22;
}
.close_filter {
    margin-left: 10px;
}

@media (max-width: 1820px) {
    .search_container {
        zoom: 0.9;
    }
}

@media (max-width: 1720px) {
    .search_container {
        zoom: 0.8;
    }
    .search_page {
        padding: 120px 0;
        width: 82%;
        margin: 0 auto;
    }
    .search_page .hub-card {
        margin-right: 30px;
    }
}

@media (max-width: 1600px) {
    .search_container {
        zoom: 0.7;
    }
}

@media (max-width: 1480px) {
    .search_container {
        width: 900px;
        zoom: 0.7;
    }
    .search_dropdown {
        width: 900px;
    }
    .search_event_icon {
        width: 276px;
    }
}

@media (max-width: 1440px) {
    .search_page {
        padding: 120px 0;
        width: 90%;
        margin: 0 auto;
    }
    .search_container {
        width: 880px;
        zoom: 0.65;
    }
    .search_dropdown {
        width: 880px;
    }
}

@media (max-width: 1344px) {
    #search_bar {
        display: block;
    }
}
@media (max-width: 550px) {
    #search_bar {
        display: none !important;
    }
}
@media (-webkit-min-device-pixel-ratio: 1.09) {
    .search_container {
        zoom: unset;
        width: 900px;
    }
    .search_dropdown {
        zoom: 0.9;
    }
    @-moz-document url-prefix() {
        .search_container {
            width: 750px;
        }
        .search_dropdown {
            -moz-transform: scale(0.75);
            -moz-transform-origin: left top;
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
    .search_container {
        width: 680px;
        zoom: unset;
    }
    .search_dropdown {
        zoom: 0.755;
    }
    @-moz-document url-prefix() {
        .search_container {
            width: 600px;
        }
        .search_dropdown {
            -moz-transform: scale(0.684);
            -moz-transform-origin: left top;
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
    #search_bar {
        display: block;
    }
    .search_container {
        zoom: unset;
        width: 450px !important;
    }
    .search_dropdown {
        zoom: 0.511;
    }
    @-moz-document url-prefix() {
        .search_dropdown {
            -moz-transform: scale(0.511);
            -moz-transform-origin: left top;
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 1.75) {
    #search_bar {
        display: block;
    }
    .search_container {
        zoom: unset;
        width: 330px !important;
    }
    .search_dropdown {
        zoom: 0.375;
    }
    .search_container.short_view {
        width: 330px;
    }
    .search_input::placeholder {
        font-size: 10px !important;
    }
}