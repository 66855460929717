.ellipsis-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.flex-fill {
  flex: 1;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.arrow-caret {
  --arrow-width: 12px;
  --arrow-real-width: calc(var(--arrow-width) / 2);
  --arrow-height: 9px;
  --arrow-color: var(--color-border);

  position: relative;
  width: 0;
  height: 0;
  margin-right: 3px;
  border-style: solid;
  border-color: transparent;

  &.up {
    display: inline-block;
    border-bottom-color: var(--arrow-color);
    border-width: 0 var(--arrow-real-width) var(--arrow-height) var(--arrow-real-width);
  }
  &.down {
    display: inline-block;
    border-top-color: var(--arrow-color);
    border-width: var(--arrow-height) var(--arrow-real-width) 0 var(--arrow-real-width);
  }
}

.arrow-progress {
  font-size: 12px;
  font-weight: bold;

  .arrow-caret {
    display: none;
    border-radius: 2px;
  }

  &.positive {
    color: var(--color-positive);
    .arrow-caret {
      @extend .up;
      --arrow-color: var(--color-positive);
    }
  }
  &.negative {
    color: var(--color-negative);
    .arrow-caret {
      @extend .down;
      --arrow-color: var(--color-negative);
    }
  }
}

[scrollbar] {
  --scrollbar-color: var(--color-text-dark-alt);
  --scrollbar-background: var(--color-border);
  --scrollbar-background-hover: #666666;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary);
  }
  &::-webkit-scrollbar-thumb:active {
    background: var(--color-primary);
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track:hover {
    background: var(--scrollbar-background-hover);
  }
  &::-webkit-scrollbar-track:active {
    background: var(--scrollbar-background-hover);
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &[scrollbar='primary'] {
    --scrollbar-color: var(--color-primary);
  }
}