.statistics_game_leaderboard {
  display: flex;
  flex-direction: column;

  .leaderboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 20px;

    .leaderboard-title {
      font-weight: bold;
      font-size: 16px;
    }

    .leaderboard-filters {
      display: flex;
      grid-gap: 20px;
    }
    .dropdown-simple {
      width: 200px;
    }
  }

  .failed-data {
    margin-top: 20px;
  }

  .featured-players {
    display: flex;
    align-items: center;
    /* grid-gap: 27px; */
    margin-top: 20px;
    margin-bottom: 16px;
    padding-top: 20px;
    border-top: 1px solid var(--color-border);

    .player_featured_statistics {
      flex: 1;
    }
  }

  .leaderboard-empty-data {
    margin-top: 20px;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
  }

  .leaderboard-players {
    $scrollbar-padding: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 60vh;
    // margin-right: -$scrollbar-padding;
    padding-right: calc(#{$scrollbar-padding} - var(--global-scrollbar-size));
  }

  .player {
    display: flex;
    align-items: center;
    background-color: var(--color-dark-alt);
    grid-gap: 10px;
    padding-left: 38px;
    padding-right: 16px;
    height: 60px;
    border-radius: 5px;
    font-size: 14px;
    flex-shrink: 0;

    &.current-user {
      margin-top: 20px;
      background: linear-gradient(180deg, var(--color-primary) 0%, rgba(var(--color-primary-rgb)) 100%);

      &, .player-points {
        color: #ffffff;
      }
    }

    .player-position {
      display: flex;
      align-items: center;
      font-weight: bold;
      flex: 1;

      .arrow-progress {
        margin-left: 10px;
        font-size: 12px;
      }
    }
    .player-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: 1.5px solid rgba(white, .10);
      width: 34px;
      height: 34px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        -o-object-fit: contain;
            object-fit: contain;
      }
    }
    .player-info {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      flex: 2;

      .player-avatar {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        -o-object-fit: cover;
           object-fit: cover;
      }
      .player-name {
        font-size: 16px;
      }
    }
    .player-points {
      flex: 4;
      color: var(--color-text-dark-alt);
    }
  }
}

@media only screen and (max-width: 576px) {
  .statistics_game_leaderboard {
    .leaderboard-header {
      flex-wrap: wrap;

      & > span {
        margin-bottom: 10px;
      }

      .leaderboard-filters {
        flex-wrap: wrap;
        justify-content: center;

        .dropdown-simple:not(:last-child) {
          width: 135px;
          margin-bottom: 10px;
        }
      }
    }

    .featured-players {
      display: block;

      .player_featured_statistics {
        margin: 0 0 10px;
      }
    }
  }
}