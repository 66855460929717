.statistics_game_list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;

  grid-gap: 12px;
  width: 100%;
  padding-right: 19px;
  border-right: 1px solid #343744;

  .game {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 0.9fr;
    grid-gap: 15px;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 0 17px;
    transition: border-color 200ms ease-in-out;

    &.connected .logo {
      grid-column: span 2;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-secondary);
      opacity: .2;
    }
    &.active {
      border-color: var(--color-primary);
    }

    .cover {
      position: absolute;
      -o-object-fit: cover;
          object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .logo {
      z-index: 1;
      max-width: 120px;
      height: auto;
      justify-self: center;
    }
  }

  .connect-game .button-simple {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 576px) {
  .statistics_game_list {
    padding-right: 0;
    border-right: unset;
    margin-bottom: 20px;
  }
}