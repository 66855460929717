.admin_dropdown {
    position: relative;
    width: 100%
}

.admin_dropdowns {
    display: flex;
    align-items: center;
    margin: 0 -10px 10px;
}

.admin_dropdown_main {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    border-radius: 2px;
    padding: 0 18px;
    border: 2px solid var(--admin-dropdown-main-border-color);
    color: var(--text-color);
    opacity: 0.75;
    font-size: 13px;
    font-weight: 500
}

.admin_dropdown_main span {
    pointer-events: none;
    position: relative
}

.admin_dropdown_main input {
    width: 100%;
    padding: 0;
    border: none;
    outline: 0;
    color: rgba(255, 255, 255, .75);
    font-size: 13px;
    font-weight: 500
}

.admin_dropdown_main input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main input:-ms-input-placeholder {
    color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main input::placeholder {
    color: rgba(255, 255, 255, .75)
}

.admin_dropdown_main img {
    pointer-events: none;
    position: relative;
    width: initial;
    margin-left: 10px;
    transform: rotate(0) translate(0, 1px);
    transition: transform .3s ease-in-out
}

.admin_dropdown_main img:first-child {
    pointer-events: none;
    position: relative;
    width: initial;
    margin-right: 4px
}

.admin_dropdown_main img:last-child {
    pointer-events: none;
    position: relative;
    width: initial;
    height: initial;
    margin-left: 10px;
    transform: rotate(0) translate(0, 1px);
    transition: transform .3s ease-in-out
}

.admin_dropdown.show .admin_dropdown_main img:last-child {
    transform: rotate(180deg) translate(0, 1px)
}

.admin_dropdown.show .admin_dropdown_items {
    transform: rotateX(0)
}

.admin_dropdown_items {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    max-height: 155px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background-color: var(--admin-dropdown-items-bg-color);
    overflow: auto;
    transform-origin: top;
    transform: rotateX(90deg);
    transition: transform .3s ease-in-out;
    z-index: 2
}

.admin_dropdown_items::-webkit-scrollbar {
    width: 3px;
}
.admin_dropdown_items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 50px #CECECE;
    border-radius: 2px;
}
.admin_dropdown_items::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 50px #24242459;
}

.admin_dropdown_main.game_type.changed~.admin_dropdown_items .game_type_add {
    display: block
}

.game_type_add {
    cursor: pointer;
    display: none;
    position: relative;
    padding: 15px 20px;
    border-radius: 2px;
    border-bottom: 1px solid rgba(137, 139, 156, .3)
}

.game_type_add_info {
    color: rgba(255, 255, 255, .75);
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
    margin: 0 0 4px
}

.game_type_value {
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    margin: 0
}

.admin_dropdown_item {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0 20px;
    background-color: var(--admin-dropdown-item-bg-color);
    color: var(--text-color);
    font-size: 13px;
    font-weight: 500;
    opacity: var(--admin-dropdown-item-opacity);
    transition: background-color .3s ease-in-out
}

.admin_dropdown_item.taller {
    height: unset;
    padding: 5px 20px;
    color: rgba(255, 255, 255, .85)
}

.admin_dropdown_item .subtext {
    font-size: 12px;
    font-style: italic;
    color: #999
}

.admin_dropdown_item.disabled .admin_dropdown_item .subtext {
    color: rgba(255, 255, 255, .2)
}

.admin_dropdown_item.new {
    justify-content: space-between
}

.admin_dropdown_item.new .admin_dropdown_item_delete {
    display: flex
}

.admin_dropdown_item_delete {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25px;
    margin-left: 5px;
    margin-right: -5px;
    padding: 5px
}

.admin_dropdown_main .subtext {
    display: none
}

.admin_dropdown_item.disabled {
    color: rgba(255, 255, 255, .3);
    pointer-events: none
}

.admin_dropdown_item:hover {
    background-color: var(--drops-dropdown-item-hover-color)
}

.admin_dropdown_item span {
    position: relative
}

.admin_dropdown_item img {
    width: initial;
}

.admin_dropdown_item img:first-child {
    margin-right: 4px;
}
