.monthly_statistics {
  // NOTE The $monthly-max-performance should be the maximum performance we can achieve in the day avgPerformance
  // In this case 5, and we create 6 colors for it to include 0
  $monthly-max-performance: 4;
  --monthly-color-performance-null: #1E232F;
  --monthly-color-performance-0: #D34445;
  --monthly-color-performance-1: #E97B26;
  --monthly-color-performance-2: #DBD543;
  --monthly-color-performance-3: #8BA85C;
  --monthly-color-performance-4: #5CA868;

  .statistics-header {
    .monthly-date {
      color: var(--color-text-light-alt);
      font-size: 12px;
      font-weight: 600;
    }
  }

  .monthly-container {
    margin-top: 20px;
  }

  .monthly-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px 6px;

    .day-name {
      color: var(--color-text-light-alt);
      text-align: center;
      font-size: 12px;
      font-weight: 600;
    }
    .day {
      background-color: var(--monthly-color-performance-null);
      width: 100%;
      height: 100%;
      border-radius: 5px;
      height: 8px;
      position: relative;
      cursor: pointer;
      &:hover .tooltip-simple {
        display: block;
      }
      // NOTE This just creates [performance='0'] { background-color: ... } in a loop with $monthly-max-performance
      @for $i from 0 through $monthly-max-performance {
        &[performance='#{$i}'] {
          background-color: var(--monthly-color-performance-#{$i});
        }
      }
    }

    .tooltip-simple {
      white-space: nowrap;

      .tooltip-date {
        color: var(--color-secondary);
      }
      .stats-container {
        span {
          padding-left: 3px;
          &:first-child {
            padding-left: 0;
          }
        }
        strong {
          color: var(--color-secondary);
        }
      }
    }
  }

  .monthly-performance {
    display: flex;
    align-items: center;
    margin-top: 19px;

    .performance-bar {
      --bar-radius: 5px;

      display: flex;
      align-items: center;
      border-radius: 5px;
      border: 3px solid var(--color-dark-alt);
      border-right-width: 6px;
      border-left-width: 6px;
      background-color: var(--color-dark-alt);
      flex: 1;
      position: relative;

      .bar-background {
        height: 6px;
        border-radius: var(--bar-radius);
        background: linear-gradient(270deg, var(--monthly-color-performance-4) 0%,
                                            var(--monthly-color-performance-3) 29.1%,
                                            var(--monthly-color-performance-2) 49.59%,
                                            var(--monthly-color-performance-1) 70.9%,
                                            var(--monthly-color-performance-0) 100%);
        flex: 1;
      }
      .bar-cursor {
        position: absolute;
        border: 2px solid #171C29;
        background-color: var(--monthly-color-performance-0);
        height: 14px;
        width: 2px;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: calc(var(--bar-radius) * -1);
        box-sizing: content-box;
        transition: left 600ms ease-in-out;
      }
    }

    .performance-desc {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-text-light-alt);
      margin-left: 11px;
    }
  }

  .monthly-hours {
    margin-top: 21px;

    .hours-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px;
      background-color: var(--color-dark-alt);
      border-radius: 5px;
      margin-top: 4px;
      &:first-child {
        margin-top: 0;
      }

      span {
        font-size: 12px;
      }
      strong {
        color: var(--color-secondary);
        font-size: 11px;
        font-weight: bold;

        .positive {
          color: var(--color-positive);
        }
        .negative {
          color: var(--color-negative);
        }
      }
    }
  }
}