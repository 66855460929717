.button-simple {
  padding: 10px 13px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0;
  z-index: 1;
  box-shadow: none;
  color: var(--color-text-light);
  white-space: nowrap;
  flex: 1;
  cursor: pointer;
  border: 1px solid transparent;
  transition: -webkit-filter var(--global-animation-short);
  transition: filter var(--global-animation-short);
  transition: filter var(--global-animation-short), -webkit-filter var(--global-animation-short);

  &:hover {
    -webkit-filter: brightness(115%);
            filter: brightness(115%);
  }

  &[color='primary'] {
    background: var(--color-primary);
    color: #ffffff;
  }
  &[color='dark'] {
    background: var(--color-dark-alt);
    border-color: var(--color-border);
  }
  &[color='border'] {
    background: var(--color-border);
  }
}