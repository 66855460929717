.queue_statistics {
  display: flex;
  flex-direction: column;

  .failed-data {
    margin-top: 8px;
  }

  .queue-container {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    &:first-child {
      margin-top: 0;
    }

    .queue {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      border-radius: 5px;
      overflow: hidden;

      &.disabled {
        opacity: .3;
      }
    }

  }
}

.queue_statistics .queue-container .queue .queue-main {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  color: #ffffff;
  &.pointer {
    cursor: pointer;
  }

  .queue-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 86px;
    padding: 15px;
    background-color: var(--color-dark-queue-icon);
    border-radius: 5px;
    margin-right: 17px;

    img {
      width: 32px;
      height: 32px;
      -o-object-fit: contain;
          object-fit: contain;
    }
    span {
      text-transform: uppercase;
      color: var(--color-text-item-stat);
      margin-top: 7px;
      font-size: 12px;
      font-weight: bold;
    }
    z-index: 1;
  }

  .queue-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    .queue-games {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }

    .queue-stats {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 9px;

      .stat {
        display: flex;
        flex-direction: column;

        strong {
          font-size: 18px;
          line-height: 25px;

          .compare-data {
            font-size: 12px;
          }

          .positive {
            color: var(--color-positive);
          }
          .negative {
            color: var(--color-negative);
          }
          .inverted.positive {
            color: var(--color-negative);
          }
          .inverted.negative {
            color: var(--color-positive);
          }
        }
        span {
          color: var(--color-text-item-stat);
          font-size: 12px;
          font-weight: bold;
          line-height: 20px;
          text-transform: uppercase;
        }
      }
    }
    z-index: 1;
  }

  .queue-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
        object-fit: cover;
    z-index: 0;
  }
}

.queue_statistics .queue-container .queue .queue-expanded-view {
  $expanded-view-padding: 20px;
  padding: 0 $expanded-view-padding;
  background-color: var(--color-dark-alt);
  max-height: 500px;
  transition: max-height var(--global-animation-middle);
  // NOTE Some hardware acceleration
  will-change: max-height;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;

  &.expand-enter, &.expand-leave-active {
    max-height: 0;
  }

  .expanded-title {
    color: var(--color-text-light-alt);
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    margin-top: $expanded-view-padding;
  }

  .expanded-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    max-height: 380px;
    margin-bottom: $expanded-view-padding;
    overflow: hidden;
    overflow-y: auto;
    // NOTE padding and negative margin for scrollbar space
    padding-right: calc(10px - var(--global-scrollbar-size));
    margin-right: -10px;

    .item {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      flex-shrink: 0;
      -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

      &:not(:first-child) {
        margin-top: 4px;
      }

      .item-info {
        display: grid;
        align-items: center;
        grid-template-columns: 39px 75px 1fr auto 30px;
        grid-gap: 5px;
        padding: 15px 20px;
        background: var(--color-dark-light);
        border-radius: 5px;

        .item-avatar {
          width: 26px;
          height: 26px;
          border-radius: 2px;
          -o-object-fit: contain;
            object-fit: contain;
        }
        .item-name {
          color: var(--color-text-light-alt);
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .item-main-stat {
          color: var(--color-text-light);
          font-size: 16px;
          white-space: nowrap;
        }
        .item-secondary-stat {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          grid-gap: 5px;
          color: var(--color-secondary);
          font-size: 11px;
          font-weight: bold;
          text-align: right;
          margin-right: 0;
          margin-left: auto;

          .stat {
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 26px;
              height: 26px;
              -o-object-fit: contain;
                 object-fit: contain;
            }
          }
        }
        .item-expand-button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 100%;
          img {
            width: 10px;
            height: auto;
          }
        }
      }

      .item-expanded-stats {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 3px;
        border-top: 1px solid var(--color-border);
        padding: 15px 20px;
        padding-top: 11px;
        background: var(--color-dark-light);

        .stat {
          display: flex;
          align-items: center;
          background-color: var(--color-dark-alt);
          padding: 10px;
          border-radius: 5px;

          .stat-icon {
            width: 20px;
            height: auto;
            margin-right: 11px;
          }
          .stat-name {
            font-size: 12px;
            font-weight: bold;
            line-height: 20px;
            text-transform: uppercase;
            color: var(--color-text-item-stat);
          }
          .stat-value {
            font-size: 14px;
            text-align: right;
            margin-right: 0;
            margin-left: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .queue_statistics .queue-container .queue .queue-expanded-view {
    .expanded-list-container {
      .item {
        .item-info {
          grid-template-columns: 39px 75px 1fr 30px;
          
          .item-secondary-stat {
            display: none;
          }
        }

        .item-expanded-stats {
          grid-template-columns: unset;
        }
      }
    }
  }
}