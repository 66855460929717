.statistics_game_compare {
  $compare-grid-gap: 34px;
  display: grid;
  grid-template-columns: calc(50% - #{$compare-grid-gap}) auto calc(50% - #{$compare-grid-gap});
  grid-gap: $compare-grid-gap;

  .v-separator {
    width: 1px;
    height: 100%;
    background: var(--color-border);
  }

  .player {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    max-width: 100%;
    // NOTE Overflow hidden will hide some parts of the tooltips
    // overflow: hidden;
  }

}

@media only screen and (max-width: 576px) {
  .statistics_game_compare {
    overflow-x: auto;
    display: flex;

    .player {
      width: 100%;

      &.compared {
        margin-left: 20px;
        min-width: 100%;
      }

      & > div:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}