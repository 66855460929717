
.landing_btn {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 45px;
    border-radius: 2px;
    background-color: #EE733A;
    color: #ffffff;
    font-family: Open Sans,sans-serif;
    font-size: 18px;
    font-weight: bold;
    border: none;
}
.landing_btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: -130%;
    width: 120%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    transition: transform .2s ease-in-out;
    transform: translate(0,0%) skewX(-25deg);
}
.landing_btn:hover:before {
    transform: translate(100%, 0) skewX(-25deg);
}
.faction_war_page {
    background-color: #1d202d;
}
.faction_war_page nav {
    height: 77px;
    border-bottom: 2px solid #252936;
}
.nav_host {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}
.nav_host span {
    font-style: italic;
}
nav.logged .nav_host {
    display: none;
}
nav.logged .nav_admin {
    display: flex;
}
/* Text */
.fw_title {
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}
.fw_subtitle {
    color: #dbdbdb;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}
.fw_subtitle span {
    color: #ffffff;
    font-weight: 600;
}
.fw_body {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 600;
    margin: 0;
}
.fw_subbody {
    color: rgba(255,255,255,0.75);
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
}
.fw_small {
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
}
.italic {
    font-style: italic;
}
/* Header */
.fw_header {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 77px;
    width: 100%;
    padding: 30px 20px;
    min-height: 591px;
    background: url(../../assets/v4/faction_wars/faction-bg.png) no-repeat #1d202d;
    background-size: cover;
    background-position: 50% 50%;
}
.fw_header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 720px;
    background-image: linear-gradient(270deg, rgba(29, 32, 45, 0) 0%, rgba(29, 32, 45, 0.55) 100%);
}
.fw_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
}
.fw_about {
    width: 100%;
    margin: 0 10px;
    max-width: 485px;
    text-align: left;
}
.fw_about_present {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
}
.fw_about_title {
    color: #ffffff;
    font-size: 34px;
    font-weight: 400;
    margin: 0 0 15px;
}
.fw_about_body {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 20px;
}
.fw_about_subbody {
    color: rgba(255,255,255,0.75);
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    font-style: italic;
    margin: 0 0 35px;
}
.fw_about_upcoming {
    width: 100%;
    max-width: 240px;
    border-radius: 3px;
    border: 2px solid rgba(249, 249, 249, 0.2);
    background-color: rgba(255, 255, 255, 0.05);
    margin: 0 0 30px;
}
.fw_about_upcoming_info {
    display: flex;
    align-items: center;
    padding: 5px 0;
    height: 65px;
}
.fw_about_upcoming_date {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 65px;
    border-radius: 1px;
    border-right: 2px solid rgba(249, 249, 249, 0.2);
    text-align: center;
    margin-right: 20px;
}
.fw_about_upcoming_date_num {
    color: #f9f9f9;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin: 0 0 -5px;
}
.fw_about_upcoming_date_body {
    color: #f9f9f9;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
    margin: 0;
}
.fw_about_upcoming_date_text {
    color: #f9f9f9;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    padding-right: 10px;
    margin: 0;
}
.fw_about_upcoming_btn {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 10px;
    margin: 0 -2px -2px;
    border-radius: 3px;
    background-color: #e5eaff;
    color: #1d222f;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}
.fw_about_upcoming_btn span {
    position: relative;
}
.fw_about_upcoming_btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: rgba(0,0,0,0);
    transition: background-color .3s ease-in-out;
}
.fw_about_upcoming_btn:hover:before {
    background-color: rgba(0,0,0,0.1);
}
.fw_about_twitter {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
    height: 48px;
    padding: 6px;
    border-radius: 2px;
    border: 2px solid #1da1f2;
    background-color: rgba(29, 161, 242, 0.52);
}
.fw_about_twitter_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 2px;
    background-color: #48b9ff;
    flex-shrink: 0;
    margin-right: 10px;
}
.fw_about_twitter_icon img {
    width: initial;
}
.fw_about_twitter_info {
    color: #f9f9f9;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
    margin: 0;
}
/* Actions */
.fw_action {
    width: 100%;
    max-width: 460px;
    border-radius: 3px;
    background-color: rgba(29, 32, 45, 0.9);
    padding: 5px 5px 0;
    margin: 0 10px;
}
.fw_action_bar {
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.16);
    overflow: hidden;
}
.fw_action_fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    transform: translate(-100%,0);
    transition: transform .3s ease-in-out;
}
.fw_action.s1 .fw_action_fill {
    transform: translate(-66.67%,0);
}
.fw_action.s2 .fw_action_fill {
    transform: translate(-33.33%,0);
}
.fw_action.s3 .fw_action_fill {
    transform: translate(-0%,0);
}
.fw_action.s4 .fw_action_fill {
    transform: translate(-0%,0);
}
.fw_action_content {
    margin: 0 -5px;
    display: none;
}
.fw_action.s1 .fw_action_content.s1 {
    display: block;
}
.fw_action.s2 .fw_action_content.s2 {
    display: block;
}
.fw_action.s3 .fw_action_content.s3 {
    display: block;
}
.fw_action.s4 .fw_action_content.s4 {
    display: block;
}
.fw_action_item {
    text-align: center;
    padding: 30px 50px;
    width: 100%;
    border-bottom: 2px solid rgba(255,255,255,0.08)
}
.fw_action_item.tl {
    text-align: left;
}
.fw_action.s1 .fw_action_item:last-child {
    border-bottom: none;
}
.fw_action_item .fw_small {
    margin-bottom: 5px;
}
.fw_action.s1 .fw_action_item .fw_title {
    margin-bottom: 15px;
}
.fw_action.s1 .fw_action_item .fw_body {
    margin-bottom: 5px;
    color: #ffffff;
}
.fw_action.s2 .fw_action_item .fw_subtitle {
    margin-bottom: 25px;
}
.fw_calendar {
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    border-radius: 2px;
    border: 2px solid rgba(255,255,255,0.2);
    margin: 0 0 25px;
}
.fw_calendar_date {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}
.fw_calendar_date input {
    width: 225px;
    color: #f9f9f9;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}
::-webkit-datetime-edit { padding: 0; }
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { background: none; }
.fw_calendar_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 28px;
    border-radius: 1px;
    border-left: 2px solid rgba(255,255,255,0.2);
    flex-shrink: 0;
    cursor: pointer;
}
.fw_calendar_icon img {
    width: initial;
}
.fw_btn.disable {
    opacity: 0.5;
    pointer-events: none;
}
.fw_btn {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 2px;
    padding: 0 10px;
    margin: 0 0 20px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}
.fw_btn span {
    position: relative;
}
.fw_btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: rgba(0,0,0,0);
    transition: background-color .3s ease-in-out;
}
.fw_btn:hover:before {
    background-color: rgba(0,0,0,0.1);
}
.fw_link {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    text-align: center;
    display: block;
    margin: 0 auto;
    color: #c7c8cb;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
}
/* Dropdown */
.fw_action.s2 .faction_dropdown {
    margin: 0 0 20px;
}
.faction_dropdown {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    text-align: left;
    z-index: 1;
}
.faction_dropdown.show .faction_dropdown_main img {
    transform: rotate(180deg);
}
.faction_dropdown.show .faction_dropdown_items {
    transform: rotateX(0deg);
}
.faction_dropdown_main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    border-radius: 2px;
    border: 2px solid rgba(255,255,255,0.2);
    color: #f9f9f9;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}
.faction_dropdown_main span {
    pointer-events: none;
    width: 100%;
}
.faction_dropdown_main img {
    pointer-events: none;
    width: initial;
    margin-left: 10px;
    flex-shrink: 0;
    transform: rotate(0deg);
    transition: transform .3s ease-in-out;
}
.faction_dropdown_items {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: rotateX(90deg);
    transform-origin: top;
    transition: transform .3s ease-in-out;
}
.faction_dropdown_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    border-radius: 2px;
    border: 2px solid rgba(255,255,255,0.2);
    color: #f9f9f9;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgba(29, 32, 45, 0.9);
    transition: background-color .3s ease-in-out;
}
.faction_dropdown_item:hover {
    background-color: rgba(29, 32, 45, 0.8);
}
/* More */
.fw_more {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    max-width: 1200px;
    padding: 0 30px;
    transform: translate(-50%,0);
}
.fw_more img {
    width: initial;
    margin-right: 10px;
    flex-shrink: 0;
}
/* Url */
.fw_action.s3 .fw_subtitle {
    margin-bottom: 20px;
}
.fw_action_urlholder {
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 7px 0 20px;
    height: 45px;
    border-radius: 2px;
    border: 2px solid rgba(255,255,255,0.2);
    margin: 0 0 15px;
}
.fw_action_url {
    color: #f9f9f9;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    width: 100%;
}
.fw_action_url_copy {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 31px;
    border-radius: 1px;
    background-color: #4c4f5a;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    margin-left: 10px;
    flex-shrink: 0;
}
.fw_action_url_copy:hover {
    opacity: 0.8;
}
.fw_action_url_copy img {
    width: initial;
}
/* Email */
.fw_action.s4 .fw_subtitle {
    margin-bottom: 20px;
}
.fw_action.s4 .fw_body {
    text-align: left;
    margin-bottom: 5px;
}
.fw_email {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 20px;
    border-radius: 2px;
    border: 2px solid var(--sub-tourneys-border-input-color);
    margin: 0 0 25px;
}
.fw_email input {
    color: var(--input-text-color);
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    padding: 0;
}
.fw_email input::placeholder {
    color: var(--sub-tourneys-border-input-color);
}
/* Info */
.fw_info {
    width: 100%;
    max-width: 1200px;
    margin: 85px auto 55px;
    padding: 0 30px;
}
.fw_info_title {
    color: var(--text-color);
    font-size: 34px;
    font-weight: 400;
    line-height: 46px;
    margin: 0 0 15px;
}
.fw_info_body {
    color: var(--sub-tourneys-info-content-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 30px;
}
.fw_info_subtitle {
    color: var(--text-color);
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 15px;
}
.fw_info_cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    border-radius: 2px;
    border: 2px solid var(--sub-tourneys-info-card-border-color);
}
.fw_info_card {
    width: calc(100%/3 - 20px);
    height: 220px;
    padding: 50px 50px 20px;
    margin: 10px;
    text-align: center;
    border-radius: 2px;
    background-image: linear-gradient(180deg, rgba(55, 59, 75, 0.34) 0%, rgba(38, 42, 57, 0.34) 100%);
    background: var(--sub-tourneys-info-card-background-color);
}
.fw_info_card_num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: #d6782f;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto 25px;
}
.fw_info_card_about {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin: 0;
}
.fw_rules {
    width: 100%;
    max-width: 1200px;
    padding: 0 30px 60px;
    margin: 0 auto;
}
.fw_rules_title {
    color: var(--text-color);
    font-size: 34px;
    font-weight: 400;
    margin: 0 0 20px;
}
.fw_rules_body {
    color: var(--sub-tourneys-info-content-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
}
/* Responsive */
@media all and (max-width: 1000px) {
    .fw_info_cards {
        flex-flow: row wrap;
    }
    .fw_info_card {
        width: calc(100%/2 - 20px);
    }
}
@media all and (max-width: 860px) {
    .fw_container {
        flex-flow:  row wrap;
        justify-content: center;
        padding: 30px 0 70px;
    }
    .fw_about {
        text-align: center;
    }
    .fw_about_upcoming {
        margin: 0 auto 30px;
    }
    .fw_about_twitter {
        margin: 0 auto 30px;
    }
    .fw_more {
        justify-content: center;
        padding: 0 20px;
    }
}
@media all and (max-width: 710px) {
    .fw_info_card {
        width: calc(100% - 20px);
    }
}
@media all and (max-width: 520px) {
    .fw_action_item {
        padding: 20px;
    }
    .fw_info {
        padding: 0 20px;
    }
    .fw_info_cards {
        padding: 5px;
    }
    .fw_info_card {
        width: 100%;
        margin: 5px;
        padding: 50px 30px 30px;
    }
    .fw_about {
        margin: 0;
    }
    .fw_action {
        margin: 0;
    }
}
@media all and (max-width: 400px) {
    .fw_about_twitter {
        min-height: 48px;
        height: initial;
    }
}
@media all and (max-width: 368px) {
    .fw_calendar_icon {
        display: none;
    }
}

/** Custom */
.fw_header {
    display: block;
}
.fw_header_title {
    display: block;
    text-align: center;
    margin-bottom: 40px;
    color: white;
}
.fw_about_title {
    margin: 0 0 15px;
}
.fw_about_present {
    line-height: 10px;
}
.fw_container {
    align-items: flex-start;
}
.fw_gifted {
    color: #ea8230;
}
.fw_description_title {
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
    font-size: 19px;
}
.fw_action.s2 .fw_action_fill {
    transform: translate(-33.33%,0);
}
.fw_action.s3 .fw_action_fill {
    transform: translate(0%,0);
}
.fw_next_step {
    text-transform: capitalize;
    font-size: 15px;
    font-weight: bold;
}
.fw_action.s5 .fw_action_content.s5 {
    display: block;
}
.fw_action.s5 .fw_action_fill, .fw_action.s5 .fw_action_fill {
    transform: translate(0%,0);
}
.waiting_ajax{
    pointer-events: none;
    opacity: 0.7;
}