/* highschool landing */
.highschool_landing_main {
    position: relative;
    margin-top: 60px;
}

.highschool_landing_top {
    background: url(../../assets/v4/highschool_landing/highschool_landing_top_v4.png) no-repeat;
    background-size: cover;
    background-position-x: center;
}

.highschool_landing_top .title {
    width: 900px;
    margin: 0 auto;
    text-align: center;
    padding: 375px 0 0;
    font-weight: 400;
    font-size: 32px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.highschool_landing_top .button_container {
    display: flex;
    justify-content: space-around;
    width: 900px;
    margin: 50px auto;
}

.highschool_landing_top .highschool_landing_btn {
    position: relative;
    background: #E89900;
    padding: 5px 40px;
    cursor: pointer;
    border-radius: 7px;
}

.highschool_landing_top .highschool_landing_btn::before {
    content: 'FOR STUDENTS';
    width: 130px;
    height: 24px;
    position: absolute;
    top: -24px;
    left: 10px;
    padding: 2px;
    background-color: #C18309;
    color: #FDFDFD;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px 7px 0 0;
}

.highschool_landing_top .highschool_landing_btn.more {
    background: #FFD130;
    padding: 5px 30px;
}

.highschool_landing_top .highschool_landing_btn.more::before {
    content: 'FOR FACULTY';
    left: 5px;
    background-color: #B79622;
}

.highschool_landing_top .highschool_landing_btn span {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.highschool_landing_top .highschool_landing_btn.more span {
    color: #000;
}

.highschool_landing_top .sub_title {
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}

.highschool_landing_top .partner_container {
    width: 1000px;
    margin: 20px auto 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.highschool_landing_top .highschool_partner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    margin-bottom: 10px;
}
.highschool_partner .highschool_partner_img{
    width: auto;
}

/*.highschool_landing_top .highschool_partner img {*/
/*    width: 75px;*/
/*    height: 75px;*/
/*}*/

.highschool_landing_top .more_info {
    display: flex;
    align-items: center;
    padding: 30px 150px 0;
}

.highschool_landing_top .more_info img {
    width: initial;
    margin-right: 10px;
    flex-shrink: 0;
}

.highschool_landing_top .more_info p {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 26px;
    cursor: pointer;
}

.highschool_landing_more {
    height: 900px;
    margin: 0 0 20px;
}

#learn_more {
    padding-top: 60px;
    background: #3B9BFF;
}

.highschool_landing_more .column {
    width: calc(100% / 3);
    height: 100%;
    display: inline-block;
    float: left;
    text-align: center;
    color: #fff;
    margin-bottom: -40px;
}

.highschool_landing_more .column.s1 {
    background: url(../../assets/v4/highschool_landing/compete_free_v2.png) no-repeat;
    background-size: cover;
    background-position-x: center;
}

.highschool_landing_more .column.s2 {
    background: url(../../assets/v4/highschool_landing/earn_scholarship_v2.png) no-repeat;
    background-size: cover;
    background-position-x: center;
}

.highschool_landing_more .column.s3 {
    background: url(../../assets/v4/highschool_landing/stem_accredited_v2.png) no-repeat;
    background-size: cover;
    background-position-x: center;
}

.highschool_landing_more .title {
    margin-top: 250px;
    padding: 20px 30px;
    font-weight: 800;
    font-size: 38px;
    text-transform: uppercase;
    line-height: 40px;
    min-height: 130px;
}

.highschool_landing_more .description {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    padding: 40px 0;
    width: 300px;
    margin: 0 auto;
    min-height: 152px;
}

.highschool_landing_more .highschool_landing_btn.more {
    margin: 50px auto;
    width: 230px;
    background: #FFD130;
    padding: 8px 30px;
    cursor: pointer;
    height: 50px;
    border-radius: 7px;
}

.highschool_landing_more .highschool_landing_btn span {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}
/* end highschool landing */

/* highschool landing student */
.highschool_student,
.highschool_admin {
    margin-top: 40px;
    width: 100%;
    height: 100%;
}
.highschool_student_header_banner{
    height: 170px;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.highschool_student_content{
    width: 100%;
    position: relative;
    margin-top: -35px;
    margin-bottom: -60px;
}
.highschool_student_content_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: auto 100% !important;
    z-index: -1;
    overflow-y: hidden;
}
.highschool_student_content_bg .content_right_bg{
    position: absolute;
    right: 0;
    top: 0;
    width: 64%;
}
.highschool_student_content_bg .content_mid_bg{
    position: absolute;
    right: 320px;
    top: 0;
    width: 80%;
    height: 120%;
}
.highschool_student_content_bg .content_icon{
    position: absolute;
    right: 70px;
    top: 0;
    width: 15%;
    height: auto;
}
.hs_student_content{
    width: 100%;
    padding: 100px 150px;
}
.hs_student_content_head, .hs_student_content_calendar, .hs_student_step_bars{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}
.hs_student_content_head{
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.hs_student_content_head_left{
    width: 33%;
}
.hs_student_content_head_left_not_register{
    width: 33%;
    display: flex;
    align-items: flex-end;
}
.hs_student_content_head_left_not_register h1{
    max-width: 300px;
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 0;
}
.hs_student_content_head_left h1{
    width: 100%;
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: 44px;
}
.hs_student_content_head_right{
    width: 67%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
}
.hs_student_btn_big{
    width: 100%;
    background-color: #3B9BFF;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    padding: 10px 50px;
    text-align: center;
    cursor: pointer;
    display: block;
    max-width: 400px;
    border-radius: 15px;
}
.hs_student_content_box{
    margin: 8px;
    padding: 15px 20px;
    color: #fff;
    width: 210px;
    height: 130px;
    display: block;
}
.hs_student_content_box:last-child{
    margin-right: 0;
}
.hs_student_content_box_header{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}
.hs_student_content_box_list{
    font-size: 14px;
}
.hs_student_content_calendar{
    width: 100%;
    margin-top: 60px;
    position: relative;
}
.hs_student_step_bars{
    display: flex;
    margin-top: 30px;
    justify-content: center;
}
.hs_content_step_bar{
    height: 7px;
    background-color: #fff;
    margin: 0 15px;
    width: 15%;
    cursor: pointer;
}
.hs_content_step_bar.active{
    background-color: #A3B4FF;
    cursor: default;
}
.hs_student_step_action{
    cursor: pointer;
}
.hs_student_arrow{
    border: solid #FF8045;
    border-width: 0 7px 7px 0;
    display: inline-block;
    padding: 12px;
    margin-top: -12px;
}
.hs_student_arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.hs_student_arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.highschool_student_header_title{
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 10px;
}
/* end highschool landing student */

/*highschool landing admin */
#create, #stem_accreditation {
    padding-top: 60px;
}
.highschool_landing_admin_create {
    padding: 40px 0;
    margin-bottom: -60px;
    background: url(../../assets/v4/highschool_landing/highschool_landing_admin_create.png) no-repeat;
    background-size: cover;
}

.highschool_landing_admin_create .create_container {
    width: 1000px;
    margin: 60px auto;
    display: flex;
}

.highschool_landing_admin_create .left_side {
    width: 550px;
    height: 480px;
    padding: 75px;
    background-color: #28324b;
    color: #fff;
}

.highschool_landing_admin_create .title {
    font-size: 43px;
    font-weight: 800;
    line-height: 40px;
}

.highschool_landing_admin_create .desc {
    font-size: 28px;
    line-height: 35px;
    margin: 35px 0;
}

.highschool_landing_admin_create .button {
    background: #3B9BFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2.34506px;
    width: 338px;
    height: 50px;
    text-align: center;
    padding: 12px 0px;
    cursor: pointer;
}

.highschool_landing_admin_create .button span {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
}

.highschool_landing_admin_create .right_side {
    display: block;
}

.highschool_landing_admin_create .right_side_mobile {
    display: none;
}

.highschool_landing_admin_create .right_side .step {
    position: relative;
    display: flex;
    width: 380px;
    height: 115px;
    justify-content: center;
    align-items: center;
    border-top: 7px solid #d0a509;
    border-bottom: 7px solid #d0a509;
    border-right: 7px solid #d0a509;
    margin: 34px 0;
    padding: 0 45px;
    background-color: #fff;
    color: #181818;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.highschool_landing_admin_create .right_side .step::before {
    content: "";
    position: absolute;
    top: 22px;
    left: 0;
    border-top: 30px solid transparent;
    border-left: 30px solid #28324b;
    border-bottom: 30px solid transparent;
}

.highschool_landing_admin_create .right_side .step::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: -32px;
    background-color: #28324b;
    width: 60px;
    height: 60px;
    color: #fff;
    border-radius: 50%;
    font-size: 38px;
}

.highschool_landing_admin_create .right_side .step_1::after {
    content: "1";
}

.highschool_landing_admin_create .right_side .step_2::after {
    content: "2";
}

.highschool_landing_admin_create .right_side .step_3::after {
    content: "3";
}


.highschool_landing_admin_create .right_side_mobile .step {
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    padding: 30px 5px 0;
    border-bottom: 7px solid #D0A509;
    border-right: 7px solid #D0A509;
    border-left: 7px solid #D0A509;
    background-color: #fff;
    color: #181818;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.highschool_landing_admin_create .right_side_mobile .step_2 {
    margin: 0 20px;
}

.highschool_landing_admin_create .right_side_mobile .step::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    right: 48px;
    background-color: #505F84;
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 50%;
}

.highschool_landing_admin_create .right_side_mobile .step_1::before {
    content: "1";
}

.highschool_landing_admin_create .right_side_mobile .step_2::before {
    content: "2";
}

.highschool_landing_admin_create .right_side_mobile .step_3::before {
    content: "3";
}

.hs_admin_free_con_bg{
    background: url(../../assets/v4/highschool_landing/highschool-admin-free-consultation-bg.png) no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 130px 20px 170px;
    min-height: 591px;
    margin-bottom: -40px;
}
.hs_admin_free_con{
    min-height: 890px;
}
.hs_admin_free_con .fw_title{
    font-weight: 500;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 100%;
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-bottom: 15px;
}

.hs_admin_free_con .hs_about_title{
    font-weight: 700 !important;
    line-height: 1;
}

.hs_admin_free_con .hs_about_content{
    font-weight: 600;
    line-height: 1;
}
.hs_admin_free_con .fw_container{
    align-items: center;
}

.hs_admin_free_con .fw_action{
    background-color: #95DBE5 !important;
}

.hs_admin_free_con .action_item_title{
    color: #1B1B1B !important;
}
.hs_admin_free_con .fw_action_bar{
    background-color: #DAFAFF !important;
}
.hs_admin_free_con .fw_action_fill{
    background-color: #4E919B !important;
}
.hs_admin_free_con .fw_btn{
    border-radius: 15px;
}
.hs_admin_free_con .fw_calendar, .hs_admin_free_con .fw_email{
    background-color: #ffffff !important;
}
.hs_admin_free_con .fw_calendar_date{
    margin-bottom: 0 !important;
}
.hs_admin_free_con .fw_calendar_icon{
    border-left: 2px solid #CCCCCC;
}
.hs_admin_free_con .fw_action_item{
    border-bottom: 2px solid #89C9D3;
}
.hs_admin_free_con .fw_title,
.hs_admin_free_con .action_item_inclined_title,
.hs_admin_free_con .fw_body,
.hs_admin_free_con .fw_calendar,
.hs_admin_free_con #pt_start_time_create,
.hs_admin_free_con .action_item_title_medium,
.hs_admin_free_con .fw_subtitle,
.hs_admin_free_con #date_select_creator {
    color: #000000 !important;
}
.hs_admin_free_con .fw_about{
    padding: 30px 40px 10px;
    background-color: #06A09B;
    margin-bottom: 50px;
}
.hs_admin_free_con .fw_btn{
    background-color: #4E929B !important;
    color: #FFFFFF !important;
}

.hs_admin_free_con .fw_link{
    color: #000000 !important;
}

.hs_admin_free_con input{
    color: #000000 !important;
}

.hs_admin_free_con input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000 !important;
    opacity: 1; /* Firefox */
    font-weight: 500;
}

.hs_admin_free_con input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000000 !important;
    font-weight: 500;
}

.hs_admin_free_con input::-ms-input-placeholder { /* Microsoft Edge */
    color: #000000 !important;
    font-weight: 500;
}
#embedBottomBarContainer{
    display:none;
}
/* end highschool landing admin */

/* new landing highschool */
.highschool_landing_main .progress {
    position: absolute;
    display: none;
    right: 60px;
    bottom: 25px;
}
.highschool_landing_page_1 {
    height: calc(100vh - 60px);
    display: flex;
    position: relative;
}
.highschool_landing_page_1 .left_side {
    width: 50%;
    background: #0E0A0F;
}
.highschool_landing_page_1 .right_side {
    width: 50%;
    background-color: #D93932;
    background-image: url(../../assets/v4/new_highschool_landing/bg_avgl_mask.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 160px;
}
.highschool_landing_page_1 .logo_img {
    display: flex;
    justify-content: center;
    margin: 200px auto 50px;
}
.highschool_landing_page_1 .logo_img img {
    width: 575px;
}
.highschool_landing_page_1 .sub_title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.highschool_landing_page_1 .mask_img {
    position: absolute;
    top: 0;
    left: 175px;
    width: 100%;
    z-index: -1;
}
.highschool_landing_page_1 .mask_img img {
    width: 1420px;
}
.highschool_landing_page_1 .main_text {
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    color: #FFFFFF;
    margin: 250px auto 50px;
}
.highschool_landing_page_1 .sub_text {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.highschool_landing_page_1 .new_highschool_btn {
    background: #FFFFFF;
    border-radius: 12px;
    width: 250px;
    padding: 10px 0;
    display: block;
    text-align: center;
    vertical-align: middle;
    margin-top: 50px;
    cursor: pointer;
}
.highschool_landing_page_1 .new_highschool_btn span {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
    text-transform: uppercase;
    color: #D93932;
}
.highschool_landing_page_1 .partner_container {
    margin: 40px auto 50px;
    max-width: 600px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.highschool_landing_page_1 .highschool_partner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    margin: 0 10px 10px;
}
.highschool_landing_page_2 {
    position: relative;
    display: flex;
    height: 100vh;
    padding: 200px 150px 0;
    background-image: url(../../assets/v4/new_highschool_landing/intel_inspire_codered_rsz.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FFF;
}
.highschool_landing_page_2 .left_side,
.highschool_landing_page_2 .right_side {
    width: 50%;
    padding: 20px 75px;
}
.highschool_landing_page_2 .intel_logo {
    width: 360px;
    height: 210px;
    margin: 0 0 50px 50px;
}
.highschool_landing_page_2 .codered_logo {
    width: 370px;
    height: 94px;
    margin: 68px 0 98px 50px;
}
.highschool_landing_page_2 .main_title {
    font-size: 30px;
    line-height: 33px;
    font-weight: 600;
    text-decoration: underline;
    color: #000;
}
.highschool_landing_page_2 .sub_title {
    font-size: 30px;
    line-height: 33px;
    font-weight: 600;
    color: #929292;
}
.highschool_landing_page_2 .main_text {
    font-style: normal;
    font-size: 26px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #0E0A0F;
    margin-top: 20px;
}
.highschool_landing_page_2 .main_text b {
    font-size: 30px;
}
.highschool_landing_page_2 .intel_label {
    position: absolute;
    right: 50px;
    top: 300px;
    width: 36px;
    height: 327px;
}
.highschool_landing_page_2 .avgl_label,
.highschool_landing_page_3 .avgl_label,
.highschool_landing_page_4 .avgl_label,
.highschool_landing_page_5 .avgl_label,
.highschool_landing_page_6 .avgl_label {
    position: absolute;
    right: 45px;
    top: 130px;
    width: 40px;
    height: 42px;
}
.highschool_landing_page_2 .new_highschool_btn,
.highschool_landing_page_3 .new_highschool_btn,
.highschool_landing_page_4 .new_highschool_btn,
.highschool_landing_page_5 .new_highschool_btn{
    background: #FFFFFF;
    border-radius: 12px;
    width: 250px;
    padding: 10px 0;
    display: block;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    bottom: 70px;
    right: 200px;
    cursor: pointer;
}
.highschool_landing_page_2 .new_highschool_btn {
    background: #D93932;
    bottom: 30px;
}
.highschool_landing_page_2 .new_highschool_btn span,
.highschool_landing_page_3 .new_highschool_btn span,
.highschool_landing_page_4 .new_highschool_btn span,
.highschool_landing_page_5 .new_highschool_btn span{
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
    text-transform: uppercase;
    color: #0D6FBF;
}
.highschool_landing_page_2 .new_highschool_btn span,
.highschool_landing_page_4 .new_highschool_btn span {
    color: #FFFFFF;
}
.highschool_landing_page_2 .mask_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}
.highschool_landing_page_3 {
    background: #0E0A0F;
    height: 100vh;
    padding: 100px;
    position: relative;
}
.highschool_landing_page_3 .highschool_card {
    padding: 0 30px;
    width: 430px;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    border: 10px solid #115185;
}
@media (hover: hover) {
    .highschool_landing_page_3 .highschool_card:hover {
        transform: unset;
        display: block;
        padding: 60px;
        border: 10px solid #0f6fbf;
    }
    .highschool_landing_page_3 .highschool_card.card_left:hover {
        background-image: url(../../assets/v4/new_highschool_landing/card_left_darker_rsz.png);
    }
    .highschool_landing_page_3 .highschool_card.card_middle:hover {
        background-image: url(../../assets/v4/new_highschool_landing/card_mid_darker_rsz.png);
    }
    .highschool_landing_page_3 .highschool_card.card_right:hover {
        background-image: url(../../assets/v4/new_highschool_landing/card_right_darker_rsz.png);
    }
    .highschool_landing_page_3 .highschool_card:hover .highschool_card_title {
        padding: 85px 0 35px;
        border-bottom: 3px solid #FFF;
        margin-bottom: 40px;
    }
    .highschool_landing_page_3 .highschool_card:hover .highschool_card_detail {
        width: 100%;
        visibility: visible;
        opacity: 1;
        list-style: outside;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 136.1%;
        align-items: center;
        text-transform: capitalize;
        color: #FFFFFF;
        text-shadow: 0px 9px 14px rgba(0, 0, 0, 0.5);
    }
    .highschool_landing_page_3 .highschool_card:hover .highschool_card_detail_paragraph {
        text-align: center;
    }
    .highschool_landing_page_3 .highschool_card:hover .highschool_card_detail li {
        margin-bottom: 15px;
    }
}
.highschool_landing_page_3 .card_left {
    background-image: url(../../assets/v4/new_highschool_landing/card_left_no_border_rsz.png);
}
.highschool_landing_page_3 .card_middle {
    background-image: url(../../assets/v4/new_highschool_landing/card_mid_no_border_rsz.png);
}
.highschool_landing_page_3 .card_right {
    background-image: url(../../assets/v4/new_highschool_landing/card_right_no_border_rsz.png);
}
.highschool_landing_page_3 .highschool_card_container {
    display: flex;
    justify-content: space-between;
    width: 1368px;
    margin: 0 auto;
}
.highschool_landing_page_3 .highschool_card_title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 14px 21px rgba(0, 0, 0, 0.3);
}
.highschool_landing_page_3 .highschool_card_detail {
    width: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.highschool_landing_page_3 .program_label {
    position: absolute;
    right: 50px;
    top: 240px;
}
.highschool_landing_page_3 .program_label img {
    width: 36px;
    height: 437px;
}
.highschool_landing_page_4 {
    position: relative;
    height: 100vh;
    padding: 160px;
    background-color: #FFF;
    /*background-image: url(../../assets/v4/new_highschool_landing/avgl_mask_4.png);*/
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
}
.highschool_landing_page_4 .big_title {
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 90px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0E0A0F;
}
.highschool_landing_page_4 .support_game_card {
    width: 407px;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_img {
    display: flex;
    transition: margin 700ms;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_img.has_events {
    margin-top: -60px;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_img img {
    width: 397px;
    height: 280px;
    background: #0D6FBF;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_img.no_event img {
    height: 260px;
}
.highschool_landing_page_4 .gallery-item-previous .game_card_img img,
.highschool_landing_page_4 .gallery-item-next .game_card_img img {
    width: 297px;
    height: 221px;
}
.highschool_landing_page_4 .gallery-item-first .game_card_img img,
.highschool_landing_page_4 .gallery-item-last .game_card_img img {
    width: 225px;
    height: 167px;
}
.highschool_landing_page_4 .game_card_container {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_container {
    width: 397px;
    visibility: visible;
    opacity: 1;
}
.highschool_landing_page_4 .gallery-item-selected .game_card_container.no_event {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.highschool_landing_page_4 .game_card_event_title {
    font-style: normal;
    font-weight: bold;
    font-size: 25.6263px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #0D6FBF;
    color: #FFFFFF;
    height: 50px;
    justify-content: center;
}
.highschool_landing_page_4 .game_card_event {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    background: #094F89;;
}
.highschool_landing_page_4 .game_card_event:first-child,
.highschool_landing_page_4 .game_card_event:last-child {
    background: #0C5C9D;
}
.highschool_landing_page_4 .game_event_title {
    padding: 0 20px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #FFFFFF;
}
.highschool_landing_page_4 .game_event_number {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FFFFFF;
}
.highschool_landing_page_4 .new_highschool_btn {
    background: #D93932;
}
.highschool_landing_page_4 .program_label {
    position: absolute;
    right: 50px;
    top: 300px;
}
.highschool_landing_page_4 .program_label img {
    width: 30px;
    height: 390px;
}
.gallery {
    width: 100%;
}
.gallery-container {
    align-items: center;
    display: flex;
    height: 400px;
    margin: 70px auto;
    max-width: 1000px;
    position: relative;
}
.gallery-item,
.card-item {
    height: 150px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 150px;
    z-index: 0;
}
.gallery-item.gallery-item-selected,
.card-item.card-item-selected {
    height: 239px;
    opacity: 1;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 397px;
    z-index: 2;
}
.gallery-item.gallery-item-previous,
.gallery-item.gallery-item-next,
.card-item.card-item-previous,
.card-item.card-item-next{
    height: 200px;
    opacity: 1;
    width: 240px;
    z-index: 1;
}
.gallery-item.gallery-item-previous,
.card-item.card-item-previous {
    left: 25%;
    transform: translateX(-50%);
}
.gallery-item.gallery-item-next,
.card-item.card-item-next {
    left: 70%;
    transform: translateX(-50%);
}
.gallery-item.gallery-item-first {
    left: 20%;
    transform: translateX(-50%);
}
.gallery-item.gallery-item-last {
    left: 100%;
    transform: translateX(-50%);
}
.gallery-controls {
    display: none;
    justify-content: center;
    margin: 30px 0;
}
.card-controls {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}
.gallery-controls button,
.card-controls button {
    border: 0;
    cursor: pointer;
    font-size: 16px;
    margin: 0 20px;
    padding: 0 12px;
    text-transform: capitalize;
}
.gallery-controls button:focus,
.card-controls button:focus{
    outline: none;
}
.gallery-controls-previous,
.card-controls-previous {
    position: relative;
}
.gallery-controls-previous::before,
.card-controls-previous::before {
    border: solid #000;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 4px;
    left: -10px;
    padding: 2px;
    position: absolute;
    top: 0;
    transform: rotate(135deg) translateY(-50%);
    transition: left 0.15s ease-in-out;
    width: 4px;
}
.gallery-controls-previous:hover::before,
.card-controls-previous:hover::before {
    left: -18px;
}
.gallery-controls-next,
.card-controls-next {
    position: relative;
}
.gallery-controls-next::before,
.card-controls-next::before {
    border: solid #000;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 4px;
    padding: 2px;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    transition: right 0.15s ease-in-out;
    width: 4px;
}
.gallery-controls-next:hover::before,
.card-controls-next:hover::before {
    right: -18px;
}
.highschool_landing_page_5 {
    position: relative;
    height: 100vh;
}
.highschool_landing_page_5 .highschool_landing_page_container {
    display: flex;
}
.highschool_landing_page_5 .padding {
    padding-top: 60px;
    background-color: #0E0A0F;
}
.highschool_landing_page_5 .left_side {
    width: 48%;
    padding: 20px 0 0 20px;
    background-color: #0D6EBF;
}
.highschool_landing_page_5 .stem_accredidation_carousel {
    height: calc(100vh - 80px);
}
.highschool_landing_page_5 .stem_accredidation_carousel.slick-dotted.slick-slider {
    margin: 0;
}
.highschool_landing_page_5 .stem_accredidation img {
    width: 48vw;
    height: calc(100vh - 100px);
}
.highschool_landing_page_5 .slick-arrow {
    display: none !important;
}
.highschool_landing_page_5 .slick-dots {
    bottom: 35px;
}
.highschool_landing_page_5 .slick-dots li {
    width: 80px;
    height: 5px;
    background-color: #FFF;
}
.highschool_landing_page_5 .slick-dots .slick-active {
    background-color: #0D6FBF;
}
.highschool_landing_page_5 .slick-dots li button {
    display: none;
}
.highschool_landing_page_5 .right_side {
    width: 52%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0E0A0F;
}
.highschool_landing_page_5 .description_container {
    max-width: 575px;
}
.highschool_landing_page_5 .stem_accredidation_description {
    display: flex;
    margin-bottom: 50px;
    position: relative;
}
.highschool_landing_page_5 .stem_accredidation_description.on:before {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background: #0D6EBF;
    bottom: -20px;
    left: 115px;
}
.highschool_landing_page_5 .stem_accredidation_description:last-child {
    margin-bottom: 0;
}
.highschool_landing_page_5 .stem_accredidation_description img {
    width: 86px;
    height: 86px;
    margin-right: 25px;
}
.highschool_landing_page_5 .description_text {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.highschool_landing_page_5 .program_label {
    position: absolute;
    right: 50px;
    top: 230px;
    width: 27px;
    height: 498px;
}
.highschool_landing_page_6 {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 130px 20px 170px;
    height: 100vh;
    margin-bottom: -40px;
    background-color: #0A4B81;
    /*background-image: url(../../assets/v4/new_highschool_landing/avgl_mask_3.png);*/
    background-repeat: no-repeat;
    background-size: contain;
}
.highschool_landing_page_6 .fw_container {
    max-width: 1400px;
}
.highschool_landing_page_6 .fw_about {
    margin: 110px 10px 0;
    max-width: 445px;
}
.highschool_landing_page_6 .fw_body {
    color: #FFF !important;
}
.highschool_landing_page_6 .fw_email {
    border: 2px solid rgba(255,255,255,0.2) !important;
}
.highschool_landing_page_6 .fw_email input {
    color: #f9f9f9 !important;
}
.highschool_landing_page_6 .fw_email input::placeholder {
    color: rgba(255,255,255,0.2) !important;
}
.highschool_landing_page_6 .hs_about_content {
    font-size: 22px;
    line-height: 30px;
}
.highschool_landing_page_6 .new_highschool_title {
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.highschool_landing_page_6 .fw_action {
    background-color: #0D6FBF !important;
}
.highschool_landing_page_6 .fw_btn {
    background-color: #FFF !important;
    color: #1F1F1F !important;
}
.highschool_landing_page_6 .fw_title {
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.highschool_landing_page_6 .action_item_inclined_title {
    padding: 0 20px;
    text-align: left;
    font-style: normal;
    margin: 12px 0 0;
}
.highschool_landing_page_6 .program_label {
    position: absolute;
    right: 50px;
    top: 240px;
    width: 27px;
    height: 498px;
}
.highschool_landing_page_6 .program_label img {
    width: 27px;
    height: 498px;
}
.highschool_landing_page_6 .new_highschool_btn {
    background: #FFFFFF;
    border-radius: 12px;
    width: 250px;
    padding: 10px 0;
    display: block;
    text-align: center;
    vertical-align: middle;
    margin-top: 50px;
    cursor: pointer;
}
.highschool_landing_page_6 .new_highschool_btn span {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
    text-transform: uppercase;
    color: #0D6FBF;
}
/* end new landing highschool */
@media screen and (max-width: 1700px){
    .hs_student_content_head_right{
        justify-content: center;
    }
    .hs_student_content_box:last-child{
        margin-right: 8px;
    }
    .highschool_landing_page_2 {
        padding: 150px 100px 0;
    }
    .highschool_landing_page_2 .left_side, .highschool_landing_page_2 .right_side {
        padding: 20px 30px;
    }
    .highschool_landing_page_2 .intel_logo {
        width: 300px;
        height: 170px;
    }
    .highschool_landing_page_2 .main_title {
        font-size: 24px;
    }
    .highschool_landing_page_2 .sub_title {
        font-size: 24px;
    }
    .highschool_landing_page_2 .main_text {
        font-size: 20px;
        margin-top: 15px;
        line-height: 34px;
    }
    .highschool_landing_page_2 .main_text b {
        font-size: 24px;
    }
    .highschool_landing_page_2 .codered_logo {
        width: 330px;
        height: 80px;
        margin: 50px 0 90px 50px;
    }
    .highschool_landing_page_3 .highschool_card {
        width: 400px;
        height: 650px;
    }
    @media (hover: hover) {
        .highschool_landing_page_3 .highschool_card:hover .highschool_card_title {
            padding: 50px 0 35px;
        }
    }
}
@media screen and (max-width: 1440px){
    .highschool_landing_more {
        height: 800px;
    }
    /* highschool student */
    .highschool_student_header_title{
        margin-bottom: 12px;
    }
    .highschool_student_content_bg .content_mid_bg{
        right: 200px;
        width: 90%;
    }
    /* new highschool landing */
    .highschool_landing_page_1 .logo_img img {
        width: 400px;
    }
    .highschool_landing_page_1 .highschool_partner {
        width: 100px;
    }
    .highschool_landing_page_1 .right_side {
        padding: 0 80px;
    }
    .highschool_landing_page_3 {
        padding: 70px;
    }
    .highschool_landing_page_3 .highschool_card_container {
        width: 1200px;
    }
    @media (hover: hover) {
        .highschool_landing_page_3 .highschool_card:hover .highschool_card_title {
            padding: 20px 0;
            margin-bottom: 20px;
        }
        .highschool_landing_page_3 .highschool_card:hover {
            padding: 30px;
        }
    }
    .highschool_landing_page_3 .highschool_card_title {
        font-size: 34px;
    }
    .highschool_landing_page_3 .highschool_card {
        width: 352px;
        height: 570px;
    }
    .highschool_landing_page_4 {
        padding: 90px;
    }
    .highschool_landing_page_5 .right_side {
        justify-content: flex-start;
        padding-left: 30px;
    }
    .highschool_landing_page_5 .description_container {
        width: 485px;
    }
    .highschool_landing_page_5 .stem_accredidation img {
        width: calc(50vw - 20px);
    }
    .highschool_landing_page_6 .fw_container {
        max-width: 1150px;
    }
}
@media screen and (max-width: 1366px){
    .highschool_landing_page_1 .main_text {
        margin: 170px auto 50px;
    }
    .highschool_landing_page_1 .logo_img {
        margin: 170px auto 50px;
    }
    .highschool_landing_page_3 .highschool_card_container {
        width: 1100px;
    }
    .highschool_landing_page_3 .highschool_card {
        width: 375px;
        height: 595px;
    }
    .highschool_landing_page_6 .fw_container {
        max-width: 1020px;
    }
}
@media screen and (max-width: 1200px){
    .highschool_landing_more {
        height: 750px;
    }
    .hs_student_content{
        padding: 100px 50px;
    }
}
@media screen and (max-width: 1000px){
    /* landing */
    .highschool_landing_top .title {
        width: 100%;
        padding: 215px 75px 20px;
    }
    .highschool_landing_top .button_container {
        display: block;
        margin: 40px auto 30px;
        width: 100%;
        text-align: center;
    }
    .highschool_landing_top .highschool_landing_btn {
        width: 550px;
        margin: 0 auto;
    }
    .highschool_landing_top .highschool_landing_btn.more {
        width: 225px;
        margin: 30px auto 15px;
    }
    .highschool_landing_top .partner_container {
        width: 100%;
    }
    .highschool_landing_top .more_info {
        justify-content: center;
    }
    .highschool_landing_more {
        height: 730px;
    }
    .highschool_landing_more .column {
        width: 100%;
    }
    .highschool_landing_more .title {
        margin-top: 340px;
        min-height: unset;
    }
    .highschool_landing_more .highschool_landing_btn.more {
        margin: 15px auto;
    }
    /* student */
    .hs_student_content{
        padding: 100px 50px;
    }
    .hs_student_content_head{
        flex-wrap: wrap;
    }
    .hs_student_content_head_left, .hs_student_content_head_right, .hs_student_content_head_left_not_register{
        width: 100%;
    }
    .hs_student_content_head_left_not_register{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hs_student_content_head_left_not_register h1{
        max-width: 100%;
        text-align: center;
        font-size: 50px;
        margin-bottom: 26.8px;
    }
    .hs_student_btn_big{
        margin: 0 auto 40px;
    }
    .hs_student_content_head_left h1 {
        text-align: center;
    }
    .highschool_student_header_title{
        font-size: 24px;
        margin-bottom: 14px;
    }
    .highschool_student_content_bg .content_mid_bg{
        height: 110%;
        right: 120px;
    }
    /* end student */

    /* admin */
    .hs_admin_free_con .fw_about{
        margin-bottom: 40px;
    }
    /*end admin */
}
@media screen and (max-width: 550px){
    /* landing */
    .highschool_landing_top .title {
        padding: 220px 25px 0;
        font-size: 26px;
    }
    .highschool_landing_top .highschool_landing_btn {
        width: 370px;
        padding: 5px;
    }
    .highschool_landing_top .highschool_landing_btn span {
        font-size: 18px;
    }
    .highschool_landing_top .partner_container {
        flex-wrap: wrap;
        margin: 20px auto;
    }
    .highschool_landing_top .highschool_partner {
        margin: 10px;
        width: 70px;
    }
    .highschool_landing_top .highschool_partner img {
        width: 60px;
    }
    .highschool_landing_top .more_info {
        padding: 0;
    }
    .highschool_landing_top .more_info p {
        font-size: 16px;
    }
    #learn_more {
        padding-top: 30px;
    }
    .highschool_landing_more {
        height: 550px;
    }
    .highschool_landing_more .title {
        margin-top: 155px;
    }
    .highschool_landing_more .description {
        padding: 25px 0;
    }
    .highschool_landing_more .highschool_landing_btn.more {
        margin: 0 auto;
    }
    /* student */
    .highschool_student_header_banner{
        height: 100px;
        padding: 0 10px;
    }
    .highschool_student_content_bg .content_mid_bg{
        right: 70px;
    }
    .highschool_student_header_title{
        font-size: 15px;
        margin-bottom: 5px;
    }
    .hs_student_content{
        padding: 50px 10px 200px;
        margin-bottom: -140px;
    }
    .hs_content_step_bar{
        margin: 0 5px;
    }
    /* admin */
    .highschool_landing_admin_create .create_container {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
    .highschool_landing_admin_create .left_side {
        height: 300px;
        padding: 20px 10px;
        text-align: center;
        width: 100%;
    }
    .highschool_landing_admin_create .right_side {
        display: none;
    }
    .highschool_landing_admin_create .right_side_mobile {
        display: flex;
    }
    .highschool_landing_admin_create .title {
        font-size: 32px;
    }
    .highschool_landing_admin_create .desc {
        font-size: 24px;
        margin: 15px 0;
    }
    .highschool_landing_admin_create .button {
        margin: auto;
        height: 40px;
        padding: 8px 0;
    }
    .highschool_landing_admin_create .right_side .step {
        width: 350px;
        font-size: 20px;
    }
    .hs_admin_free_con_bg{
        margin-bottom: -140px;
        padding: 50px 20px 150px;
    }
    .hs_student_content_head_left{
        padding: 0 20px;
    }
    /* new highschool landing */
    .highschool_landing_page_1 {
        display: block;
        height: auto;
        padding: 50px 50px 80px;
        background: url(../../assets/v4/new_highschool_landing/bg_avgl_mask_mobile.png) no-repeat;
        background-size: 100% 100%;
    }
    .highschool_landing_page_1 .logo_img {
        width: 100%;
        margin: 0px auto 30px;
    }
    .highschool_landing_page_1 .logo_img img {
        width: 300px;
        height: 115px;
    }
    .highschool_landing_page_1 .main_text {
        margin: 0;
        font-size: 17px;
        font-weight: 400;
        text-transform: capitalize;
    }
    .highschool_landing_page_1 .sub_text {
        font-size: 16px;
        justify-content: center;
        margin-top: 25px;
    }
    .highschool_landing_page_1 .new_highschool_btn {
        margin: 15px auto 25px;
        width: 170px;
        padding: 5px 0;
    }
    .highschool_landing_page_1 .new_highschool_btn span {
        font-size: 18px;
        line-height: 30px;
    }
    .highschool_landing_page_1 .sub_title {
        font-size: 18px;
    }
    .highschool_landing_page_1 .partner_container {
        margin: 20px 0 auto;
    }
    .highschool_landing_page_1 .highschool_partner {
        width: 50px;
    }
    .highschool_landing_page_1 .highschool_partner img {
        height: 40px;
    }
    .highschool_landing_page_2 {
        flex-flow: wrap;
        height: auto;
        padding: 50px 40px 80px;
        background-image: url(../../assets/v4/new_highschool_landing/intel_codered_mobile_rsz.png);
        background-size: contain;
    }
    .highschool_landing_page_2 .left_side,
    .highschool_landing_page_2 .right_side {
        width: 100%;
        padding: 0;
    }
    .highschool_landing_page_2 .right_side {
        margin-top: 50px;
    }
    .highschool_landing_page_2 .intel_logo,
    .highschool_landing_page_2 .codered_logo {
        width: calc(100% - 25px);
        position: static;
        height: auto;
        margin: 30px auto;
    }
    .highschool_landing_page_2 .main_title {
        text-decoration: none;
        padding-bottom: 3px;
        font-size: 22px;
    }
    .highschool_landing_page_2 .left_side .main_title {
        border-bottom: 2px solid #126EBB;
    }
    .highschool_landing_page_2 .right_side .main_title {
        border-bottom: 2px solid #FC031A;
    }
    .highschool_landing_page_2 .sub_title {
        font-size: 14px;
    }
    .highschool_landing_page_2 .main_text {
        width: 100%;
        font-size: 18px;
        line-height: 28px
    }
    .highschool_landing_page_2 .main_text b {
        font-size: 22px;
    }
    .highschool_landing_page_2 .new_highschool_btn {
        display: none;
        width: 170px;
        padding: 5px 0;
        bottom: 80px !important;
        right: 15px;
    }
    .highschool_landing_page_2 .new_highschool_btn span {
        font-size: 18px;
        line-height: 30px;
    }
    .highschool_landing_page_3 {
        display: block;
        min-height: 667px;
        padding: 50px 50px 80px;
        background-color: #0e0a0f;
        background-image: url(../../assets/v4/new_highschool_landing/avgl_mask_5.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .highschool_landing_page_3 .highschool_card_container {
        align-items: center;
        height: 400px;
        margin: 0 auto;
        max-width: 1000px;
    }
    .highschool_landing_page_3 .highschool_card {
        /*pointer-events: none;*/
        border: none;
    }
    .highschool_landing_page_3 .card_left {
        background-image: url(../../assets/v4/new_highschool_landing/card_left_rsz.png);
    }
    .highschool_landing_page_3 .card_middle {
        background-image: url(../../assets/v4/new_highschool_landing/card_mid_rsz.png);
    }
    .highschool_landing_page_3 .card_right {
        background-image: url(../../assets/v4/new_highschool_landing/card_right_rsz.png);
    }
    .highschool_landing_page_3 .highschool_card .highschool_card_detail_paragraph {
        text-transform: capitalize;
        font-size: 12px;
    }
    .highschool_landing_page_3 .highschool_card.card-item-selected {
        width: 185px;
        height: 300px;
        padding: 10px;
        opacity: unset;
        display: block;
    }
    .highschool_landing_page_3 .highschool_card.card-item-previous,
    .highschool_landing_page_3 .highschool_card.card-item-next {
        padding: 0;
    }
    .highschool_landing_page_3 .card-item-selected .game_card_img {
        display: flex;
        transition: margin 700ms;
    }
    .highschool_landing_page_3 .card-item-previous {
        left: 28%;
    }
    .highschool_landing_page_3 .card-item-previous ,
    .highschool_landing_page_3 .card-item-next {
        width: 145px;
        height: 235px;
        top: 25%;
    }
    .highschool_landing_page_3 .highschool_card .highschool_card_title {
        font-size: 15px;
        padding: 5px 0 10px;
        margin-bottom: 10px;
        line-height: 24px;
    }
    .highschool_landing_page_3 .highschool_card.card-item-selected .highschool_card_title {
        border-bottom: 2px solid #FFF;
        font-size: 18px;
    }
    .highschool_landing_page_3 .highschool_card.card-item-selected .highschool_card_detail {
        color: #FFF;
        visibility: visible;
        opacity: 1;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        margin-left: 5px;
        list-style: inside;
    }
    .highschool_landing_page_3 .highschool_card.card-item-selected .highschool_card_detail li {
        margin-bottom: 5px;
    }
    .highschool_landing_page_3 .card-controls-previous {
        width: 12px;
        height: 24px;
        background: url(../../assets/v4/new_highschool_landing/arrow_left.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 40%;
        left: 10px;
    }
    .highschool_landing_page_3 .card-controls-next {
        width: 12px;
        height: 24px;
        background: url(../../assets/v4/new_highschool_landing/arrow_right.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 40%;
        right: 10px;
    }
    .highschool_landing_page_4 {
        padding: 60px;
        height: auto;
        background-image: url(../../assets/v4/new_highschool_landing/avgl_mask_6.png);
    }
    .highschool_landing_page_4 .big_title {
        font-size: 28px;
        line-height: 42px;
    }
    .highschool_landing_page_4 .support_game_card {
        width: 200px;
    }
    .highschool_landing_page_4 .gallery-item-selected .game_card_img.has_events {
        margin-top: -40px;
    }
    .highschool_landing_page_4 .gallery-item-selected .game_card_img img {
        width: 200px;
        height: 120px;
    }
    .highschool_landing_page_4 .gallery-item-selected .game_card_img.no_event img {
        height: 130px;
    }
    .highschool_landing_page_4 .gallery-item-previous .game_card_img img,
    .highschool_landing_page_4 .gallery-item-next .game_card_img img {
        width: 120px;
        height: 95px;
    }
    .highschool_landing_page_4 .gallery-item-first .game_card_img img,
    .highschool_landing_page_4 .gallery-item-last .game_card_img img {
        width: 80px;
        height: 70px;
    }
    .highschool_landing_page_4 .gallery-item-selected .game_card_container {
        width: 200px;
    }
    .highschool_landing_page_4 .game_card_event_title {
        font-size: 18px;
        line-height: 22px;
        height: 30px;
    }
    .gallery-container {
        margin: 0;
    }
    .gallery-item.gallery-item-previous {
        left: 35%;
    }
    .gallery-item.gallery-item-next {
        left: 95%;
    }
    .gallery-item.gallery-item-first {
        top: 28%;
        left: 25%;
    }
    .gallery-item.gallery-item-last {
        top: 28%;
        left: 120%;
    }
    .highschool_landing_page_4 .game_card_event {
        padding: 5px;
    }
    .highschool_landing_page_4 .game_card_event img {
        display: none;
        width: 25px !important;
        height: 25px !important;
    }
    .highschool_landing_page_4 .game_event_title {
        display: block;
        max-width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
    .highschool_landing_page_4 .game_event_number {
        font-size: 12px;
    }
    .highschool_landing_page_5 {
        height: auto;
        padding: 0 0 80px 0;
        background: #0E0A0F;
    }
    .highschool_landing_page_5 .highschool_landing_page_container {
        flex-flow: row wrap;
    }
    .highschool_landing_page_5 .left_side {
        width: 100%;
        padding: 25px 25px 0 25px;
    }
    .highschool_landing_page_5 .right_side {
        width: 100%;
        padding: 25px;
    }
    .highschool_landing_page_5 .stem_accredidation_carousel {
        height: 400px;
    }
    .highschool_landing_page_5 .stem_accredidation img {
        height: 400px;
        width: calc(100vw - 40px);
    }
    .highschool_landing_page_5 .description_container {
        width: 100%;
    }
    .highschool_landing_page_5 .stem_accredidation_description {
        padding: 0 5px;
        align-items: center;
    }
    .highschool_landing_page_5 .stem_accredidation_description img {
        width: 60px;
        height: 60px;
    }
    .highschool_landing_page_5 .description_text {
        font-size: 16px;
        line-height: 26px;
    }
    .highschool_landing_page_5 .stem_accredidation_description.on:before {
        left: 90px;
    }
    .highschool_landing_page_6 .mobile_next_section {
        bottom: 28px;
    }
    .highschool_landing_page_6 {
        display: block;
        height: auto;
        padding: 0 0 50px 0;
    }
    .highschool_landing_page_6 .fw_about {
        margin: 0;
    }
    .highschool_landing_page_6 .new_highschool_title {
        font-size: 30px;
        display: block;
        text-align: left;
        padding: 0 40px
    }
    .highschool_landing_page_6 .hs_about_content {
        text-align: left;
        padding: 0 40px;
        font-size: 18px
    }
    .highschool_landing_page_6 .fw_action {
        width: calc(100% - 30px);
    }
    .highschool_landing_page_6 .fw_title {
        width: 100%;
        margin: 0;
    }
    .highschool_landing_page_6 .new_highschool_btn {
        width: 170px;
        padding: 5px 0;
        margin: 25px auto;
    }
    .highschool_landing_page_6 .new_highschool_btn span {
        font-size: 18px;
        line-height: 30px;
    }
    .highschool_landing_page_6 .mobile_avgl_label {
        bottom: 50px;
    }
    .highschool_landing_page_6 .mobile_next_section {
        height: 28px;
        width: 260px;
        bottom: 46px;
    }
    .highschool_landing_page_6 .next_section_arrow {
        bottom: 52px;
    }
    .mobile_avgl_label {
        width: 22px;
        position: absolute;
        bottom: 22px;
        left: 15px;
    }
    .mobile_next_section {
        width: 300px;
        height: 17px;
        position: absolute;
        bottom: 25px;
        left: 60px;
    }
    .next_section_arrow {
        width: 15px;
        position: absolute;
        bottom: 25px;
        right: 15px;
    }
}

@media screen and (max-width: 450px){
    .highschool_landing_admin_create .right_side_mobile .step::before {
        right: 36px;
    }
}

@media screen and (max-width: 392px){
    .mobile_next_section {
        width: 280px;
    }
    .mobile_avgl_label {
        width: 20px;
    }
}

@media screen and (max-width: 379px){
    .highschool_landing_admin_create .right_side_mobile .step::before {
        right: 30px;
    }
    .highschool_landing_page_1 .highschool_partner {
        width: 45px;
    }
    .mobile_next_section {
        width: 240px;
    }
    .mobile_avgl_label {
        width: 18px;
    }
}

.highschool_landing_main .landing_socials_container,
.highschool_student .landing_socials_container,
.highschool_admin .landing_socials_container {
    width: 100% !important;
    transform: unset !important;
}

@media screen and (max-height: 900px) {
    .highschool_landing_page_2 .intel_label {
        top: 200px;
        width: 28px;
    }
    .highschool_landing_page_3 .program_label {
        top: 180px;
    }
    .highschool_landing_page_3 .program_label img {
        height: 370px;
    }
    .highschool_landing_page_4 .program_label {
        top: 180px;
    }
    .highschool_landing_page_4 .program_label img {
        height: 360px;
    }
    .highschool_landing_page_5 .program_label {
        top: 160px;
        width: 22px;
    }
    .highschool_landing_page_6 .program_label {
        top: 160px;
    }
    .highschool_landing_page_6 .program_label img {
        height: 410px;
    }
    .highschool_landing_page_2 .avgl_label,
    .highschool_landing_page_3 .avgl_label,
    .highschool_landing_page_4 .avgl_label,
    .highschool_landing_page_5 .avgl_label,
    .highschool_landing_page_6 .avgl_label {
        top: 75px;
    }
    .highschool_landing_page_2 .new_highschool_btn,
    .highschool_landing_page_3 .new_highschool_btn,
    .highschool_landing_page_4 .new_highschool_btn,
    .highschool_landing_page_5 .new_highschool_btn{
        width: 200px;
        bottom: 25px;
        right: 125px;
    }
    .highschool_landing_page_2 .new_highschool_btn span,
    .highschool_landing_page_3 .new_highschool_btn span,
    .highschool_landing_page_4 .new_highschool_btn span,
    .highschool_landing_page_5 .new_highschool_btn span{
        font-size: 22px;
        line-height: 28px;
    }
}
