.queueup_top_menu {
    background: #201E39;
    max-height: 65px;
    z-index: 99 !important;

    .nav_link {
        font-weight: 600;
        font-size: 18px;
        // line-height: 27px;
        color: #F4F7FD;
    }

    .user_avatar {
        -webkit-clip-path: polygon(0 0,calc(100% - 8px) 0,100% 8px,100% 100%,0 100%,0 0);
        clip-path: polygon(0 0,calc(100% - 8px) 0,100% 8px,100% 100%,0 100%,0 0);
        margin-left: 0;
        display: flex;
        align-items: center;
    }

    .queueup_header {
        &_item {
            margin: 0px;
            display: flex;
            align-items: center;
        }
        &_text {
            margin: 0px 10px 0 0;
            display: block;

            .online_status {
                font-size: 12px;
                line-height: 15px;
                opacity: 0.7;
                font-weight: normal;
                margin: 0;
                color: #F4F7FD;
                position: relative;

                .icon_online{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: rgba(107, 216, 101, 0.5);
                    position: absolute;
                    left: 40px;
                    top: 0px;
                }
                .icon_online_inline{
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #6BD865;
                    position: absolute;
                    left: 43px;
                    top: 3px;
                }
            }

            .user_name {
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                margin: 0;
                color: #F4F7FD;
                white-space: nowrap;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .toggle_menu_mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.queueup_lhs_menu{
    display: flex;
    height: 100%;
    margin-left: -5px;
    overflow: hidden;

    .lhs_sections {
        width: calc(100% - 16px);
        background-color: #434343;

        .lhs_section {
            padding: 0px 15px;
            margin: 0 auto;
            &:not(:last-child){
                border-bottom: 2px solid #7E7E7E;
            }

            &.boom{
                background-color: #1E232F;
            }
            &.avgl{
                background-color: #232323;
            }
            &.queueup{
                background-color: #434343;
            }
            &_icon{
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                i, img {
                    width: 100%;
                }
                img {
                    max-width: 40px;
                }
            }
        }
    }
    .lhs_toggle{
        width: 16px;
        background-color: #6B6B6B;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .lhs_ellipses{
        height: 40px;
        padding: 6px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        max-width: 45px;
        margin: auto;

        .lhs_ellipse {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #D9D9D9;
        }
    }
}

.nav_dropdown_items{
    position: fixed;
    width: 100%;
    max-width: 465px;
    left: 0;
    top: 60px;
    z-index: 100;
    overflow-y: auto;
    max-height: calc(100vh - 60px);

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #A0AAC7;
    }
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #252344;
    }

    &.hide {
        display: none;
    }

    .nav_dropdown_item {
        width: 100%;
        max-width: 600px;
        height: 70px;
        display: flex;
        align-items: center;
        padding: 20px 40px;
        cursor: pointer;

        .menu_icon {
            width: 20px;
            display: flex;
            justify-content: center;
            margin-right: 35px;
        }
        .menu_title {
            font-weight: 600;
            line-height: 33px;
            font-size: 20px;
            color: #FFFFFF;
        }
        .menu_expand_icon {
            margin-left: auto;
            transition: ease-in-out 0.5s;
            &.open {
                transform: rotate(180deg);
            }
        }

        .settings_switch_status {
            font-size: 16px !important;
            color: #FFFFFF !important;
            font-weight: normal !important;
        }

        .settings_switch_button.enabled .button {
            background: #EE733A !important;
        }

        &__reverse {
            flex-direction: row-reverse;

            .menu_icon {
                margin: 0 0 0 30px;
            }
        }

        &:hover {
            .menu_title, {
                color: #FF7A00;
            }
            svg {
                path {
                    fill: #FF7A00 !important;
                }
            }
        }
    }
}
.nav_dropdown_items.nav_profile_dropdown_items {
    max-width: 300px;
    top: 60px;
    right: 100px;
    left: unset;

    .nav_dropdown_item {
        height: 52px;
        padding: 12px 20px;

        .menu_icon {
            margin-right: 20px;
        }

        .menu_title {
            font-size: 16px;
        }
    }
}
.nav_dropdown_menu_expand {
    padding: 0 30px;
    background-color: #252344;
    width: 100%;
    display: none;
    transition: display ease-in-out 0.5s;

    &.show {
        display: block;
    }

    &.sub_menu {
        width: calc(100% + 60px);
        margin: 0 0 0 -30px;
        background-color: #171530;
    }

    .menu_expand_item {
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 0;
        &:not(:last-child) {
            border-bottom: 3px solid #413E65;
        }
        &:hover {
            color: #FF7A00;
        }
        .menu_expand_title {
            //margin: auto;
            width: calc(100% - 28px);
            cursor: pointer;
        }
        .menu_expand_icon {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: ease-in-out 0.5s;
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .nav_dropdown_hub_menu {
        &:last-child {
            .menu_expand_item {
                border-bottom: unset !important;
            }
        }

        .menu_expand_item {
            border-bottom: 3px solid #413E65;
        }
    }
}

.nav_logo_dropdown_items {
    position: fixed;
    width: 100%;
    max-width: 465px;
    left: 0;
    top: 60px;
    z-index: 100;
}

.nav_logo_dropdown_item {
    width: 100%;
    text-align: center;
    padding: 15px;
}

.nav_logo_dropdown_item.avgl {
    background: #0B0E15;
}

.nav_logo_dropdown_item.boom {
    background: #1e232f;
}

.nav_logo_dropdown_item.queueup {
    background: #413E65;
}

.nav_logo_dropdown_item.boom i {
    width: 107px;
    height: 30px;
}

.nav_logo_dropdown_item.avgl i {
    width: 107px;
    height: 33px;
}

.nav_logo_dropdown_item.queueup img {
    width: 125px;
    height: 30px;
}

.nav_logo_dropdown_item_title {
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width: 576px) {
    .queueup_top_menu {
        background: #201E39 !important;
        transform: translate(0px, 0) !important;

        .queueup_header_text .user_name {
            max-width: 70px;
        }
    }
    .nav_dropdown_items{
        .nav_dropdown_item {
            padding: 20px;
            height: 60px;

            .menu_icon {
                margin-right: 10px;
            }
        }
    }
    .nav_dropdown_items.nav_profile_dropdown_items {
        max-width: 465px;
        top: 60px;
        right: 0;
        left: 0;
    }
}
