.failed-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark-alt);
  color: var(--color-text-light-alt);
  text-align: center;
  border-radius: 5px;
  padding: 15px;
  flex: 1;

  .icon, strong {
    color: var(--color-primary);
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  strong {
    text-transform: uppercase;
    margin-top: 10px;
  }

  span {
    margin-top: 3px;
  }

  .button-simple {
    flex: 0;
    margin-top: 12px;
  }

}