.player_featured_statistics {
  display: flex;
  align-items: center;
  position: relative;
  grid-gap: 5px;
  padding: 18px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 2;
  background-color: #ffffff;
  margin: var(--margin-right-player_featured_statistics);

  &:last-child, &:first-child{
    margin: 0px;
  }
  .player-avatar {
    width: 90px;
    height: 90px;
    border-radius: 2px;
    flex-shrink: 0;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }

  .player-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    flex: 1;

    .player-position {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: var(--color-text-dark-alt);

      span {
        display: flex;

        img {
          margin-right: 11px;
          width: 20px;
          height: 20px;
          -o-object-fit: contain;
             object-fit: contain;
        }
      }

      .position-progress {
        display: flex;
        align-items: center;
        margin-left: 7px;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;

        .arrow-caret {
          --arrow-width: 12px;
          --arrow-height: 9px;
        }
      }
    }
    .player-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: 1.5px solid var(--color-border);
      width: 34px;
      height: 34px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        -o-object-fit: contain;
            object-fit: contain;
      }
    }
    .player-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
    .player-points {
      font-size: 14px;
      line-height: 19px;
      color: var(--color-text-dark-alt);
    }

    .rank-container {
      .player-name {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }

      .player-rank {
        display: flex;
        margin-top: 8px;
        grid-gap: 8px;

        .rank-avatar {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          -o-object-fit: contain;
              object-fit: contain;
        }

        .rank-desc {
          display: flex;
          flex-direction: column;

          .rank-name {
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
          }
          .rank-info {
            font-size: 11px;
            color: var(--color-secondary);
            line-height: 16px;
            margin-top: 1px;
          }
        }
      }
    }

  }

  .player-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: -1;
  }
}