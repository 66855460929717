.chat_container {
    background-color: rgba(29, 32, 45, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    text-align: center;
    overflow-y: auto;
    z-index: 100;
    opacity: 1;
    pointer-events: all;
    transition: opacity .3s ease-in-out;
}

.chat_box {
    background: #E6E9F2;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: fixed;
    right: 50px;
    bottom: 20px;
    z-index: 10;
}

.chat_icon_container {
    position: fixed;
    right: 90px;
    bottom: 40px;
    z-index: 30;
}

.chat_icon {
    position: relative;
    display: flex;

    width: 60px;
    height: 60px;

    border-radius: 4px;
    background-color: #ff7a00;

    color: #f4f7fd;

    clip-path: polygon(0 0, calc(100% - 15px) 0%, 100% 15px, 100% 100%, 0 100%);
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.chat_icon > img {
    width: 24px;
    height: 24px;
}

.chat_unread {
    position: absolute;

    height: 20px;

    top: -8px;
    padding: 0 5px;
    right: 50px;

    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #EDF0F6;

    background: #34324C;
    border-radius: 4px;
}

@media screen and (max-width: 550px) {
    .chat_icon_container {
        right: 15px;
        bottom: 20px;
    }
    .chat_icon {
        width: 50px;
        height: 50px;
    }
    .chat_icon>img {
        width: 20px;
        height: 20px;
    }
    .chat_box {
        right: 0;
        height: 100%;
        bottom: 0;
    }
}