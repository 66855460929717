@import "~vue-multiselect/dist/vue-multiselect.min.css";
html {
	position: relative;
	width: 100%;
	overflow-x: hidden;
}
body {
	position: relative;
	overflow-x: hidden;
	width: 100%;
}
.drops_main {
	background: var(--drops-bg-color);
	padding: 65px 0 0 0;
}
.drops_main.queueup-page {
	overflow: hidden;
}
.section {
    display: flex;
    align-items: center;
}
.drops_header {
	display: flex;
    align-items: center;
	position: relative;
	padding: 45px 15px;
	width: 100%;
	max-width: 1230px;
	margin: 0 auto;
}
.drops_header .body_main {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    max-width: 100px;
    line-height: 19px;
}
.drops_header .header_main {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
}
.drops_header_bg {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%,0);
	width: 100%;
	min-width: 1680px;
	height: 636px;
	background: var(--drops-header-boom-bg);
	background-repeat: repeat-x;
	background-size: var(--drops-header-bg-size);
	background-position: 50% 50%;
}
.drops_header_bg.avgl {
	background: var(--drops-header-avgl-bg);
	background-repeat: repeat-x;
	background-size: auto 100%;
	background-position: 50% 50%;
}
.drops_header_bg.codered {
	background: url(../../assets/drops/drops-codered.gif);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 0%;
}
.drops_header_bg_1 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    min-width: 1680px;
    height: auto;
}
.drops_header_info {
	position: relative;
    width: 100%;
    margin: 15px;
}
.drops_header_icon {
	max-width: 568px;
	margin: 0 0 50px;
}
.drops_header_about {
	display: flex;
	align-items: stretch;
}
.drops_header_coins.bag {
	border: none;
	margin-left: 20px;
	padding: 20px 0 12px;
}
.drops_header_coins.bag .drops_header_box {
	margin: 0 12px;
}
.drops_header_coins {
	position: relative;
	padding: 20px 5px 15px;
	background: var(--drops-header-coins-bg);
	border: var(--drops-header-border);
	overflow: hidden;
	filter: var(--drop-rank-filter);
}
.drops_header_coins_bg {
	display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
	width: 100%;
	max-height: 100%;
}
.drops_header_coins > .body_main {
	position: relative;
	width: 100%;
	text-align: center;
	max-width: initial;
	text-transform: uppercase;
	margin: 0 0 20px;
	color: var(--text-color);
}
.drops_header_boxes {
	display: flex;
	align-items: flex-start;
}
.drops_header_box {
	position: relative;
	padding: 20px;
	background: var(--drops-header-box-bg);
	border: var(--drops-header-border);
	margin: 0 15px;
	border-radius: 2px;
	width: 156px;
	text-align: center;
}
.drops_header_coins.bag .drops_header_box {
	padding:  35px 20px;
}
.drops_header_box .header_main {
	margin: 0 0 10px;
}
.drops_header_box .body_main {
	text-overflow: initial;
    max-width: initial;
    width: initial;
    white-space: initial;
    text-transform: uppercase;
}
.drops_header_box_smash_cake {
	height: 102px;
	background-color: #ff834b;
	cursor: pointer;
	padding: 20px 0;
}
.drops_header_box_smash_cake .body_main {
	font-size: 16px;
	margin-top: 18px
}
.drops_header_hover {
	position: absolute;
	top: 1px;
	right: 1px;
	cursor: pointer;
}
.drops_header_arrow {
	position: absolute;
	top: 1px;
	right: 1px;
}
.drops_header_circle {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 456px;
	height: 456px;
	border: 2px solid #5F7194;
	box-sizing: border-box;
	margin: 15px;
	border-radius: 50%;
	flex-shrink: 0;
	padding: 9px;
}
.drops_header_circle_line {
	position: absolute;
	top: -5px;
	left: -5px;
	right: -5px;
	bottom: -5px;
	z-index: 1;
}
.drops_header_circle_line:before {
	content: '';
	position: absolute;
	left: calc(50% - 3px);
	top: -19px;
	height: 46px;
	width: 3px;
	background-color: #6A7CB0;
	z-index: 1;
}
.drops_header_circle_line:after {
	content: '';
	position: absolute;
	left: calc(50% - 9px);
	top: -10px;
	height: 22px;
	width: 6px;
	background-color: var(--drops-header-circle-line-after-bg-color);
	z-index: 1;
}
.drops_header_circle_line_inner {
	position: absolute;
	top: 0;
	left: 0%;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.drops_header_circle_line_inner:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	box-sizing: border-box;
}
.drops_header_circle_bg {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 97%;
	height: 97%;
	border-radius: 50%;
	object-fit: cover;
	transform: translate(-50%,-50%);
}
.drops_header_circle_context {
	position: relative;
	text-align: center;
}
.drops_header_circle_clock {
	display: flex;
	width: 35px;
	margin: 0 auto 30px;
}
.drops_header_circle_next {
	font-family: Open Sans, sans-serif;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;
	color: #6A7CB0;
	margin: 0 auto 10px;
}
.drops_header_circle_time {
	margin: 0 auto 50px;
	font-family: Open Sans, sans-serif;
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	color: var(--text-color);
}
.drops_header_circle_reward {
	margin: 0 auto;
	font-family: Open Sans, sans-serif;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
}
.drops_header_circle_desc {
	font-family: Open Sans, sans-serif;
	font-weight: normal;
	font-size: 24px;
	line-height: 33px;
	text-align: center;
	color: #FFFFFF;
	margin: 0 auto;
	max-width: 280px;
}
.light_drops_bg {
	background: var(--drops-light-bg);
}
 /* loading icon  */
 @-webkit-keyframes spinLoaderSaving {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

@keyframes spinLoaderSaving {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

#loaderSaving::after {
    margin-left: 5px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: #fff;
    border-top-color: transparent;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: spinLoaderSaving .8s linear infinite;
    animation: spinLoaderSaving .8s linear infinite;
}
.loaderSaving::after {
	margin-left: 5px;
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	border-style: solid;
	border-color: #fff;
	border-top-color: transparent;
	border-width: 2px;
	border-radius: 50%;
	-webkit-animation: spinLoaderSaving .8s linear infinite;
	animation: spinLoaderSaving .8s linear infinite;
}
/* Completed Challenges  */
.completed_challenges {
	margin-top: 80px;
}
.completed_challenges_title {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 23.76px;
	line-height: 32px;
	color: var(--input-text-color);
	margin-bottom: 29px;
}
.completed_challenges_content {
	display: flex;
	flex-wrap: wrap;
}
.completed_challenges_column {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.completed_challenges_row {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	width: 100%;
}
.completed_challenges_column .drops_works_card {
	margin-bottom: 38px;
}

/* How it works*/
.drops_works {
	position: relative;
	width: 100%;
	max-width: 1170px;
	padding: 40px;
	margin:  0 auto 60px;
	background: var(--drops-works-bg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border: var(--drops-header-border);
	filter: var(--drop-rank-filter);
}
#quest_reward_how_to_win {
	position: absolute;
	top: -40px;
}
.quest_reward_hub {
	position: relative;
	max-width: 100% !important;
	background: unset;
	border: unset;
	filter: unset;
}
.quest_reward_hub .drops_works_header {
	border-bottom: unset;
	margin-bottom: 26px;
}
.quest_reward_hub .landing_slider {
	padding: 0 !important;
}
.drops_works .slick-track {
	padding: 10px 0;
}
.drops_works.codered {
	max-width: 100%;
	padding: 0 40px 40px 40px;
	margin: 20px 0;
}
.drops_works_header {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 30px 0 30px;
	margin: 0 auto 40px;
	border-bottom: 2px solid #343744;
	/* justify-content: flex-end; */
}
.drops_works_title {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-right: 20px;
}
.drops_works_title i:first-child {
	flex-shrink: 0;
	width: 28px;
	margin-right: 12px;
}
.drops_works.codered .drops_works_title i:first-child {
	width: 230px;
}
.drops_works_title i:last-child {
	width: 86px;
	margin-left: 10px;
	flex-shrink: 0;
}
.drops_works_title div {
  display: flex;
}
.drops_works.codered .drops_works_title i:last-child {
	width: 155px;
	margin: 18px 0 0 0;
}
.drops_works_title_text {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-size: 34px;
	line-height: 46px;
	color: var(--text-color);
}

.drops_works_title .hub_drop_theme_arrow {
	width: 28px;
    margin-right: 12px;
}

.drops_works_title .hub_drop_theme_win_text {
	width: 86px;
    margin-left: 10px
}

.drops_works_desc {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: var(--text-color);
	width: 100%;
	padding-right: 130px;
	max-width: 690px;
}
.drops_works .landing_slider .slick-prev, .drops_works .landing_slider .slick-next {
	top: -80px;
}
.drops_works .landing_slider .slick-prev {
	left: auto;
	right: 45px;
}
.drops_works .landing_slider .slick-next {
	right: 5px;
}
.drops_works_card {
	width: var(--drops-works-card-width);
	padding: 20px;
	margin-right: 20px;
	background-color: var(--drops-works-card-bg-color);
	/* filter: var(--drop-rank-filter); */
	box-shadow: var(--drop-rank-box-shadow);
}
.drops_works_card_check {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1.5px solid #343744;
	margin: 0 0 20px;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: var(--text-color);
}
.drops_works_card_check img {
	pointer-events: none;
	touch-action: none;
	position: absolute;
	top: calc(50% + 1px);
	left: 50%;
	width: 16px;
	transform: translate(-50%,-50%);
	opacity: 0;
}
.drops_works_card_check.check {
	color: rgba(255,255,255,0);
}
.drops_works_card_check.check img {
	opacity: 1;
}
.drops_works_card_title {
	margin: 0 0 10px;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: var(--text-color);
}
.drops_works_card.hub_quest_card {
	min-height: 331px;
	background-color: var(--hub-drop-quest-card-bg);
}
.completed_challenges .drops_works_card {
	background-color: var(--hub-drop-quest-card-bg) !important;
}
.drops_works_card_title.card_title_underline {
	border-bottom: 1px solid #D6D6D6;
}
.hub_quest_card_icon_status {
	width: 35px;
	height: 35px;
	min-width: 35px;
	min-height: 35px;
}
.hub_quest_title_question {
	font-size: 21.7831px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	word-break: break-word;
	justify-content: space-between;
}
.hub_quest_title_submit_score {
	font-size: 21.7831px;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.drops_works_card_title .quest_title_status {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 21.7831px;
	line-height: 33px;
	text-align: right;
	color: var(--hub-drop-quest-title-status-color);
	min-width: 65px;
}
.hub_quest_card_line {
	margin-bottom: 20px;
}
.hub_quest_card_line.hub_quest_answers {
	min-height: 82px;
}
.hub_quest_submit_score {
	margin-top: 20px !important;
}
.hub_quest_submit_score .quest_input_score {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 13.9339px;
	line-height: 21px;
	color: var(--input-text-color);
	word-break: break-word;
}
.hub_quest_submit_score .quest_input_score input {
	width: 118px;
	height: 34px;
	padding: 5px 10px;
	background: var(--hub-drop-quest-input-bg);
	border: 1px solid #EE733A;
	box-sizing: border-box;
	color: var(--text-color);
}
.hub_quest_submit_score .quest_attach_image {
	display: flex;
	align-items: center;
}
.quest_attach_file {
	width: 295px;
	opacity: 0;
	position: absolute;
	height: 31.36px;
	z-index: 5;
	cursor: pointer;
}
.quest_custom_button {
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%);
	background: #EE733A;
	font-family: Open Sans, sans-serif;
	text-align: center;
	color: #FFFFFF;
	display: flex;
    align-items: center;
	justify-content: center;
	cursor: pointer;
}
.quest_custom_button:hover {
	opacity: .9;
}
.quest_attach_file:hover + .quest_custom_button {
	opacity: .9;
}
.quest_custom_attach_file {
	width: 136.08px;
	height: 31.36px;
	font-style: normal;
	font-weight: bold;
	font-size: 12.4379px;
	line-height: 17px;
	margin-right: 11.34px;
}
.quest_btn_submit {
	width: 123.08px;
	height: 31.36px;
	font-style: normal;
	font-weight: bold;
	font-size: 14.0516px;
	line-height: 19px;
}
.quest_btn_loading_submit {
	cursor: unset;
	background-color: rgb(104, 106, 123);
}
.quest_btn_loading_submit:hover {
	opacity: 1 !important;
}
.quest_custom_attach_file_name {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 12.1992px;
	line-height: 17px;
	display: flex;
	align-items: center;
	color: #777777;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
    display: block;
}
.hub_quest_missions {
	margin-top: 20px !important;
}
.hub_quest_mission {
	margin: 8px 0 8px 18px;
	list-style-type: disc;
	display: list-item;
	text-align: -webkit-match-parent;
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14.185px;
	line-height: 19px;
	text-transform: capitalize;
	color: #464646;
}
.hub_quest_mission_detail {
	display: flex;
	align-items: center;
}
.hub_quest_mission::before {
	color: #898B9C;
}
.hub_quest_mission_status {
	margin-left: 10px;
}
.hub_quest_mission_status.pending {
	width: 72px;
	height: 21px;
	background: #EE733A;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%);
}
.hub_quest_question {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16.1805px;
	line-height: 20px;
	color: var(--hub-quest-question-color);
	margin-bottom: 10px !important;
	word-break: break-word;
}
.hub_quest_answers {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.hub_completed_quest_answer {
	cursor: unset !important;
}
.hub_quest_answer {
	width: calc(50% - 5px);
	margin: 3px 0;
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 25px;
	text-align: center;
	color: var(--hub-drop-quest-answer-color);
	padding: 5px;
	background: var(--hub-drop-quest-answer-bg);
	min-height: 25px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	word-break: break-word;
}
.hub_quest_answer.choosing {
	border: 2px solid #EE733A;
	padding: 3px;
}
.hub_quest_answer.true_answer {
	background: #18B23A;
	color: #ffffff;
}
.hub_quest_answer.fail_answer {
	background: #EE733A;
	color: #ffffff;
}
.hub_quest_description {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: var(--hub-quest-description-color);
	width: 100%;
	margin: 10px 0 -10px;
	word-break: break-word;
}
.drops_works_card_lines {
	width: 100%;
	margin: 0 auto 20px;
	min-height: 92px;
}
.drops_works_card_line {
	/*display: flex;*/
	display: list-item;
	list-style-position: outside;
	align-items: center;
	width: 100%;
	font-family: Open Sans, sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: var(--drops-works-card-line-text-color);
	margin: 0 0 10px 20px;
}
.drops_works_card_line a {
	color: var(--drops-works-card-line-text-color);
}
.drops_works_card_line a:hover {
	text-decoration: underline;
	cursor: pointer;
}
.drops_works_card_line.goal-completed a:hover {
	text-decoration: none;
	cursor: default;
}

.goal_none_action a:hover{
	text-decoration: none;
	cursor: default;
}
.goal_action{
	display: block;
	position: relative;
}
.goal_action:before{
	content: '\203A';
    margin-right: 10px;
    position: absolute;
    top: -2px;
    left: -18px;
}

@-moz-document url-prefix() {
    .goal_action:before {
        left: -15px;
    }
}

/*
.drops_works_card_line:before {
	content: '';
	width: 6px;
	height: 6px;
	background-color: #898B9C;
	flex-shrink: 0;
	margin-right: 10px;
	border-radius: 50%;
}
 */
.drops_works_card_bar {
	position: relative;
	background-color: var(--drops-works-card-bar-bg-color);
	width: 100%;
	height: 10px;
	border: 3px solid var(--drops-works-card-bar-bg-color);
	border-radius: 5px;
	overflow: hidden;
	margin: 0 auto 20px;
}
.drops_works_card_fill {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	transform: translate(-100%,0);
}
.drops_works_card_info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.drops_works_card_info .section {
	margin-right: 10px;
}
.drops_works_crown {
	display: flex;
	width: 21px;
	flex-shrink: 0;
	margin-right: 10px;
}
.drops_works_crown_title {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
	color: var(--text-color);
	display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.hub_drops_works_crown_title_doing {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
	color: var(--input-text-color);
}
.hub_drops_works_crown_title_completed {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16.292px;
	line-height: 27px;
	text-transform: uppercase;

	color: var(--input-text-color);
}
.drops_works_crown_achievement {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 20px;
	text-align: right;
	text-transform: uppercase;
	color: var(--text-color);
}
/* Works end */

/* Ranks */
.drops_ranks_container {
	display: flex;
	align-items: flex-start;
	padding: 0 20px;
	width: 100%;
	max-width: 1230px;
	margin: 0 auto 20px;
}
.drops_ranks {
	position: relative;
	width: 100%;
	margin: 0 10px 40px;
	padding: 30px;
	border: var(--drops-header-border);
	height: 613px;
  filter: var(--drop-rank-filter);
  display: flex;
  flex-direction: column;
}
.drops_ranks::-webkit-scrollbar {
  display: none;
}
.drops_ranks_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 0 16px;
}
.drops_ranks_header_title {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: var(--text-color);
}
.drops_ranks_header_link {
	margin: 0 0 0 12px;
	font-family: Open Sans, sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
}

@media (max-width: 576px){
	.drops_ranks_header_link {
		margin: 30px 0 0 15px;
	}
}

.drops_ranks_menu {
	display: flex;
	align-items: center;
	margin: 0 -10px;
}
.drops_ranks_menu .admin_dropdown {
	width: 100%;
	margin: 0 10px 20px;
}
.drops_rank_current_user {
  margin: 30px 0;
  position: relative;
}
.drops_rank_current_user * {
  color: #ffffff !important;
}
.drops_rank_current_user .connect_button_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.drops_rank_current_user .connect_button {
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
}
.drops_ranks_items {
	width: 100%;
	height: 380px;
	overflow-y: scroll;
	scrollbar-color: rgba(242,242,242,.4) rgba(127,127,150,.1);
	scrollbar-width: thin;
}
.drops_ranks_items::-webkit-scrollbar {
	width: 5px;
}
.drops_ranks_items::-webkit-scrollbar-thumb {
	width: 5px;
	border-radius: 2px;
	background-color: #2f353c;
}
.drops_ranks_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	width: 100%;
	background-color: var(--drops-ranks-user-bg-color);
	border-radius: 4px;
	padding: 0 20px 0 10px;
	margin: 0 auto 4px;
	box-shadow: var(--drops-ranks-user-box-shadow);
}
.drops_ranks_item:last-child {
	margin-bottom: 0;
}
.drops_ranks_icon {
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	margin-right: 20px;
	background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.drops_ranks_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.drops_ranks_item_username {
	width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-size: 16px;
	line-height: 22px;
	color: var(--text-color);
}
.drops_ranks_item_points {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-size: 12px;
	line-height: 16px;
	color: #898B9C;
}
.drops_ranks_item_rank {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0;
	width: 30px;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
	color: var(--profile-position-leaderboard-color);
}
.drops_ranks_item_rank img {
	width: 12px;
	height: auto;
	margin-right: 10px;
}
.drops_ranks_item_rank.golden {
	color: #ECB93F;
}
.drops_ranks_item_rank.silver {
	color: #898B9C;
}
.drops_ranks_item_rank.copper {
	color: #BA6C36;
}

.drops_ranks_users {
	position: relative;
	width: 100%;
    height: 510px;
    overflow-y: scroll;
	scrollbar-color: rgba(242,242,242,.4) rgba(127,127,150,.1);
	scrollbar-width: thin;
}
.drops_ranks_users::-webkit-scrollbar {
	width: 5px;
}
.drops_ranks_users::-webkit-scrollbar-thumb {
	width: 5px;
	border-radius: 2px;
	background-color: #2f353c;
}
.drops_ranks_user {
	position: relative;
	display: flex;
	align-items: center;
	height: 80px;
	padding-left: 10px;
	background-color: var(--drops-ranks-user-bg-color);
	border-radius: 4px;
	margin: 0 0 4px;
	box-shadow: var(--drops-ranks-user-box-shadow);
}
.drops_ranks_user:last-child {
	margin-bottom: 0;
}
.drops_ranks_user_icon {
	position: relative;
	width: 60px;
	height: 60px;
	flex-shrink: 0;
	margin-right: 15px;
}

.drops_ranks_user_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.drops_ranks_user_about {
	flex-shrink: 0;
	margin-right: 30px;
}
.drops_ranks_user_about .drops_ranks_item_username {
	margin-bottom: 5px;
}
.drops_ranks_user_desc {
	width: 100%;
	padding-right: 20px;
}
.drops_ranks_user_desc_title {
	margin: 0 0 5px;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 11px;
	line-height: 16px;
	color: rgba(255,255,255,0.7);
}
.drops_ranks_user_desc_text {
	margin: 0;
	font-family: Open Sans, sans-serif;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
	color: var(--text-color);
}
.drops_ranks_user_item {
	width: 107px;
	height: 80px;
	flex-shrink: 0;
}
.drops_ranks_user_item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* Ranks end */

/* Drops Video */
.drops_video {
	position: relative;
	width: 100%;
	max-width: 900px;
	margin: 0 auto 60px;
}
.drops_video img {
	height: auto;
}
/* Drops Video End */

/* Drops Works Progress */
.drops_works.progress .drops_works_desc {
	padding: 0;
	max-width: initial;
}
.drops_works.progress.codered .drops_works_desc {
	max-width: 675px;
	margin-left: 30px;
}
.drops_works_progress {
	width: 100%;
}
.drops_works_progress_lineholder {
	position: relative;
	width: calc(100%/6*5);
	height: 16px;
	margin: 0 auto 20px;
}
.drops_works_progress_line {
	position: relative;
	width: 100%;
	height: 5.83px;
	background-color: #343744;
	/*border: 4px solid #343744;*/
	border-radius: 16px;
	overflow: hidden;
}
.drops_works_progress_fill {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-color: #FF3932;
	transform: translate(-100%,0);
	transition: transform .3s ease-in-out;
}
.drops_works_progress_point {
	position: absolute;
	top: -4px;
	left: 0;
	width: 14.58px;
	height: 14.58px;
	/*background-color: rgba(255, 57, 50, 0);*/
	/*border: 6px solid var(--bg-color);*/
	background-color: #000000;
	border-radius: 50%;
	transform: translate(-50%,0);
	box-shadow: 0px 0px 30px rgba(195, 33, 48, 0);
	transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
}
.drops_works_progress_point.on {
	background-color: #FF3932;
	box-shadow: 0px 0px 30px #C32130;
}
.drops_works_progress_infos {
	display: flex;
	align-items: flex-start;
}
.drops_works_progress_info {
	width: 100%;
	text-align: center;
}
.drops_works_progress_info .section {
	justify-content: center;
}
.drops_works_progress_info .drops_works_crown {
	margin-right: 5px;
}
.drops_works_progress_title {
	margin: 0 0 4px;
	font-family: Open Sans, sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: var(--text-color);
}
.drops_cake_highlight {
	height: 13px;
	width: 13px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	right: -6px;
	top: -7px;
	border: 1px solid grey;
}
/*.drops_header_box_smash_cake .drops_cake_highlight {
	top: -3px;
	right: -10px;
}*/
.btn_go_to_drop {
	padding: 7px;
	border-radius: 5px;
	margin-left: 15px;
	min-width: 145px;
}
.btn_go_to_drop a {
	color: #fff;
}
.drops_header_container {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.drops_ranks_header_filter {
    width: 50%;
	margin: 0 10px;
}

@media (max-width: 1230px) {
	.drops_header {
		overflow-x: hidden;
	}
}
@media (max-width: 1126px) {
	.drops_header {
		flex-wrap: wrap;
		justify-content: center;
	}
	.drops_header_icon {
		margin: 0 auto 50px;
	}
	.drops_header_about {
		justify-content: center;
		margin-bottom: 30px;
	}
}
@media (max-width: 1100px) {
	.drops_ranks_container {
		flex-wrap: wrap;
	}
	.drops_ranks {
		max-width: 720px;
		margin: 0 auto 40px;
	}
	.drops_works.progress.codered {
		display: none;
	}
	.drops_works_progress_infos {
		flex-wrap: wrap;
	}
	.drops_works_progress_info {
		margin: 0 auto 20px;
	}
	.drops_works_progress_info:last-child {
		margin-bottom: 0;
	}
}
@media (max-width: 940px) {
	.drops_works_header {
		flex-wrap: wrap;
		padding-bottom: 60px;
	}
	.drops_works.progress .drops_works_header {
		padding-bottom: 15px;
	}
	.drops_works_title {
		margin: 0 0 20px;
	}
	.drops_works_desc {
		max-width: initial;
	}
	.drops_works .landing_slider .slick-prev {
		right: initial;
		left: 5px;
	}
	.drops_works .landing_slider .slick-next {
		right: initial;
		left: 45px;
	}
}
@media (max-width: 648px) {
	.drops_works .landing_slider {
		padding: 0;
	}
	.drops_works_desc {
		padding: 0;
	}
	.drops_works_card_line {
		width: 92%;
	}
    .drops_header_container {
        flex-wrap: wrap;
    }

    .drops_ranks_header_filter {
        margin-top: 5px;
        margin-left: 0px;
        width: 100%;
    }
}
@media (max-width: 645px) {
	.drops_header_about {
		flex-wrap: wrap;
		justify-content: center;
	}
	.drops_header_coins {
		padding: 20px 3.5px 15px;
	}
	.drops_header_box {
		margin: 0 5px;
	}
	.drops_header_coins.bag .drops_header_box {
		margin: 0 10px;
	}
	.drops_header_about {
		padding: 0 6px;
		margin: 0;
	}
	.drops_header_coins, .drops_header_coins.bag {
		margin: 0 6px 40px;
	}
	.drops_header_icon {
		max-width: 628px;
		padding: 0 30px;
	}
}
@media (max-width: 580px) {
	.drops_ranks_menu {
		flex-wrap: wrap;
	}
	.drops_ranks_item {
		flex-wrap: wrap;
		height: initial;
		padding: 10px 20px 10px 10px;
	}
	.drops_ranks_item .section {
		width: 100%;
		margin-bottom: 10px;
	}
	.drops_ranks_user {
		flex-wrap: wrap;
		justify-content: center;
		padding: 10px;
		height: initial;
	}
	.drops_ranks_user_about {
		margin: 0;
	}
	.drops_ranks_user_desc {
		padding: 10px 0;
		text-align: center;
	}
	.drops_ranks_items {
		height: 270px;
	}
	.hub_quest_submit_score .quest_input_score {
		font-size: 12.4609px;
		line-height: 19px;
	}
	.hub_drops_works_crown_title_doing {
		font-size: 10.7314px;
		line-height: 18px;
	}
	.quest_btn_submit {
		width: 121.69px;
		height: 28.04px;
		font-size: 12.5662px;
		line-height: 17px;
	}
	.completed_challenges_title {
		font-size: 16.6239px;
		line-height: 23px;
		border-left: 5.91px solid #EE733A;
		padding-left: 26.49px;
		margin-left: -10px;
	}
	.drops_works_header {
		padding-bottom: 0;
	}
}
@media (max-width: 456px) {
	.drops_header_circle {
		width: 100%;
		height: calc(100vw - 30px);
	}
	.drops_works_card {
		width: 340px;
	}
	.drops_ranks_header {
		justify-content: center;
	}
}
@media (max-width: 400px) {
	.drops_works_card {
		width: 300px;
	}
}





