.player_search_statistics {
  @extend .player_featured_statistics;
  overflow: visible;
}

.player_search_statistics {
  .player-name {
    cursor: pointer;

    .arrow-caret {
      --arrow-width: 14px;
      --arrow-height: 9px;
      --arrow-color: var(--color-text-light);
      top: -1px;
    }
  }

  .player-selection-container {
    $items-height: 48px;
    $item-positions-colors: ("1": #C37D3D, "2": #B5B1AE, "3": #A67169);

    position: absolute;
    margin-top: 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    .selection-input {
      display: flex;
      align-items: center;
      background-color: var(--color-dropdown-hover);
      padding: 0 15px;
      height: $items-height;

      img {
        width: 13px;
        height: 13px;
        margin-right: 9px;
        -o-object-fit: contain;
            object-fit: contain;
      }
      input {
        color: var(--color-text-dark-alt);
        font-size: 13px;
        font-weight: 600;
        height: 100%;
      }
    }

    .loading-simple {
      background-color: var(--color-dark-alt);
      flex: 1;

      img {
        width: 45px;
      }
    }

    .selection-player {
      display: flex;
      align-items: center;
      background-color: var(--color-dark-alt);
      font-size: 11px;
      padding: 0 15px;
      height: $items-height;
      cursor: pointer;

      &:hover {
        background-color: var(--color-dropdown-hover);
      }

      .selection-position {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        width: 12px;
        font-size: 10px;
        font-weight: bold;
        color: var(--color-text-light-alt);

        img {
          margin-bottom: 2px;
        }
      }
      .selection-avatar {
        width: 32px;
        height: 32px;
        border: 2px solid transparent;
        border-radius: 2px;
        padding: 3px;
        margin-right: 9px;

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover;
        }
      }

      .selection-info {
        display: flex;
        flex-direction: column;
        font-size: 11px;
        font-weight: bold;
        line-height: 16px;

        .selection-points {
          color: var(--color-text-light-alt);
          margin-top: 2px;
        }
      }


      // NOTE We go trough $item-positions-colors and create a .position-x for every position inside the map
      @each $position, $color in $item-positions-colors {
        &.position-#{$position} {
          .selection-position {
            color: $color;
          }
          .selection-avatar {
            border-color: $color;
          }
        }
      }
    }
  }
}

.player_search_statistics.search-active {
  .player-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px dashed var(--color-dark-alt);
    background-color: var(--color-border);

    img {
      width: 40px;
      height: 40px;
      -o-object-fit: contain;
        object-fit: contain;
    }
  }

  .player-info {
    overflow: visible;
  }

  .rank-container {
    position: relative;

    .rank-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed var(--color-border);
      border-radius: 100%;

      img {
        width: 23px;
        height: auto;
      }
    }
  }

}