@import "game_list_statistics";
@import "game_stats_statistics";
@import "game_compare_statistics";
@import "game_leaderboard_statistics";

@import "core_statistics";
@import "monthly_statistics";
@import "overview_statistics";
@import "player_featured_statistics";
@import "player_search_statistics";
@import "player_statistics";
@import "queue_statistics";
@import "graph_statistics";

#statistics_tab {
  --statistics-border-radius: 5px;
  $statistics-tab-gamelist-width: 240px;
  $statistics-grid-gap: 41px;

  display: -ms-grid;
  display: grid;
  grid-template-columns: $statistics-tab-gamelist-width calc(100% - #{$statistics-tab-gamelist-width} - #{$statistics-grid-gap});
  grid-gap: $statistics-grid-gap;
  color: var(--color-text-light);
  min-height: 400px;
}

#statistics_tab {
  .statistics_game_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .player_statistics {
      margin-top: 12px;
    }

    .sub-tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      margin: 32px 0;

      .button-simple {
        text-transform: capitalize;
      }
    }
  }
}

#statistics_tab {
  .expand-two-col {
    grid-column: span 2;
  }
  .expand-two-col-row {
    grid-column: span 2;
    grid-row: span 2;
  }

  .statistics-header {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid transparent;

    &.bordered {
      border-bottom-color: var(--color-border);
    }

    .extra-info {
      margin-right: 0;
      margin-left: auto;
    }

    .arrow-progress {
      background-color: var(--color-dark-alt);
      margin-left: 10px;
      padding: 5px 7px;
      border-radius: 5px;
      &:not(.positive):not(.negative) {
        color: var(--color-secondary);
      }

      .arrow-caret {
        --arrow-width: 13px;
        --arrow-height: 10px;
      }
    }
  }

  .statistics-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    line-height: 27px;
  }

  .statistics-loading,
  .loading-simple {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
    }
  }

  .infinite-loading-container {
    .loading-simple {
      margin: 0 auto;
      width: 50px;
      height: 50px;
    }
    .infinite-status-prompt {
      font-size: 16px !important;
      color: var(--color-negative) !important;

      .btn-try-infinite {
        margin-top: 8px;
        border-color: var(--color-negative);
        background: var(--color-negative);
        font-size: 15px !important;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #statistics_tab {
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #statistics_tab::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  #statistics_tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
}