.graph_statistics {
  display: flex;
  flex-direction: column;

  .chart-container {
    margin-top: 20px;

    & > canvas {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
    }
  }

  .line_chart {
    display: flex;
    position: relative;
  }

  .chart-gridlabels {
    $gridlabel-padding-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 50px;
    padding-right: $gridlabel-padding-right;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 79%;
      top: 0;
      right: 0;
      background-color: var(--color-border);
    }

    &.grid-images .gridlabel {
      top: -11px;
    }

    .gridlabel {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      top: -6px;
      color: var(--color-secondary);
      font-weight: bold;
      font-size: 15px;
      line-height: 1;
      flex: 1;

      &:first-child {
        position: absolute;
        bottom: 40px;
        right: 0;
        top: auto;
        padding-right: $gridlabel-padding-right;
      }

      img {
        width: 30px;
        height: 30px;
        -o-object-fit: contain;
           object-fit: contain;
      }
    }
  }

  .chart-scrollable-area {
    flex: 1;
    overflow-x: scroll;
  }

  .chart-area-container {
    width: 100%;
    min-width: 100%;
    padding-bottom: 10px;
    position: relative;
  }

    .chart-scrollable-area::-webkit-scrollbar {
        height: 6px;
    }
}

#chart-tooltip {
  $arrow-width: 12px / 2;
  $arrow-height: 9px;

  position: absolute;
  background-color: var(--color-dark-alt);
  color: var(--color-secondary);
  font-weight: bold;
  font-size: 11px;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  margin-left: -23px;
  margin-top: -18px;
  transition: all var(--global-animation-short);
  transition-property: top, left;
  z-index: 1;
  pointer-events: none;

  .value {
    font-size: 12px;
    white-space: nowrap;
    color: var(--color-text-light);

    &.default {
      display: none;
    }
    &.positive {
      color: var(--color-positive);
      .arrow {
        border-bottom-color: var(--color-positive);
        border-width: 0 $arrow-width $arrow-height $arrow-width;
      }
    }
    &.negative {
      color: var(--color-negative);
      .arrow {
        border-top-color: var(--color-negative);
        border-width: $arrow-height $arrow-width 0 $arrow-width;
      }
    }

    .arrow {
      display: inline-block;
      position: relative;
      width: 0;
      height: 0;
      top: 5px;
      border-style: solid;
      border-color: transparent;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -7px;
    top: 10px;
    width: 15px;
    height: 15px;
    background-color: var(--color-dark-alt);
    transform: rotate(45deg);
    z-index: -1;
  }
}
